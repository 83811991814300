import React, { useEffect, useState, useCallback } from 'react';
import './IntroVideo.css';
import { Modal, InlineStack } from '@shopify/polaris';

function IntroVideo(props) {

  const [openVideo, setVidoActive] = useState(false);
  const handleClkWtchDemo = useCallback(() => setVidoActive(!openVideo), [openVideo]);
  
  return (
    <div>
      <div class="btn-fix-video" id="cursorPoint" onClick={() => handleClkWtchDemo()}>
        <div className='btn-only-video'>
          <span class="">Watch a demo</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none">
            <path
              d="M15.2578 1.53906C15.5677 1.77604 15.7318 2.08594 15.75 2.46875V9.00391C15.7318 9.42318 15.5677 9.74219 15.2578 9.96094C15.0755 10.0703 14.875 10.125 14.6562 10.125C14.4922 10.125 14.3372 10.0885 14.1914 10.0156L11.375 8.73047V9.25C11.3568 9.74219 11.1836 10.1523 10.8555 10.4805C10.5273 10.8086 10.1172 10.9818 9.625 11H1.75C1.25781 10.9818 0.847656 10.8086 0.519531 10.4805C0.191406 10.1523 0.0182292 9.74219 0 9.25V2.25C0.0182292 1.75781 0.191406 1.34766 0.519531 1.01953C0.847656 0.691406 1.25781 0.518229 1.75 0.5H9.625C10.1172 0.518229 10.5273 0.691406 10.8555 1.01953C11.1836 1.34766 11.3568 1.75781 11.375 2.25V2.76953L14.1914 1.48438C14.556 1.32031 14.9115 1.33854 15.2578 1.53906ZM10.0625 9.25V2.25C10.0443 1.97656 9.89844 1.83073 9.625 1.8125H1.75C1.47656 1.83073 1.33073 1.97656 1.3125 2.25V9.25C1.33073 9.52344 1.47656 9.66927 1.75 9.6875H9.625C9.89844 9.66927 10.0443 9.52344 10.0625 9.25ZM14.4375 8.70312V2.79688L11.375 4.21875V7.28125L14.4375 8.70312Z"
              fill="black" />
          </svg>
        </div>
      </div>
      <Modal
        size='large'
        open={openVideo}
        onClose={() => handleClkWtchDemo()}>
        <InlineStack align='center'>
          {/* <iframe id="youtubeViDeo" width="981" height="550" src={`//www.youtube.com/embed/4Ga2gy0fSac`} allowFullScreen={false}></iframe> */}
          <iframe id="youtubeViDeo" width="981" height="550" src={`//www.youtube.com/embed/OVCUlsZMA2I?si=pQXW-uSb2sXLiWxU`} allowFullScreen={false}></iframe>
        </InlineStack>
      </Modal>
    </div>
  );
}

export default IntroVideo;