import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
// import Toastify from './components/Util/Toastify';
import * as actions from '../ducks/auth';
import { load, loaded } from '../ducks/loading';
import { toastify } from '../ducks/toast';

let siFBTrack = false;

function* getProfile() {
  try {
    yield put(load());

    const pvd = localStorage.getItem('pvd');
    const istm = localStorage.getItem('istm');
    const busr = localStorage.getItem('isbusr');

    const url = (pvd === 'shopify')
      ? `/getProfile?pvd=${pvd}&istm=${istm}&isbusr=${busr}`
      : `/getProfile?istm=${istm}&isbusr=${busr}`;

    const res = yield call(api.GET, url);

    if (res.status === 'success') {
      if (res.data.ia !== false) {
        //set cookie domain 
        if (busr !== 'true' && res.data.csub === 'free') {
          setCookie_domain('W_APP_TI', res.data.ti, 24 * 365);
        }

        if (!res.data.lctn) {
          //get user location from froged
          yield call(api.GET, '/getLocation');
        }

        yield put(actions.getProfileSuccess(res.data));

        if (!res.data.gclntid) {
          setTimeout(() => {
            if (window.ga) {
              try {
                let tracker = window.ga?.getAll?.()[0]?.get?.('clientId') || '';
                fetch(process.env.REACT_APP_API_URL + '/updateGID?gclntid=' + tracker, {
                  method: 'GET',
                  headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                  }
                }).then(res => res.json()).then(() => { });
              } catch (ex) {
                console.log(ex);
              }
            }
          }, 1000);
        }

        const { fn, un, e, ti, istm, isMaster, csub, pvd, utyp, vstp, isbusr, uaq, uuq, cdt, orge, afi, wxeml, billing, indtry, arrusrpltfm, shp, brand, agc, apstk } = res.data;

        if (localStorage.getItem('istm') === 'true' && res.data.userTeam) {
          localStorage.setItem('username', res.data.userTeam.fn ? res.data.userTeam.fn : 'User');
        }
        else {
          localStorage.setItem('username', fn ? fn : un);
        }
        localStorage.setItem('e', e);
        localStorage.setItem('ti', ti);
        // localStorage.setItem('istm', istm || false);
        // localStorage.setItem('isMaster', isMaster || false);
        // localStorage.setItem('csub', csub || '');
        // localStorage.setItem('isbusr', (isbusr === true) ? true : false); //white_label

        if (vstp && vstp.plx && vstp.plx.st !== true) {
          localStorage.setItem('isPixelIns', false);
        }

        //complete onboarding 
        const isCompletedOnboarding = (indtry !== '' && indtry !== undefined) && (arrusrpltfm && arrusrpltfm.length > 0) && csub;
        localStorage.setItem('iscmpltdonbrdng', isCompletedOnboarding ? 'true' : 'false');

        if (pvd === 'shopify' || pvd === 'wix' || pvd === 'thinkific' || pvd === 'bigcommerce' || pvd === 'duda') {
          localStorage.setItem('pvd', pvd);

          //set shopify count. beacuse if user have multiple shopify store then we need to show the normal notification screen
          if (res.data?.shp?.length > 1) {
            localStorage.setItem('shp_cnt', res.data.shp.length);
          }
        } else {
          localStorage.setItem('pvd', 'local');
        }

        if (utyp === 'dealify' || utyp === 'appsumo') {
          localStorage.setItem('utyp', utyp);
        }

        setTimeout(() => {
          if (window._cio) {
            window._cio.identify({
              id: ti,
              name: fn,
              username: fn,
              referral: afi ? true : false,
              PlanName: csub,
              assign_quota: uaq ? uaq : 0,
              registered: dateToSeconds(cdt),
              created_at: dateToSeconds(cdt),
              Provider: utyp || pvd,
              IsSubUser: (busr === 'true') ? true : false,
              IsTeamMember: (istm === 'true') ? true : false
            });
            if (pvd === 'thinkific' || pvd === 'duda' || pvd === 'bigcommerce' || pvd === 'prestashop') {
              window._cio.identify({
                id: ti,
                email: orge || e,
              });
            }
            else if (pvd === 'wix') {
              window._cio.identify({
                id: ti,
                email: wxeml || e,
              });
            }
            else {
              window._cio.identify({
                id: ti,
                email: e,
              });
            }
            if (uuq !== undefined && uuq !== 0) {
              window._cio.identify({
                id: ti,
                used_quota: uuq === 0 ? 1 : uuq
              });
            }
            if (indtry !== undefined) {
              window._cio.identify({
                id: ti,
                industry: indtry
              });
            }
            if (vstp) {
              window._cio.identify({
                id: ti,
                Pixel: vstp.plx.st
              });
            }
            if (billing && billing.pobj) {
              window._cio.identify({
                id: ti,
                Plan_type: billing.pobj.period_unit
              });
            }
            if (localStorage.getItem('isNewUser') && localStorage.getItem('isNewUser') === 'true') {
              if (localStorage.getItem('utyp') == null || localStorage.getItem('utyp') === undefined) {
                if (pvd === 'local') {
                  window._cio.track('new_register_local');
                }
                if (pvd === 'wix') {
                  window._cio.track('new_register_wix');
                }
                if (pvd === 'thinkific') {
                  window._cio.track('new_register_thinkific');
                }
                if (pvd === 'bigcommerce') {
                  window._cio.track('new_register_bigcommerce');
                }
                if (pvd === 'ecwid') {
                  window._cio.track('new_register_ecwid');
                }
              }
              else if (localStorage.getItem('utyp') === 'appsumo') {
                window._cio.track('new_register_appsumo');
              }
            }
            if (apstk) {
              window._cio.identify({
                id: ti,
                AppSumoCode: apstk.length,
                Plan: ''
              });
            }
            if (brand && agc) {
              window._cio.identify({
                id: ti,
                AgencyId: agc
              });
            }
            if (pvd && pvd === 'shopify' && shp && shp.length > 0) {
              window._cio.identify({
                id: ti,
                shopify_install: cdt
              });
            }
            if (res.data && res.data.lctn) {
              window._cio.identify({
                id: ti,
                country: res.data.lctn.cury || '',
                city: res.data.lctn.ct || '',
                state: res.data.lctn.st || '',
                ipAddress: res.data.lctn.ip || ''
              });
            }
          }
          if (window.Froged) {
            window.Froged('set', {
              userId: res.data.ti,
              name: res.data.fn,
              username: res.data.fn,
              referral: res.data.afi ? true : false,
              PlanName: res.data.csub,
              registered: res.data.cdt,
              Provider: res.data.utyp || res.data.pvd,
              //white_label
              IsSubUser: (busr === 'true') ? true : false,
              IsTeamMember: (istm === 'true') ? true : false
            });
            if (res.data.pvd == 'thinkific' || res.data.pvd == 'duda' || res.data.pvd == 'bigcommerce' || res.data.pvd == 'prestashop') {
              window.Froged('set', {
                email: res.data.orge,
              });
            }
            else if (res.data.pvd == 'wix') {
              window.Froged('set', {
                email: res.data.wxeml,
              });
            }
            else {
              window.Froged('set', {
                email: res.data.e,
              });
            }
            if (res.data.uuq !== undefined && res.data.uuq !== 0) {
              window.Froged('set', {
                used_quota: res.data.uuq == 0 ? 1 : res.data.uuq
              });
            }
            if (res.data.indtry !== undefined) {
              window.Froged('set', {
                industry: res.data.indtry
              });
            }
            if (res.data.vstp) {
              window.Froged('set', {
                Pixel: res.data.vstp.plx.st
              });
            }
            if (res.data.billing && res.data.billing.pobj) {
              window.Froged('set', {
                Plan_type: res.data.billing.pobj.period_unit
              });
            }
            if (localStorage.getItem('isNewUser') && localStorage.getItem('isNewUser') == 'true') {
              if (localStorage.getItem('utyp') == null || localStorage.getItem('utyp') == undefined) {
                if (res.data.pvd == 'local') {
                  window.Froged('track', 'new_register_local');
                }
                if (res.data.pvd == 'wix') {
                  window.Froged('track', 'new_register_wix');
                }
                if (res.data.pvd == 'thinkific') {
                  window.Froged('track', 'new_register_thinkific');
                }
                if (res.data.pvd == 'bigcommerce') {
                  window.Froged('track', 'new_register_bigcommerce');
                }
                if (res.data.pvd == 'ecwid') {
                  window.Froged('track', 'new_register_ecwid');
                }
              }
              else if (localStorage.getItem('utyp') == 'appsumo') {
                window.Froged('track', 'new_register_appsumo');
              }
              localStorage.removeItem('isNewUser');
            }
            if (res.data.apstk) {
              window.Froged('set', {
                AppSumoCode: res.data.apstk.length,
                Plan: ''
              });
            }
            if (res.data.brand && res.data.agc) {
              window.Froged('set', {
                AgencyId: res.data.agc
              });
            }
            if (res.data.pvd && res.data.pvd == 'shopify' && res.data.shp && res.data.shp.length > 0) {
              window.Froged('set', {
                shopify_install: res.data.cdt
              });
            }
          }
        }, 6000);

        //facebook tracker
        if (window.fbq && siFBTrack === false) {
          siFBTrack = true;
          window.fbq('trackCustom', 'UserDetail', {
            IsSignup: 'true',
            IsPixelInstalled: res.data.vstp.plx.st == true ? 'true' : 'false',
            PlanName: res.data.csub ? res.data.csub : '',
            CodeCount: res.data.apstk ? res.data.apstk.length : '',
            UserType: res.data.utyp ? 'appsumo' : res.data.pvd
          });
        }

        //if user singup date is less than 15 days. show the coupon code popup
        let currDate = new Date().getTime();
        let dt = new Date(res.data.cdt);
        let signDate = dt.setDate(dt.getDate() + 15);
        let loginTime = new Date().setDate(new Date().getDate() + 1);

        if (signDate >= currDate && !localStorage.getItem('loginTime')) {
          localStorage.setItem('loginTime', loginTime);
        }

        if (res.data.vstp && res.data.vstp.plx) {
          localStorage.setItem('isPixelIns', res.data.vstp.plx.st);
        }
      }
      else {
        console.log('User is not active');
        localStorage.removeItem('restoreId');
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');
        localStorage.removeItem('e');
        localStorage.removeItem('ti');
        localStorage.removeItem('pvd');
        localStorage.removeItem('role');
        localStorage.removeItem('istm');
        localStorage.removeItem('isbusr');//white_label
        localStorage.removeItem('utyp');
        localStorage.removeItem('alg');
        localStorage.removeItem('fvicn');
        localStorage.removeItem('isAppsumo');
        localStorage.removeItem('isMaster');
        localStorage.removeItem('isPixelIns');
        localStorage.removeItem('is_hide_blackfriday');
        localStorage.removeItem('loginTime');
        localStorage.removeItem('rdt_url');
        localStorage.removeItem('iskponbrdng');
        window.location.href = '/ ';
      }
    }
    else if (res.status == 'Unauthorized') {
      console.log('Unauthorized');
      localStorage.removeItem('restoreId');
      localStorage.removeItem('authToken');
      localStorage.removeItem('username');
      localStorage.removeItem('e');
      localStorage.removeItem('ti');
      localStorage.removeItem('pvd');
      localStorage.removeItem('role');
      localStorage.removeItem('istm');
      localStorage.removeItem('isbusr');//white_label
      localStorage.removeItem('utyp');
      localStorage.removeItem('alg');
      localStorage.removeItem('fvicn');
      localStorage.removeItem('isMaster');
      localStorage.removeItem('isPixelIns');
      localStorage.removeItem('is_hide_blackfriday');
      localStorage.removeItem('loginTime');
      localStorage.removeItem('UTM_SIGNUP');
      localStorage.removeItem('rdt_url');
      localStorage.removeItem('iskponbrdng');
      window.location.href = '/login';
    }
    else {
      yield put(toastify({ type: 'error', msg: 'Failed to get user profile' }));
    }
    yield put(loaded());
  } catch (error) {
    yield put(loaded());
    console.log(error, 'getProfile==>');
  }
}

//function for date to second 
function dateToSeconds(dateString) {
  // Parse the date string into milliseconds
  let milliseconds = Date.parse(dateString);
  // Convert milliseconds to seconds
  let seconds = milliseconds / 1000;
  return seconds;
}

function setCookie_domain(name, value, hour) {
  var expires = '';
  if (hour) {
    var date = new Date();
    date.setTime(date.getTime() + (hour * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + ';domain=.wisernotify.com; path=/;SameSite=None;secure;';
}

function* loginWithBGC(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithBGC', action.payload);
    if (res.status === 'success') {
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st != true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific' || res.data.pvd === 'bigcommerce') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_bigcommerce');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_bigcommerce' });
        } catch (error) {
          console.log(error);
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      // let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        // (localStorage.getItem('csub') === '' || localStorage.getItem('csub') == null || localStorage.getItem('csub') === undefined) ?
        //   (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
        //     window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
        //     :
        //     window.location.href = '/onboarding'
        //   :
        //   window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        window.location.href = '/socialproof'
      }, 2000);
      localStorage.removeItem('rdt_url');
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithWix(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithWix', action.payload);
    if (res.status === 'success') {
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_wix');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_wix' });
        } catch (error1) {
          console.log(error1, '=====error1');
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      // let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        // (localStorage.getItem('csub') === '' || localStorage.getItem('csub') === null || localStorage.getItem('csub') === undefined) ?
        //   (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
        //     window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
        //     :
        //     window.location.href = '/onboarding'
        //   :
        //   window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        window.location.href = '/socialproof';
      }, 2000);
      localStorage.removeItem('rdt_url');
    }
    else if (res.status === 'not_found') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_active') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithThinkific(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithThinkific', action.payload);
    if (res.status === 'success') {
      // localStorage.setItem('authToken', res.data.token);
      // localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      // localStorage.setItem('pvd', 'thinkific');
      // localStorage.setItem('isbusr', false);//white_label
      // localStorage.setItem('istm', false);
      // localStorage.setItem('e', res.data.e);
      // if (res.data.ti) {
      //   localStorage.setItem('ti', res.data.e);
      // }
      // //mixpanel start
      // // if (window.mixpanel) {
      // //   window.mixpanel.identify(res.data.e);
      // //   window.mixpanel.people.set({
      // //     '$email': res.data.e,
      // //     '$last_login': new Date()
      // //   });
      // // }
      // // history.push(res.data.rp, { some: 'state' });
      // //mixpanel end

      // //facebook marketing
      // if (window.fbq) {
      //   window.fbq('track', 'PixelInstall');
      // }
      // if (action.payload.newUser && action.payload.newUser == true) {
      //   //froged regiter event
      //   if (window.Froged) {
      //     window.Froged('track', 'new_register_thinkific');
      //   }
      //   if (res.data.newUser == true) {
      //     try {
      //       let tracker = window.ga.getAll()[0];
      //       tracker.send('event', window.location.hostname, 'sign_up', 'create_account_thinkific');
      //     } catch (ex) {
      //       console.log(ex);
      //     }
      //     if (localStorage.getItem('UTM_SIGNUP')) {
      //       let signupString = localStorage.getItem('UTM_SIGNUP');
      //       if (signupString.includes('utm_source=google-ads')) {
      //         window.gtag('event', 'conversion', { 'send_to': 'AW-663600856/8VRyCJ7e4PgCENj9trwC' });
      //       }
      //       localStorage.removeItem('UTM_SIGNUP');
      //     }
      //   }
      // }
      // // const params = (new URL(window.location)).searchParams;
      // // const messageForIntegration = params.get('redirect');
      // // if (messageForIntegration) {
      // //   console.log('window.location.href', '/integration?int=wix&msg={"status":"success","m":"Integration already in use"}');
      // //   window.location.href = '/integration?int=wix&msg={"status":"success","m":"Integration already in use"}';
      // // } else {
      // window.location.href = res.data.rp;
      // // }

      // // browserHistory.push('/');
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_thinkific');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_thinkific' });
        } catch (error) {
          console.log(error);
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      // let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        // (localStorage.getItem('csub') === '' || localStorage.getItem('csub') === null || localStorage.getItem('csub') === undefined) ?
        //   (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
        //     window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
        //     :
        //     window.location.href = '/onboarding'
        //   :
        //   window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        window.location.href = '/socialproof';
      }, 2000);
      localStorage.removeItem('rdt_url');
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithEcwid(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithEcwid', action.payload);
    // if (res.data.newUser == true) {
    //   setTimeout(() => {
    //     try {
    //       window.gtag('event', 'signup',
    //         {
    //           'event_label': 'signup with ecwid',
    //           'event_category': window.location.hostname
    //         }
    //       );
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }, 1000);
    // }

    if (res.status === 'success') {
      // localStorage.setItem('authToken', res.data.token);
      // localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      // localStorage.setItem('pvd', 'ecwid');
      // localStorage.setItem('isbusr', false);
      // localStorage.setItem('istm', false);
      // localStorage.setItem('e', res.data.e);
      // if (res.data.ti) {
      //   localStorage.setItem('ti', res.data.e);
      // }
      // if (window.fbq) {
      //   window.fbq('track', 'PixelInstall');
      // }
      yield put(toastify({ type: 'success', msg: res.m }));
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_ecwid');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_ecwid' });
        } catch (error) {
          console.log(error);
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        (localStorage.getItem('csub') === '' || localStorage.getItem('csub') === null || localStorage.getItem('csub') === undefined) ?
          (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
            window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
            :
            window.location.href = '/onboarding'
          :
          window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        // window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
      localStorage.removeItem('rdt_url');
      //window.location.href = res.data.rp;
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* loginWithDuda(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/loginWithDuda', action.payload);
    // if (res.data.newUser == true) {
    //   setTimeout(() => {
    //     try {
    //       window.gtag('event', 'signup',
    //         {
    //           'event_label': 'signup with ecwid',
    //           'event_category': window.location.hostname
    //         }
    //       );
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }, 1000);
    // }
    if (res.status === 'success') {
      // localStorage.setItem('authToken', res.data.token);
      // localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.username);
      // localStorage.setItem('pvd', 'ecwid');
      // localStorage.setItem('isbusr', false);
      // localStorage.setItem('istm', false);
      // localStorage.setItem('e', res.data.e);
      // if (res.data.ti) {
      //   localStorage.setItem('ti', res.data.e);
      // }
      // if (window.fbq) {
      //   window.fbq('track', 'PixelInstall');
      // }
      yield put(toastify({ type: 'success', msg: res.m }));
      localStorage.removeItem('cd');//remove appsumo code from localstorage
      // if (window.fcWidget) {
      //   window.fcWidget.user.clear().then(function () {
      //     console.log('User cleared');
      //   }, function () {
      //     console.log('User Not cleared');
      //   });
      // }
      localStorage.setItem('authToken', res.data.t);
      localStorage.setItem('username', res.data.fn ? res.data.fn : res.data.un);
      localStorage.setItem('e', res.data.e);
      localStorage.setItem('ti', res.data.ti);
      localStorage.setItem('istm', res.data.istm);
      localStorage.setItem('isMaster', res.data.isMaster);
      localStorage.setItem('csub', res.data.csub);
      if (res.data.vstp && res.data.vstp.plx && res.data.vstp.plx.st !== true) {
        localStorage.setItem('isPixelIns', false);
      }
      if (res.data.isbusr === true) {
        //white_label
        localStorage.setItem('isbusr', true);
      }
      else {
        //white_label
        localStorage.setItem('isbusr', false);
      }
      if (res.data.pvd === 'shopify' || res.data.pvd === 'wix' || res.data.pvd === 'thinkific' || res.data.pvd === 'duda') {
        localStorage.setItem('pvd', res.data.pvd);
      } else {
        localStorage.setItem('pvd', 'local');
      }
      if (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') {
        localStorage.setItem('utyp', res.data.utyp);
      }
      if (action.payload.newUser && action.payload.newUser === true) {
        localStorage.setItem('isNewUser', true);
        try {
          let tracker = window.ga.getAll()[0];
          tracker.send('event', window.location.hostname, 'sign_up_website', 'create_account_ecwid');
          //for GA-4
          window.gtag('event', window.location.hostname, { 'event_action': 'sign_up_website', 'event_label': 'create_account_ecwid' });
        } catch (error) {
          console.log(error);
        }
        //linkdin tracking 
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 14211337 });
        }
      }
      // if (res.data.role) {
      //   localStorage.setItem('role', res.data.role);
      // }
      //mixpanel start
      // window.mixpanel.identify(res.data.e);
      // window.mixpanel.people.set({
      //   '$email': res.data.e,
      //   '$last_login': new Date()
      // });
      //mixpanel end

      //froged 
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('logout');
        }
      }, 1000);

      //if user not login 
      let rdt_url = localStorage.getItem('rdt_url');
      setTimeout(() => {
        (localStorage.getItem('csub') === '' || localStorage.getItem('csub') === null || localStorage.getItem('csub') === undefined) ?
          (res.data.utyp === 'dealify' || res.data.utyp === 'appsumo') ?
            window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard'
            :
            window.location.href = '/onboarding'
          :
          window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
        // window.location.href = rdt_url ? rdt_url : res.data.rp ? res.data.rp : '/dashboard';
      }, 2000);
      localStorage.removeItem('rdt_url');
      //window.location.href = res.data.rp;
    }
    else if (res.status === 'duplicate') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(loaded());
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

export function* watchGetProfile() {
  yield takeLatest(actions.getProfile.type, getProfile);
}

export function* watchLoginWithBGC() {
  yield takeLatest(actions.loginWithBGC.type, loginWithBGC);
}

export function* watchLoginWithWix() {
  yield takeLatest(actions.loginWithWix.type, loginWithWix);
}

export function* watchLoginWithThinkific() {
  yield takeLatest(actions.loginWithThinkific.type, loginWithThinkific);
}

export function* watchLoginWithEcwid() {
  yield takeLatest(actions.loginWithEcwid.type, loginWithEcwid);
}

export function* watchLoginWithDuda() {
  yield takeLatest(actions.loginWithDuda.type, loginWithDuda);
}

export default function* rootSaga() {
  yield fork(watchGetProfile);
  yield fork(watchLoginWithBGC);
  yield fork(watchLoginWithWix);
  yield fork(watchLoginWithThinkific);
  yield fork(watchLoginWithEcwid);
  yield fork(watchLoginWithDuda);
}