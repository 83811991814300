import { Badge, BlockStack, Box, Button, Card, Divider, InlineStack, Layout, Page, Text, ButtonGroup, InlineGrid, Select, Modal, TextContainer, TextField, SkeletonDisplayText, SkeletonBodyText, Tooltip } from '@shopify/polaris';
import React from 'react';
import { checkCircle_icon, planTrue_icon } from '../../img';
import BillingSummery from '../PricingPlan/BillingSummery';
import { useSelector } from 'react-redux';

function WixPricingPlanCard(props) {

  const { state, changeNameValue, translate } = props;

  const isLoading = useSelector(state => state.loading.isLoading);

  const tabs = [
    { tabName: 'month', label: translate('wixplan').PayMonthly },
    { tabName: 'year', label: translate('wixplan').PayYearly },
  ];

  const planDescList = [
    { icon: checkCircle_icon, label: translate('wixplan').MoneyBack },
    { icon: checkCircle_icon, label: translate('wixplan').CngPlanTime }
  ]

  const freeIncludeList = [
    { icon: planTrue_icon, label: translate('wixplan').LimitedTemp, tooltip: translate('wixplan').LimitedTempTooltip },
    { icon: planTrue_icon, label: translate('wixplan').ActiveNotif, tooltip: translate('wixplan').ActiveNotifTooltip },
    {
      icon: planTrue_icon,
      label: translate('wixplan').UrgencyWidgets,
      tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('wixplan').UrgencyWidgetsSubTxt}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('wixplan').UrgencyWidgetsSubTxtSecond}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('wixplan').UrgencyWidgetsSubTxtThird}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('wixplan').UrgencyWidgetsSubTxtFourth}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('wixplan').MultipleWebsite, tooltip: translate('wixplan').MultipleWebsitesTooltip },
    { icon: planTrue_icon, label: translate('wixplan').Integrations, tooltip: translate('wixplan').IntegrationsTooltip },
    { icon: planTrue_icon, label: translate('wixplan').LiveSupport, tooltip: translate('wixplan').LiveSupportTooltip },
    {
      icon: planTrue_icon, label: translate('wixplan').ReportSetting, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('wixplan').ReportSettingSubTxt}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('wixplan').ReportSettingSubTxtSecond

            }</Text>
          </div>
        </BlockStack>
    }
  ]

  const proIncludeList = [
    { icon: planTrue_icon, label: translate('wixplan').PremTemp, tooltip: translate('wixplan').PremTempTooltip },
    { icon: planTrue_icon, label: translate('wixplan').UnlimitedNotif, tooltip: translate('wixplan').UnlimitedNotifTooltip },
    { icon: planTrue_icon, label: translate('wixplan').BrndCtrl, tooltip: translate('wixplan').BrndCtrlTooltip },
    {
      icon: planTrue_icon, label: translate('wixplan').AbTestTrack, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('wixplan').AbTestTrackTooltip}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('wixplan').AbTestTrackTooltipSecond}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('wixplan').CustomCss, tooltip: translate('wixplan').CustomCssTooltip },
    { icon: planTrue_icon, label: translate('wixplan').OneTeammate, tooltip: translate('wixplan').OneTeammateTooltip }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: translate('wixplan').Inlinenotif, tooltip: translate('wixplan').InlinenotifTooltip },
    { icon: planTrue_icon, label: translate('wixplan').DepAcManager, tooltip: translate('wixplan').DepAcManagerTooltip },
    { icon: planTrue_icon, label: translate('wixplan').CustomDesign, tooltip: translate('wixplan').CustomDesignTooltip },
    { icon: planTrue_icon, label: translate('wixplan').LimitedTemp, tooltip: translate('wixplan').LimitedTempTooltip }
  ]

  return (
    <div className='page-wrap page-plan-wrap'>
      <Box paddingBlockEnd={500}>
        <Page title={translate('wixplan').WixTitle}>
          <Card>
            <BlockStack gap={300}>
              <Text variant='bodyLg' as='h6' fontWeight='semibold'>{translate('wixplan').SetPlanTraffic}</Text>
              <Box>
                <InlineStack gap={200} wrap={false} blockAlign='center'>
                  {planDescList && planDescList.map((data, index) => (
                    <InlineStack key={index} gap={100} blockAlign='center'>
                      <img src={data.icon} alt='' />
                      <Text as='p' fontWeight='regular'>{data.label}</Text>
                    </InlineStack>
                  ))}
                </InlineStack>
              </Box>
            </BlockStack>

            <div id='plan_block'>
              <Box paddingBlockEnd={500}>
                <Layout>
                  <Layout.Section variant='oneThird'>
                    <Box id='pln_basic_block' padding={400} borderRadius={300}>
                      <BlockStack gap={300}>
                        <Box>
                          <Badge tone="warning">{translate('wixplan').FreePlan}</Badge>
                        </Box>
                        <Text variant='bodySm' as='p' fontWeight='regular'>{translate('wixplan').FreePlanSubTxt}</Text>
                        <Divider borderWidth='050' />
                        {isLoading ?
                          <Box>
                            <Box width='30%' paddingBlockStart={300}>
                              <SkeletonBodyText lines={1} />
                            </Box>
                            <Box paddingBlock={400}>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </Box>
                          </Box> :
                          <>
                            <Box>
                              <InlineStack gap={100} blockAlign='center'>
                                <Text variant='headingLg' as='h3'>
                                  $0
                                </Text>
                              </InlineStack>
                            </Box>

                            <TextField
                              value={translate('wixplan').FreeVisitorMonth}
                              autoComplete="off"
                              disabled
                            />
                          </>}
                        <Box paddingBlockStart={400}>
                          <Button id='primary_btn' fullWidth disabled>
                            <Text variant='bodyMd' fontWeight='semibold'>{translate('wixplan').FreePlan} {state.profile && state.profile.csub === 'free' ? translate('wixplan').Activated : ''}</Text>
                          </Button>
                        </Box>

                        <Box>
                          <BlockStack gap={100}>
                            <Text variant='bodyMd' fontWeight='semibold'>
                              Includes
                            </Text>
                            {freeIncludeList && freeIncludeList.map((data, index) => (
                              <InlineStack key={index} gap={100} blockAlign='center'>
                                <img src={data.icon} alt='' />
                                <Tooltip content={data.tooltip} hasUnderline preferredPosition='below' width='wide' dismissOnMouseOut padding="400">
                                  <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                </Tooltip>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Layout.Section>
                  <Layout.Section>
                    <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={800} paddingBlockEnd={500} paddingBlockStart={500}>
                      <InlineStack blockAlign='center' align='center'>
                        <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                          <ButtonGroup>
                            {tabs.map((data, index) => (
                              <Button key={index} variant={state.plv === data.tabName ? 'primary' : ''}
                                onClick={() => props.handleTabChange(data)}>
                                <Text variant='bodyLg' as='p' fontWeight='regular'>{data.label}</Text>
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400}>
                          <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="info">{translate('wixplan').Pro}</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>{translate('wixplan').ProSubTxt}</Text>
                              <Divider />
                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                :
                                <>< Box >
                                  <InlineStack gap={100} blockAlign='center'>
                                    <Text variant='headingLg' as='p' fontWeight='semibold'>${state.selctdProPlanPrice ? state.selctdProPlanPrice : '-'}</Text>
                                    <Text variant='headingXs' as='p' fontWeight='regular'>{state.plv === 'month' ? translate('wixplan').PerMonth : translate('wixplan').PerYear}</Text>
                                    {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId)?.prdu.toLowerCase() === state.plv && (
                                      <Badge tone="success">{translate('wixplan').Selected}</Badge>
                                    )}
                                  </InlineStack>
                                </Box>

                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.proOptionList}
                                      onChange={(e) => changeNameValue({ selctdProPlanVisitor: parseInt(e) })}
                                      value={state.selctdProPlanVisitor}
                                    />
                                  </Box>
                                </>}

                              {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId)?.prdu.toLowerCase() === state.plv ?
                                <Button id='primary_btn' disabled fullWidth onClick={() => props.handleWixPayment(state.selctdProPlanVisitor, state.plv)}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('wixplan').CurrentActivePlan}</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleWixPayment(state.selctdProPlanVisitor, state.plv)} disabled={state.selctdProPlanPrice < state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      `${translate('wixplan').Upgrade} ${state.plv === 'year' ? translate('wixplan').Yearly : translate('wixplan').Monthly}` : translate('wixplan').GetStartedNow
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('wixplan').EverythingInBasic}</Text>
                                </Box>
                                <BlockStack gap={100}>

                                  {proIncludeList && proIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center'>
                                      <img src={data.icon} alt='' />
                                      <Tooltip content={data.tooltip} hasUnderline preferredPosition='below' width='wide' dismissOnMouseOut padding="400">
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}

                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>

                          <Box background='bg-surface' padding={400} borderRadius='300'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="attention">{translate('wixplan').Growth}</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>{translate('wixplan').GrowthSubTxt}</Text>
                              <Divider />
                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                : <>
                                  <Box>
                                    <InlineStack gap={100} blockAlign='center'>
                                      <Text variant='headingLg' as='h3'>
                                        ${state.selctdGrowthPlanPrice ? state.selctdGrowthPlanPrice : '-'}
                                      </Text>
                                      <Text variant='bodySm' as='p' >{state.plv === 'month' ? translate('wixplan').PerMonth : translate('wixplan').PerYear}</Text>

                                      {state?.selctdPlanId && (state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId)?.prdu.toLowerCase() === state.plv) && (
                                        <Badge tone="success">{translate('wixplan').Selected}</Badge>
                                      )}

                                    </InlineStack>
                                  </Box>
                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.growthOptionList}
                                      onChange={(e) => changeNameValue({ selctdGrowthPlanVisitor: parseInt(e) })}
                                      value={state.selctdGrowthPlanVisitor}
                                    />
                                  </Box>
                                </>}
                              {state?.selctdPlanId && state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId)?.prdu.toLowerCase() === state.plv ?
                                <Button id='primary_btn' fullWidth onClick={() => props.handleWixPayment(state.selctdGrowthPlanVisitor, state.plv)}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('wixplan').CurrentActivePlan}</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleWixPayment(state.selctdGrowthPlanVisitor, state.plv)} disabled={state.selctdGrowthPlanPrice < state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold' >
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      `${translate('wixplan').Upgrade} ${state.plv === 'year' ? translate('wixplan').Yearly : translate('wixplan').Monthly}` : translate('wixplan').GetStartedNow
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    {translate('wixplan').EverythingInPro}
                                  </Text>
                                </Box>
                                <BlockStack gap={100}>
                                  {growthIncludeList && growthIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center'>
                                      <img src={data.icon} alt='' />
                                      <Tooltip content={data.tooltip} hasUnderline preferredPosition='below' width='wide' dismissOnMouseOut padding="400">
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}
                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>
                        </InlineGrid>
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Box>
              <Card>
                <InlineStack align='space-between' blockAlign='center'>
                  <BlockStack gap={100}>
                    <Text variant='bodySm' as='h6' fontWeight='semibold'>{translate('wixplan').HaveMoreVisitor}</Text>
                    <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular'>{translate('wixplan').HaveMoreVisitorSubTxt}</Text>
                  </BlockStack>
                  {localStorage.getItem('isbusr') === 'false' &&
                    <Button>{translate('wixplan').ChatWithUs}</Button>
                  }
                </InlineStack>
              </Card>
            </div>

          </Card>

          {state.profile && state.profile.billing && state.profile.billing.icnc === false &&
            <Box paddingBlock={700}>
              <Box paddingBlockEnd={'200'}>
                <Text as='p' variant='bodyLg' fontWeight='semibold'>{translate('wixplan').ActivePlan}</Text>
              </Box>
              <BillingSummery props={props} />
            </Box>
          }

          <Modal
            open={state.wixOpen}
            onClose={() => props.handleCloseModal()}
            title=""
            primaryAction={{
              content: props.loading ? 'Wait a moment' : 'Reload',
              onAction: () => window.location.reload(),
              disabled: props.loading,
            }}
          >
            <Modal.Section>
              <TextContainer>
                <p>Please subscribe to your desired plan at the checkout page opened in a new tab.</p>
                <p>After you've subscribed, hit the reload button.</p>
              </TextContainer>
            </Modal.Section>
          </Modal>

        </Page>
      </Box>
    </div>
  );
}

export default WixPricingPlanCard;
