import React, { useCallback, useEffect, useState } from 'react';
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, InlineStack, List, Modal, ProgressBar, Select, Text, TextField } from '@shopify/polaris';
import { reload_icon } from '../../img';
import { useDispatch, useSelector } from 'react-redux';
import * as NotifShopifyDucks from '../../ducks/notifShopify';
import * as pricingPlanDucks from '../../ducks/pricingPlan';
import SimpleReactValidator from "simple-react-validator";
import { checkPermission } from '../../services/permissions';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';
const moment = require('moment');
let expDt = '';
let isFreeExpire = false;
let _mailTo;
let _width;
let usedQuota = 0;

function BillingSummery({ props }) {
  const dispatch = useDispatch();
  const { translate } = useLanguage();
  const validator = new SimpleReactValidator({ locale: 'en' });

  const [state, setState] = useState({
    profile: {},
    billing: {},
    planUse: 0,
    errMessage: {},
    cancelAutoRenewalOpen: false,
    cancelTyp: '',
    cancelText: '',
    cancelLbl: translate('billingSummery').CancelOptionLabel,
    planDetailOpen: false,
  });

  //set changeNameValue data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //api data.
  const profileState = useSelector(state => state.auth.profile);
  const isLoading = useSelector(state => state.loading.isLoading);

  //set profile & billing & planUse state data.
  useEffect(() => {
    if (profileState) {
      let planUse = profileState.uuq / profileState.uaq * 100;

      if (planUse.toString() === 'NaN') {
        planUse = 0;
      }
      let obj = {
        profile: profileState,
        billing: profileState?.billing,
        planUse: parseFloat(planUse.toFixed())
      }
      changeNameValue(obj);
    }
  }, [profileState]);

  useEffect(() => {

    if (state.planUse) {
      if (state.planUse > 100) {
        _width = '100';
      }
      else {
        _width = `${state.planUse}`;
      }
      if (state.planUse.toString() === 'NaN') {
        state.planUse = 0;
      }
    }

    // usedQuota
    if (profileState) {
      if (profileState.uuq > profileState.uaq) {
        usedQuota = profileState.uaq;
      }
      else {
        if (profileState.suaq) {
          usedQuota = profileState.uuq + profileState.suaq;
          if (usedQuota > profileState.uaq) {
            usedQuota = profileState.uaq;
          }
        }
        else {
          usedQuota = profileState.uuq;
        }
      }
      if (profileState.puuq) {
        usedQuota = usedQuota + profileState.puuq;
      }
    }
  }, [state.planUse, profileState]);

  useEffect(() => {
    if (state.profile.csub === 'free' && (localStorage.getItem('pvd') === 'local' || localStorage.getItem('pvd') === 'bigcommerce' || localStorage.getItem('pvd') === 'thinkific' || localStorage.getItem('pvd') === 'wix' || localStorage.getItem('pvd') === 'duda' || localStorage.getItem('pvd') === 'shopify')) {
      let newRegDate = new Date(state.profile.cdt);
      // let registerDay = new Date(state.profile.cdt).toLocaleString();
      // if (new Date(registerDay) - new Date('1/19/2022') < 0) {
      //   newRegDate = new Date('1/19/2022');
      // }
      let expDate = new Date(newRegDate).setDate(newRegDate.getDate() + 15);
      let now = new Date().getTime();
      let distance = expDate - now;
      expDt = Math.max(0, Math.floor(distance / (1000 * 60 * 60 * 24)));
      if (expDt > 14 || Math.sign(expDt) === -1 || state.profile.isfrexp === true) {
        isFreeExpire = true;
        expDt = 'Your free trial has expired on ' + moment(new Date(expDate)).format('MMM-DD-YYYY') + '.';
      }
      else {
        expDt = 'Your trial will end on ' + moment(new Date(expDate)).format('MMM-DD-YYYY') + '.';
      }
    }
    if (state.profile.brand) {
      _mailTo = 'mailto:' + state.profile.brand.feadr;
    }

    //if login user is team member
    if (localStorage.getItem('istm') === 'true') {
      if (state.profile && state.profile.userTeam && state.profile.userTeam.iu) {
        changeNameValue({ iu: state.profile.userTeam.iu });
      }
    }
    else {
      if (state.profile && state.profile.iu) {
        changeNameValue({ iu: state.profile.iu });
      }
    }
    if (state.profile && state.profile.uuq !== undefined) {
      let _uuq = state.profile.uuq;
      if (state.profile.suaq) {
        _uuq = _uuq + state.profile.suaq;
      }
      if (state.profile.puuq) {
        _uuq = _uuq + state.profile.puuq;
      }
      let planUse = _uuq / state.profile.uaq * 100;
      //get current timezone offset
      let _offset = new Date().getTimezoneOffset();
      // //get next month billing date
      // if (state.profile.nxtb) { //&& state.profile.csub == 'free'
      //   if (Math.sign(_offset) == -1 && _offset > -330) {
      //     let reset = new Date(state.profile.nxtb);
      //     reset.setDate(reset.getDate() + 1);
      //     state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
      //   } else {
      //     let reset = new Date(state.profile.nxtb);
      //     state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
      //   }
      // }
      // else 
      //get next billing date
      if (state.profile.nxtb) {
        if (Math.sign(_offset) === -1 && _offset > -330) {
          let reset = new Date(state.profile.nxtb);
          reset.setDate(reset.getDate() + 1);
          state.billingDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
        else {
          let reset = new Date(state.profile.nxtb);
          state.billingDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
      }
      else if (state.profile.billing && state.profile.billing && state.profile.billing.sobj && state.profile.billing.sobj.next_billing_at) {
        if (Math.sign(_offset) === -1 && _offset > -330) {
          let reset = new Date(state.profile.billing.sobj.next_billing_at);
          reset.setDate(reset.getDate() + 1);
          state.billingDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
        else {
          let reset = new Date(state.profile.billing.sobj.next_billing_at);
          state.billingDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
      }


      if (state.profile.uqrcrdt) {
        if (Math.sign(_offset) === -1 && _offset > -330) {
          let reset = new Date(state.profile.uqrcrdt);
          reset = reset.setMonth(reset.getMonth() + 1);
          reset = new Date(reset);
          reset.setDate(reset.getDate() + 1);
          state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
        else {
          let reset = new Date(state.profile.uqrcrdt);
          reset = reset.setMonth(reset.getMonth() + 1);
          reset = new Date(reset);
          state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
      }
      else if (state.profile.nxtb) {
        if (Math.sign(_offset) === -1 && _offset > -330) {
          let reset = new Date(state.profile.nxtb);
          reset.setDate(reset.getDate() + 1);
          state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
        else {
          let reset = new Date(state.profile.nxtb);
          state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
      }
      else if (state.profile.pvd === 'shopify' && state.profile.billing) {
        if (Math.sign(_offset) === -1 && _offset > -330) {
          let reset = new Date(state.profile.billing.cdt);
          reset = new Date(reset.setMonth(reset.getMonth() + 1));
          reset = new Date(reset);
          reset.setDate(reset.getDate() + 1);
          state.resetDate = moment(new Date(reset)).format('MMM DD YYYY');
        }
        else {
          let newDate = new Date(state.profile.billing.cdt);
          newDate = new Date(newDate.setMonth(newDate.getMonth() + 1));
          state.resetDate = moment(new Date(newDate)).format('MMM DD YYYY');
        }
      }
      else if (state.profile.nxtrcrdt) {
        state.resetDate = moment(new Date(state.profile.nxtrcrdt)).format('MMM DD YYYY');
      }
      changeNameValue({ planUse: planUse });
    }
  }, [state.profile]);

  //open plan Detail model.
  const openPlanDetail = () => {
    openCloseModal('planDetailOpen', state.planDetailOpen, 'open');
  }

  //formmet number.
  const formmetNumber = (nu) => {
    return nu.toLocaleString();
  }

  //convert.
  const convert = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K';
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
  };

  //openChat.
  const openChat = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  };

  //handle Get Uniq Cron.
  const handleGetUniqCron = () => {
    const ti = localStorage.getItem('ti');
    dispatch(NotifShopifyDucks.getUniqCron(ti));
  }

  //open close modal.
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) { //stop closing on outside click 
      changeNameValue({ [name]: !value });
    }
  }, []);

  //cancel Options.
  const cancelOptions = [
    { label: translate('billingSummery').CancelOptionOne, value: 'Lack of features' },
    { label: translate('billingSummery').CancelOptionTwo, value: 'High cost' },
    { label: translate('billingSummery').CancelOptionThree, value: 'Poor customer service' },
    { label: translate('billingSummery').CancelOptionFour, value: 'Difficulty of use' },
    { label: translate('billingSummery').CancelOptionFive, value: 'Bugs or technical issues' },
    { label: translate('billingSummery').CancelOptionSix, value: 'Better alternatives available' },
    { label: translate('billingSummery').CancelOptionSeven, value: 'No longer needed or used.' },
    { label: translate('billingSummery').CancelOptionEight, value: 'Not getting benefits' },
    { label: translate('billingSummery').CancelOptionNine, value: 'Others' }
  ];

  //handle Select Reason.
  const handleSelectReason = (value) => {
    let label = translate('billingSummery').CancelOptionLabel;

    switch (value) {
      case 'Lack of features':
        label = translate('billingSummery').CancelOptionLabelOne;
        break;
      case 'High cost':
        label = translate('billingSummery').CancelOptionLabelTwo;
        break;
      case 'Poor customer service':
        label = translate('billingSummery').CancelOptionLabelThree;
        break;
      case 'Difficulty of use':
        label = translate('billingSummery').CancelOptionLabelFour;
        break;
      case 'Bugs or technical issues':
        label = translate('billingSummery').CancelOptionLabelFive;
        break;
      case 'Better alternatives available':
        label = translate('billingSummery').CancelOptionLabelSix;
        break;
      case 'No longer needed or used.':
        label = translate('billingSummery').CancelOptionLabelSeven;
        break;
      case 'Others':
        label = translate('billingSummery').CancelOptionLabel;
        break;
      default:
        label = translate('billingSummery').CancelOptionLabel;
    }
    changeNameValue({ cancelTyp: value, cancelLbl: label });
  }

  //handle Cancel Subscription.
  const handleCancelSubscription = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      validator.helpers.forceUpdateIfNeeded();
      changeNameValue({ errMessage: validator.errorMessages });
    }
    else {
      changeNameValue({ errMessage: {} });
      //set plan cancel event in froged
      setTimeout(() => {
        if (window.Froged) {
          window.Froged('track', 'plan_cancel');
        }
      }, 1000);

      let calSub = {
        sbi: state.billing?.sobj?.id,
        blid: state.billing?._id,
        fn: state.profile.fn,
        e: state.profile.e,
        crsn: state.cancelTyp,
        ctxt: state.cancelText ? state.cancelText : ''
      };
      dispatch(pricingPlanDucks.cancelSubscription(calSub, state.billing?._id));
      openCloseModal('cancelAutoRenewalOpen', state.cancelAutoRenewalOpen, 'close')
    }
  }

  //display paid amount
  let paidPrice = '';
  if (state.profile && state.profile.nxtbamt) {
    paidPrice = state.profile.nxtbamt;
  }
  else if (state && state.billing) {
    paidPrice = state.billing?.pobj ? state.billing?.pobj.price / 100 : state.billing?.price;
    if (state && state.billing.iobj && state.billing.iobj.amount_paid) {
      paidPrice = state.billing.iobj.amount_paid / 100;
    }
  }

  const handleUpgrade = () => {
    if (window._cio) {
      window._cio.track('Current_Upgrade');
    }
  }

  const handleResumePlan = () => {
    let obj = {
      subId: state.profile.billing && state.profile.billing.sbid,
      blid: state.profile.blid,
      e: state.profile.e,
    }
    dispatch(pricingPlanDucks.resumePlan(obj));
  }

  return (
    <div className='card-active-plan'>
      {
        localStorage.getItem('utyp') !== 'appsumo' ?
          state.profile.blid &&
          <Card>
            <BlockStack gap={400}>
              <InlineStack gap={200} blockAlign='center'>
                <Text variant='bodyMd' as='h4' fontWeight='semibold'>{translate('billingSummery').CurrentPlan}</Text>
                {
                  (localStorage.getItem('pvd') === 'wix' || localStorage.getItem('pvd') === 'shopify') ?
                    <Badge tone='success'>
                      {state.profile?.csub !== 'free' ?
                        <>
                          {/* Check if grp is undefined, then display name, pinm, or pnm */}
                          {state.billing?.pobj?.grp === undefined ? (
                            state.billing?.pobj?.name ? (
                              <Text as="span" variant="bodyMd">{state.billing?.pobj?.name} </Text>
                            ) : state.billing?.pobj?.pinm ? (
                              <Text as="span" variant="bodyMd">{state.billing?.pobj?.pinm} </Text>
                            ) : state.billing?.pobj?.pnm ? (
                              <Text as="span" variant="bodyMd">{state.billing?.pobj?.pnm} </Text>
                            ) : null
                          ) : null}

                          {/* Display pn if name, pinm, pnm, or grp is undefined */}
                          {(state.billing?.pobj?.name || state.billing?.pobj?.pinm || state.billing?.pobj?.pnm || state.billing?.pobj?.grp === undefined) &&
                            state.billing?.pobj?.pn && (
                              <Text as="span" variant="bodyMd">{state.billing?.pobj?.pn} </Text>
                            )}

                          {state.billing?.pobj?.uaq &&
                            <>
                              {state.billing?.pobj?.grp && <Text as='span' variant='bodyMd'>{state.billing?.pobj?.grp === 'pro' ? 'Pro' : 'Growth'} </Text>}
                              <Text as='span' variant='bodyMd'>{convert(state.billing?.pobj?.uaq)} {translate('billingSummery').visitorsMonthly}: </Text>
                            </>
                          }


                          ${state.billing?.pobj ?
                            parseFloat(state.billing?.pobj.prc).toFixed(2) :
                            parseFloat(state.billing?.prc).toFixed(2)
                          }
                          /{state.billing?.pobj?.pt ? state.billing?.pobj?.pt : state.billing?.pobj?.prdu}
                        </> :
                        <>
                          {translate('billingSummery').FreeVisitorsMonthly}
                        </>
                      }
                    </Badge>
                    :

                    localStorage.getItem('pvd') === 'local' && state.profile.csub === 'free' ?
                      <Badge tone='success'>Free</Badge>
                      :
                      profileState?.agc !== undefined ?
                        <Badge tone='success' >{state.billing?.pobj?.pn}: ${state.billing?.pobj ? state.billing?.pobj.prc : state.billing?.prc}.00/{state.billing?.pobj?.piunt && state.billing?.pobj?.piunt}</Badge>
                        :
                        <Badge tone='success' >{state.billing?.pobj?.name} : ${paidPrice}/{state.billing?.pobj?.period_unit && state.billing?.pobj?.period_unit}</Badge>
                }
                {/* <Badge>{`Next billing date : ${state.billingDate ? state.billingDate : '-'}`}</Badge> */}
                {localStorage.getItem('pvd') === 'local' && state.profile.csub === 'free' ?
                  '' :
                  state.profile.billing && state.profile.billing.icnc === true && state.profile.billing.iexp === false ?
                    <Badge tone='critical'>{translate('billingSummery').PlanExpireDate} : {state.billingDate ? state.billingDate : '-'}</Badge>
                    :
                    <Badge>
                      {localStorage.getItem('isbusr') === 'false'
                        ? `${translate('billingSummery').NextBillingDate} : ${state.billingDate ? state.billingDate : '-'}`
                        : `${translate('billingSummery').PlanExpireDate} : ${state.billing?.pobj?.piunt === 'lifetime'
                          ? `${translate('billingSummery').Lifetime}`
                          : state.billing?.pendt
                            ? moment(state.billing.pendt).format('MMM DD YYYY')
                            : '-'
                        }`}
                    </Badge>
                }

              </InlineStack>
              <InlineStack blockAlign='end' wrap={false} gap={600}>
                <Box width='66%'>
                  <BlockStack gap={200}>
                    <InlineStack align='space-between' blockAlign='center'>
                      <InlineStack gap={500} blockAlign='center' wrap={false}>
                        {/* <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`Visitors Used: ${state.profile?.uuq ? formmetNumber(state.profile?.uuq) : 0} out of ${state.profile?.uaq ? formmetNumber(state.profile?.uaq) : 0} monthly visitors`}</Text> */}
                        <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`${localStorage.getItem('pvd') !== 'local' ? translate('billingSummery').MonthlyVisitorsUsed : 'visitors used'} : ${usedQuota ? formmetNumber(usedQuota) : 0} / ${state.profile?.uaq ? formmetNumber(state.profile?.uaq) : 0} (${state.planUse.toString() === 'NaN' ? '0' : state.planUse.toFixed() > 100 ? '100' : state.planUse.toFixed()}%)`}</Text>
                        <InlineStack gap={100} wrap={false}>
                          <img className={isLoading ? 'icon-refresh' : ''} src={reload_icon} alt='reload' />
                          <div onClick={() => handleGetUniqCron()} style={{ cursor: "pointer" }}>
                            <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{translate('billingSummery').Refresh}</Text>
                          </div>
                        </InlineStack>
                      </InlineStack>
                      {/* <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{state.planUse}%</Text> */}
                    </InlineStack>
                    <ProgressBar progress={_width} size="small" />
                  </BlockStack>
                </Box>
                <Box>
                  {props?.planBilling && state.profile.csub !== 'free' ?
                    state.profile.billing && state.profile.billing.icnc === true && state.profile.billing.iexp === false ?
                      <Button variant='plain' tone='critical' onClick={() => handleResumePlan()}>{translate('billingSummery').ResumePlanNow}</Button>
                      :
                      <Button variant='plain' tone='critical' onClick={() => openCloseModal('cancelAutoRenewalOpen', state.cancelAutoRenewalOpen, 'open')}>{translate('billingSummery').CancelAutoRenewal}</Button>
                    :
                    localStorage.getItem('isbusr') === 'false' &&
                    checkPermission('profile', 'isedit', state.profile) &&
                    <Link to={'/plan'}><Button id='primary_btn' variant='primary' onClick={() => handleUpgrade()}>{translate('billingSummery').Upgrade}</Button></Link>
                  }
                  {/* {state.profile.billing && state.profile.billing.icnc === true && state.profile.billing.iexp === false } */}
                </Box>
              </InlineStack>
              {localStorage.getItem('pvd') === 'local' && state.profile.csub === 'free' ?
                ''
                :
                state.resetDate && (
                  <Text
                    variant="headingXs"
                    as="span"
                    fontWeight="regular"
                    tone="subdued"
                  >
                    {`${translate('billingSummery').YourVisitorQuotaResetDate}: ${moment(state.resetDate).format('MMM DD, YYYY')}.`}
                  </Text>
                )}

              {/* <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`Your visitor quota reset date : ${state.resetDate ? moment(state.resetDate).format('MMM DD, YYYY') : '-'}`}.</Text> */}

              {/* {state.profile && state.profile.csub === 'free' ?
            // localStorage.getItem('pvd') == 'local' ?
            // <List type="bullet">
            //   <List.Item>You’re using Free Trial</List.Item>
            //   <List.Item>{expDt}</List.Item>
            // </List>
            //   :
            // <List type="bullet">
            //   <List.Item>You’re using Free plan</List.Item>
            //   <List.Item>Your visitor quota limit will reset on {state.resetDate}.</List.Item>
            // </List>
            isFreeExpire === true ?
              <List type="bullet">
                <List.Item>{expDt}</List.Item>
                <List.Item>Upgrade your plan to continue using our services</List.Item>
              </List>
              :
              // <List type="bullet">
              //   <List.Item>Currently, Your are using your free trial</List.Item>
              //   <List.Item>{expDt}</List.Item>
              // </List>
              <List type="bullet">
                <List.Item>You’re using Free plan</List.Item>
                <List.Item>Your visitor quota limit will reset on {state.resetDate}.</List.Item>
              </List>
            :
            //white_label
            state.profile.isbusr ?
              new Date() > new Date(state.profile.billing.pendt) ?
                <List type="bullet">
                  <List.Item>You’re using <Button variant='plain' onClick={() => openPlanDetail()}> {state.profile && state.profile.billing && state.profile.billing.pobj.pn.replace('plan', '')} plan</Button></List.Item>
                  <List.Item>Your plan is expired.</List.Item>
                  <List.Item>For more details & support, kindly contact us on <Button variant='plain' url={_mailTo} target='_blank'>{state.profile.brand.feadr}</Button></List.Item>
                </List>
                :
                <List type="bullet">
                  <List.Item>You’re using <Button variant='plain' onClick={() => openPlanDetail()}> {state.profile && state.profile.billing && state.profile.billing.pobj.pn.replace('plan', '')} plan</Button></List.Item>
                  <List.Item>Your visitor quota limit will reset on {state.resetDate}.</List.Item>
                  {state.profile.billing.pendt &&
                    <List.Item>Plan will be expired on {moment(new Date(state.profile.billing.pendt)).format('MMM DD YYYY')}.</List.Item>
                  }
                  <List.Item>For more details & support, kindly contact us on <Button variant='plain' url={_mailTo} target='_blank'>{state.profile.brand.feadr}</Button></List.Item>
                </List>
              :
              state.profile.csub &&
              <div>
                {state.profile && state.profile.suaq && state.profile.csub === 'agency' &&
                  <List type="bullet">
                    <List.Item>{formmetNumber(state.profile.uuq) + ' visitor quota is used by agency account & ' + formmetNumber(state.profile.suaq) + ' visitor quota is allocated to sub users account.'}</List.Item>
                  </List>
                }
                {state.profile && state.profile.billing && state.profile.billing.pobj &&
                  state.profile.billing.pobj.pnm ?
                  <List type="bullet">
                    <List.Item>You’re using {state.profile.billing.pobj.pnm.replace('plan', '').replace('Plan', '')} plan.</List.Item>
                  </List>
                  :
                  <List type="bullet">
                    <List.Item>You’re using {state.profile && state.profile.billing && state.profile.billing.pobj && state.profile.billing.pobj.pn && state.profile.billing.pobj.pn.replace('plan', '').replace('Plan', '') || state.profile.billing.pobj.name && state.profile.billing.pobj.name.replace('plan', '').replace('Plan', '')} plan</List.Item>
                  </List>
                }
                <List type="bullet">
                  <List.Item>Your visitor quota limit will reset on {state.resetDate}.</List.Item>
                </List>
              </div>
          } */}
            </BlockStack>
          </Card>
          :
          <Card>
            <BlockStack gap={400}>
              <InlineStack align='space-between' blockAlign='end' wrap={false} gap={200}>
                <Box width='85%'>
                  <BlockStack gap={200}>
                    <InlineStack align='space-between' blockAlign='center'>
                      <InlineStack gap={500} blockAlign='center' wrap={false}>
                        <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{`${translate('billingSummery').VisitorsUsed}: ${usedQuota ? formmetNumber(usedQuota) : 0} ${translate('billingSummery').outOf} ${state.profile?.uaq ? formmetNumber(state.profile?.uaq) : 0} ${translate('billingSummery').monthlyVisitors}`}</Text>
                        <InlineStack gap={100} wrap={false}>
                          <img className={isLoading ? 'icon-refresh' : ''} src={reload_icon} alt='reload' />
                          <div onClick={() => handleGetUniqCron()} style={{ cursor: "pointer" }}>
                            <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{translate('billingSummery').Refresh}</Text>
                          </div>
                        </InlineStack>
                      </InlineStack>
                      <Text variant='headingXs' as='span' fontWeight='regular' tone='subdued'>{state.planUse.toFixed() > 100 ? '100' : state.planUse.toFixed()}%</Text>
                    </InlineStack>
                    <ProgressBar progress={_width} size="small" />
                  </BlockStack>
                </Box>
              </InlineStack>
              <div>
                <List type="bullet">
                  {state.profile &&
                    state.profile.suaq &&
                    state.profile.suaq !== 0 ?
                    <List.Item>{formmetNumber(state.profile.uuq) + ` ${translate('billingSummery').visitorQuotaUsedAgency} & ` + formmetNumber(state.profile.suaq) + ` ${translate('billingSummery').visitorQuotaAllocatedSubUsers}`}</List.Item>
                    : ''
                  }
                  {
                    state.profile && state.profile.csub && state.profile.billing && state.profile.billing.icnc !== true && state.profile.billing.iexp !== true ?
                      <List.Item>{translate('billingSummery').YoureUsingLifetime} + {state.profile && state.profile.billing && state.profile.csub.replace('plan', '')} plan.</List.Item>
                      :
                      <List.Item>{translate('billingSummery').YoureUsingLifetimePlan}</List.Item>
                  }
                  {
                    state.profile &&
                    <List.Item>{translate('billingSummery').YourVisitorQuotaLimit} {state.resetDate}.</List.Item>
                  }
                </List>
              </div>
            </BlockStack>
          </Card>
      }
      {/* Cancel Auto-Renewal Modal */}
      <Modal
        open={state.cancelAutoRenewalOpen}
        title={<Text variant='headingMd' fontWeight='bold'>{translate('billingSummery').CancelAutoRenewal}</Text>}
        onClose={(e) => openCloseModal('cancelAutoRenewalOpen', state.cancelAutoRenewalOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            <Box paddingBlockEnd={400}>
              <Text variant='headingMd' as='p' fontWeight='regular' >{translate('billingSummery').cancelAutoRenewalTitle}</Text>
            </Box>
            <BlockStack gap={400}>
              <div>
                <Select
                  label={translate('billingSummery').SelectReasonLabel}
                  placeholder={translate('billingSummery').SelectReasonPlaceholder}
                  options={cancelOptions}
                  value={state.cancelTyp}
                  onChange={(value) => handleSelectReason(value)}
                  error={state.errMessage.cancelTyp ? "The reason field is required." : false}
                />
                {validator.message('cancelTyp', state.cancelTyp, 'required')}
              </div>
              <div>
                <TextField
                  label={state.cancelLbl}
                  value={state.cancelText}
                  onChange={(e) => changeNameValue({ cancelText: e })}
                  multiline={4}
                  autoComplete="off"
                  error={state.errMessage.cancelText ? "The detail field is required." : false}
                />
                {validator.message('cancelText', state.cancelText, 'required')}
              </div>
            </BlockStack>
            <Box paddingBlockStart={600}>
              <InlineStack align='space-between' blockAlign='center' wrap={false}>
                {localStorage.getItem('isbusr') === 'false' &&
                  <Text variant='headingMd' as='span' fontWeight='regular'>{translate('billingSummery').NeedHelp} <Button variant='plain' onClick={() => openChat()}>{translate('billingSummery').ChatWithUs}</Button></Text>
                }
                <ButtonGroup>
                  <Button id='primary_btn' onClick={() => openCloseModal('cancelAutoRenewalOpen', state.cancelAutoRenewalOpen, 'close')}>{translate('billingSummery').KeepAccount}</Button>
                  <Button onClick={() => handleCancelSubscription()}>{translate('billingSummery').CancelPlan}</Button>
                </ButtonGroup>
              </InlineStack>
            </Box>
          </div>
        </Modal.Section>
      </Modal>

      {/* planDetail Modal */}
      <Modal
        open={state.planDetailOpen}
        title={<Text variant='headingMd' fontWeight='bold'>Current plan details</Text>}
        onClose={(e) => openCloseModal('planDetailOpen', state.planDetailOpen, e)}>
        <Modal.Section>
          <div className="pageAction_paDDing">
            {state.billing &&
              <Box paddingInline={400} paddingBlockEnd={400}>
                <BlockStack gap={100}>
                  <List type="bullet">
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Name:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.billing.pobj?.pn ? state.billing.pobj?.pn : '-'}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Price:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>${state.billing.pobj?.prc ? state.billing.pobj?.prc : '-'}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Unique Visitor:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.billing.pobj?.uaq ? state.billing.pobj?.uaq : 0}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Teammate Quota:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.billing.pobj?.taq ? state.billing.pobj?.taq : 0}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Remove Brandname:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.billing.pobj?.crbn ? 'yes' : 'no'}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Change Brand URL:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.billing.pobj?.taq ? 'yes' : 'no'}</Text>
                    </InlineStack>
                    <InlineStack>
                      <div className='bs_detail_wdth'>
                        <List.Item>Quota Reset Date:</List.Item>
                      </div>
                      <Text variant='headingSm' as="span" fontWeight='semibold'>{state.resetDate ? state.resetDate : '-'}</Text>
                    </InlineStack>
                    {(state.billing.pobj?.piunt !== 'lifetime' && state.billing?.pendt) &&
                      <InlineStack>
                        <div className='bs_detail_wdth'>
                          <List.Item>Plan Expire Date:</List.Item>
                        </div>
                        <Text variant='headingSm' as="span" fontWeight='semibold'>{moment(new Date(state.billing?.pendt)).format('MMM DD YYYY')}</Text>
                      </InlineStack>
                    }
                    {state.billing.pobj?.piunt === 'lifetime' &&
                      <InlineStack>
                        <div className='bs_detail_wdth'>
                          <List.Item>Plan Expire Date:</List.Item>
                        </div>
                        <Text variant='headingSm' as="span" fontWeight='semibold'>Lifetime</Text>
                      </InlineStack>
                    }
                  </List>
                </BlockStack>
              </Box>
            }
          </div>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default BillingSummery;