import React, { useEffect, useState } from 'react';
import HeaderCard from '../Layout/Header';
import { createApp } from '@shopify/app-bridge';
import { AppLink, NavigationMenu, Redirect } from '@shopify/app-bridge/actions';
import { useNavigate } from 'react-router-dom';
import ShopifyEmbedGetStart from './ShopifyEmbedGetStart';
import ThirdParty from './ThirdParty';
// import { useLanguage } from '../../LanguageContext';

function EntryCard(props) {
  const navigate = useNavigate();
  // const { triggerShopify, setTriggerShopify } = useLanguage();

  const [isMainAppVisible, setIsMainAppVisible] = useState(false);
  const handleGetStarted = () => {
    setIsMainAppVisible(true);
  };

  useEffect(() => {
    //subscribe shopify admin menu navigation click event
    const strObjConfig = localStorage.getItem('store_config');
    if (strObjConfig) {
      try {
        const appShopify = createApp(JSON.parse(strObjConfig));
        let arrItems = [];

        if (localStorage.getItem('pvd') === 'shopify' && !localStorage.getItem('shp_cnt')) {
          arrItems.push(AppLink.create(appShopify, { label: 'Notifications', destination: '/notifShopify' }));
        } else {
          arrItems = arrItems.concat([
            AppLink.create(appShopify, { label: 'Social proof', destination: '/socialproof' }),
            AppLink.create(appShopify, { label: 'Widgets', destination: '/widgets' }),
            AppLink.create(appShopify, { label: 'Inline', destination: '/inline' })
          ]);
        }

        arrItems = arrItems.concat([
          AppLink.create(appShopify, { label: 'Reports', destination: '/report' }),
          // AppLink.create(appShopify, { label: 'Performance', destination: '/performance' }),
          // AppLink.create(appShopify, { label: 'Analytics', destination: '/analytic' }),
          // AppLink.create(appShopify, { label: 'Visitor', destination: '/visitor' }),
          // AppLink.create(appShopify, { label: 'Goal', destination: '/goal' }),
          AppLink.create(appShopify, { label: 'Data', destination: '/data' }),
          AppLink.create(appShopify, { label: 'Integrations', destination: '/integration' }),
          AppLink.create(appShopify, { label: 'Plan', destination: '/plan' }),
          // AppLink.create(appShopify, { label: 'Billing invoice', destination: '/payment' }),
          // AppLink.create(appShopify, { label: 'Account', destination: '/profile' }),
          // AppLink.create(appShopify, { label: 'Settings', destination: '/setting' }),
        ]);

        if (localStorage.getItem('pvd') !== 'shopify') {
          arrItems = arrItems.concat([
            AppLink.create(appShopify, { label: 'Billing invoice', destination: '/payment' }),
          ]);
        }

        arrItems = arrItems.concat([
          AppLink.create(appShopify, { label: 'Account', destination: '/profile' }),
          AppLink.create(appShopify, { label: 'Settings', destination: '/setting' }),
        ]);

        //Navigation menu for shopify
        NavigationMenu.create(appShopify, {
          items: arrItems
        });

        const unsubscribe = appShopify.subscribe(Redirect.Action.APP, (payload) => {
          window._cio?.page(payload.path);
          //ensure main app is visible before navigation
          if (!isMainAppVisible) {
            setIsMainAppVisible(true);
            //call language translation file 
            // setTriggerShopify(true);
            //allow state update to complete before navigation
            setTimeout(() => { navigate(payload.path); }, 1);
          }
          else
            navigate(payload.path);
        });

        return () => {
          unsubscribe();
        }
      } catch (error) {
        console.error('Error initializing Shopify App Bridge:', error);
      }
    }
    //end subscribe 
  }, [navigate])

  return (
    <div>
      {
        !isMainAppVisible && localStorage.getItem('is_shopify_embed') ?
          <ShopifyEmbedGetStart handleGetStarted={handleGetStarted} />
          :
          <>
            <ThirdParty />
            <HeaderCard component={props.component} />
          </>
      }
    </div>
  );
}

export default EntryCard;