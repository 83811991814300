import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './Store';
import Spinner from './components/Spinner/Spinner';
import EntryCard from './components/Layout/EntryCard';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter } from 'react-router-dom';
import Toastify from './components/Util/Toastify';
import * as Sentry from '@sentry/react';
import en from "@shopify/polaris/locales/en.json";
import './App.css';
import ErrorBoundaryUI from './components/ErrorBoundaryUI/ErrorBoundaryUI';
import { onLCP, onFID, onINP, onCLS, } from 'web-vitals';
import { LanguageProvider } from './LanguageContext';
import ShopifyInitSkeleton from './components/Layout/ShopifyInitSkeleton';
import ThirdParty from './components/Layout/ThirdParty';

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: 'https://4c2fdfdae59dd3b47ab13f7f9f3fbc21@o992599.ingest.us.sentry.io/4507978256678912',
    tracesSampleRate: 1.0,
    beforeSend(event) {

      // Filter out specific events
      const errors = ['Cloudflare Turnstile', 'Unexpected token'];
      const targetError = event.exception?.values[0]?.value?.toString();

      return !errors.some(x => targetError.indexOf(x) !== -1) ? event : null;
    },

    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [window.location.origin],
      }),
      Sentry.browserTracingIntegration()
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  if (localStorage.getItem('e')) {
    Sentry.setUser({ email: localStorage.getItem('e') });
  }
}

let authToken = localStorage.getItem('authToken');


const App = (props) => {
  //#region start code for shopify app bridge
  const [shopifyInitLoading, setShopifyInitLoading] = React.useState(false);

  let shop, host, scopes, is_shopify_embed, custom_domain, fn, apiKey, token, id_token;
  //Extracting parameters from the URL query string
  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
  shop = queryParams.shop;
  host = queryParams.host;
  scopes = queryParams.scopes;
  is_shopify_embed = queryParams.is_embeded;
  custom_domain = queryParams.custom_domain;
  fn = queryParams.fn;
  apiKey = process.env.REACT_APP_SHOPIFY_KEY;
  token = queryParams.q;
  id_token = queryParams.id_token;

  if (window.location.pathname === '/shopifyAuth' && shop) {
    sessionStorage.setItem('shop_name', shop);
    localStorage.setItem('is_shopify_embed', true);
  }

  useEffect(() => {
    if (window.location.pathname === '/shopifyAuth') {
      if (!id_token) {
        //start showing the loading screen, when the shopify authentication is detected
        setShopifyInitLoading(true);
        fnForShopifyAuthCheck();
      }

      if (!authToken) {
        fnForShopifyAuthCheck();
      }
    }
  }, []);

  const fnForShopifyAuthCheck = async () => {
    const _path = process.env.REACT_APP_API_URL + "/shopify/oauth" + window.location.search;
    const response = await fetch(_path);
    const data = await response.json();

    if (data.status === 'success' && data.data) {
      if (data.idRedirect) {
        window.location.href = data.data;
        // return;
      }
      //Extracting parameters from the URL query string
      const queryParams = Object.fromEntries(new URLSearchParams(data.data.split('?')[1]));
      custom_domain = queryParams.custom_domain;
      fn = queryParams.fn;
      token = queryParams.q;

      if (fn) {
        localStorage.setItem('shp_un', fn);
      }

      if (custom_domain) {
        localStorage.setItem('shopify_custom_domain', custom_domain);
      }

      if (token) {
        localStorage.setItem('authToken', token);
        authToken = localStorage.getItem('authToken');
      }
    }
  }

  // Redirecting to Shopify OAuth authorization if scopes are provided
  if (scopes) {
    window.parent.location.href = 'https://' + shop + '/admin/oauth/authorize?client_id=' + process.env.REACT_APP_SHOPIFY_KEY + '&scope=' + scopes + '&redirect_uri=' + process.env.REACT_APP_API_URL + '/shopify/callbackOauth&&host=' + host + '&shop=' + shop;
  }
  else if (apiKey && shop) {
    const config = {
      apiKey: apiKey,
      shopOrigin: shop,
      host: host,
      forceRedirect: true,
    }
    //Store Shopify store authentication information in localStorage. We need to use this information to start listening for menu clicks in the sidebar page because we can't use the navigate function in the app.js file.
    localStorage.setItem('store_config', JSON.stringify(config))
  }

  if (fn || shop) {
    localStorage.setItem('shp_un', fn || shop.split('.')[0]);
  }

  // Checking if the app is rendered inside the Shopify admin
  if (is_shopify_embed) {
    localStorage.setItem('is_shopify_embed', true);
  }

  if (custom_domain || shop) {
    localStorage.setItem('shopify_custom_domain', custom_domain || shop);
  }

  if (token) {
    localStorage.setItem('authToken', token);
    authToken = localStorage.getItem('authToken');
  }

  if (!localStorage.getItem('pvd'))
    localStorage.setItem('pvd', 'shopify');
  //#endregion 

  const params = new URLSearchParams(window.location.search);
  //set user token to localstorage 
  let param_tkn = params.get('tkn');

  if (param_tkn) {
    localStorage.setItem('authToken', param_tkn);
    //update authtoken value
    authToken = localStorage.getItem('authToken');
  }

  if (window.Chargebee) {
    window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_KEY,
      enableGTMTracking: JSON.parse(process.env.REACT_APP_CHARGEBEE_ENABLE_GTM_TRACKING || 'false'),
      iframeOnly: true
    });
  }

  useEffect(() => {
    // Log web vitals metrics to the console
    onLCP(console.log); // Largest Contentful Paint
    onFID(console.log); // First Input Delay
    onCLS(console.log); // Cumulative Layout Shift
    onINP(console.log); // Interaction to Next Paint
  }, []);

  // Automatically update account across all tabs on logout (Auto-logout across all tabs when logged out in one.)
  useEffect(() => {
    const redirectAuth = () => {
      const intervalId = setInterval(() => {
        const currentAuthToken = localStorage.getItem('authToken');
        if (!currentAuthToken && authToken !== currentAuthToken) {
          if (!currentAuthToken) {
            window.location.href = '/login'; //on logout
          }
          else window.location.reload();
        }
      }, 5000);
      return () => clearInterval(intervalId);
    };
    redirectAuth();
  }, [authToken]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryUI />} showDialog>
      <LanguageProvider>
        <Provider store={store}>
          <AppProvider i18n={en}>
            <Spinner />
            <Toastify />
            <BrowserRouter>
              {
                shopifyInitLoading ?
                  <ShopifyInitSkeleton />
                  :
                  authToken || (localStorage.getItem('is_shopify_embed') === 'true') ?
                    <EntryCard component={<props.Component />} />
                    :
                    <>
                      <ThirdParty />
                      <props.Component />
                    </>
              }
            </BrowserRouter>
          </AppProvider>
        </Provider>
      </LanguageProvider>,
    </Sentry.ErrorBoundary>
  );
};

export default App;
