import React, { createContext, useContext, useState, useEffect } from 'react';
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Load initial language from localStorage or default to 'en'
  const [language, setLanguage] = useState(() => {
    return localStorage.getItem('language') || 'en';
  });

  // State to hold the translations for the current language
  const [translations, setTranslations] = useState({});

  // Setup for Shopify embed. We do not render the language file on the Shopify embed home page
  // const [triggerShopify, setTriggerShopify] = useState(false);

  // Function to dynamically load the selected language JSON
  useEffect(() => {
    const loadTranslations = async () => {
      try {
        //get the language json file
        // const response = await fetch(`http://localhost:3000/${language}.json`);
        const response = await fetch(process.env.REACT_APP_FRONTEND_URL + `/${language}.json`);
        if (!response.ok) {
          throw new Error(`Failed to fetch translations for ${language}`);
        }
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error(`Error loading translations for ${language}:`, error);
        setTranslations({});
      }
    };

    // Load translations only if the user is not on the Shopify auth page
    // if (!localStorage.getItem('is_shopify_embed') || ['/shopifyAuth', '/', 'shopifyAuthRedirect'].indexOf(window.location.pathname) === -1) {
    loadTranslations();
    // }

  }, [language]);//triggerShopify

  // Save language to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  //function for translating keys
  const translate = (key) => translations[key] || `Missing translation: ${key}`;

  return (
    // <LanguageContext.Provider value={{ language, setLanguage, translate, setTriggerShopify }}>
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
