import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import Routes from './Routes';

const rootEl = document.getElementById('root');

const root = createRoot(rootEl);

// window.fbAsyncInit = function () {
//   window.FB.init({
//     appId: process.env.REACT_APP_FB_APP_ID,
//     cookie: true,
//     xfbml: true,
//     version: process.env.REACT_APP_FACEBOOK_APP_VERSION ? process.env.REACT_APP_FACEBOOK_APP_VERSION : 'v5.0'
//   });
// };

// (function (d, s, id) {
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s); js.id = id;
//   js.src = 'https://connect.facebook.net/en_US/sdk.js';
//   fjs.parentNode.insertBefore(js, fjs);
// }(document, 'script', 'facebook-jssdk'));


//If a call from Shopify authentication is detected, we need to redirect to the backend for authentication
// if (window.location.pathname === '/shopifyAuth') {
//   root.render(
//     <App Component="shopifyInitLoading" />
//   );

//   const _path = process.env.REACT_APP_API_URL + "/shopify/oauth" + window.location.search;

//   //Retrieve the redirect URL using fetch instead of performing a backend redirect
//   const response = await fetch(_path, {
//     method: 'GET',
//     headers: {},
//   });
//   const data = await response.text();
//   window.location.href = data;

//   //window.location.href = _path;
// }
// else {
//   root.render(
//     <App Component={Routes} />
//   );
// }

root.render(
  <App Component={Routes} />
);


