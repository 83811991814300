import { IndexTable, SkeletonBodyText, Spinner } from '@shopify/polaris';
import React from 'react';

export const skeleton = (rowCount, columnCount) => {
  return Array.from({ length: rowCount }).map((_, index) => (
    <IndexTable.Row id={`skeleton-${index}`} key={`skeleton-${index}`} position={index}>
      {Array.from({ length: columnCount }).map((_, cellIndex) => (
        <IndexTable.Cell key={`skeleton-cell-${cellIndex}`}>
          <SkeletonBodyText lines={1} />
        </IndexTable.Cell>
      ))}
    </IndexTable.Row>
  ));
}

export const skeletonOnRouteChange = () => {
  return (
    <div className='loading-wrap'>
      <div className='spinner_loading_main_div'>
        <Spinner size="large" />
      </div>
    </div>
  );
}