import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const notifShopify = createSlice({
  name: 'notifShopify',
  initialState,
  reducers: {
    getNotifShopify: (state, action) => { },
    getNotifShopifySuccess: (state, action) => {
      return { ...state, notifShopify: action.payload };
    },
    getNotifByUser: (state, action) => { },
    getNotifByUserSuccess: (state, action) => {
      return { ...state, notifByUser: action.payload };
    },
    getNotifActivityByUser: (state, action) => { },
    getNotifActivityByUserSuccess: (state, action) => {
      return { ...state, notifActivityByUser: action.payload };
    },
    getUserNotifData: (state, action) => { },
    getUserNotifDataSuccess: (state, action) => {
      return { ...state, getUserNotifData: action.payload };
    },
    getShpNotifCount: (state, action) => { },
    getShpNotifCountSuccess: (state, action) => {
      return { ...state, getShpNotifCount: action.payload };
    },
    getUniqCron: (state, action) => { },
    createNotifShopify: (state, action) => { },
    createNotifShopifySuccess: (state, action) => { return { ...state, resCreateNotif: action.payload } },
    notifClear: (state, action) => { return { ...state, resCreateNotif: null } },
    updNotifStatus: (state, action) => { },
    updNotifStatusSuccess: (state, action) => {
      return { ...state, activeStatus: action.payload };
    },
    delNotification: (state, action) => { },
    createInlineNotif: (state, action) => { },
    deleteInlineNotif: (state, action) => { },
    getNotiHght: (state, action) => { },
    getNotiHghtSuccess: (state, action) => {
      return { ...state, notifHeight: action.payload };
    },
    createCloneNotif: (state, action) => { },
    smartDebugger: (state, action) => { },
    smartDebuggerSuccess: (state, action) => {
      return { ...state, smartDebuggerList: action.payload }
    },
    updNotifName: (state, action) => { },
    checkAppStatus: (state, action) => { }
  }
});

export const {
  getNotifShopify,
  getNotifShopifySuccess,
  getNotifByUser,
  getNotifByUserSuccess,
  getNotifActivityByUser,
  getNotifActivityByUserSuccess,
  getUserNotifData,
  getUserNotifDataSuccess,
  getShpNotifCount,
  getShpNotifCountSuccess,
  getUniqCron,
  createNotifShopify,
  createNotifShopifySuccess,
  notifClear,
  updNotifStatus,
  updNotifStatusSuccess,
  delNotification,
  createInlineNotif,
  deleteInlineNotif,
  getNotiHght,
  getNotiHghtSuccess,
  createCloneNotif,
  smartDebugger,
  smartDebuggerSuccess,
  updNotifName,
  checkAppStatus
} = notifShopify.actions;

export default notifShopify.reducer;
