import React, { useEffect } from 'react';

function ThirdParty(props) {

  useEffect(() => {
    const host = window.location.host;
    //'localhost:3000', 'localhost:3002', bcz: stop to load facebook,google,frodge tools in local
    const arr = ['app.wisernotify.com', 'wisernotify.azurewebsites.net', 'localhost:3000'];
    if (host && arr.includes(host)) {
      const scripts = [
        'https://apis.google.com/js/client.js',
        'https://storage.googleapis.com/pt.wisernotify.com/apexcharts.js',
        'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'
      ];

      scripts.forEach(src => {
        const script = document.createElement('script');
        script.src = src;
        script.defer = true;
        document.head.appendChild(script);
      });

      window.onloadTurnstileCallback = function () {
        if (document.getElementById('cloudflare-turnstile')) {
          window.turnstile.render('#cloudflare-turnstile', {
            sitekey: '0x4AAAAAAAR6g2LVrzvAVvO3',
            theme: "light",
            language: "auto",
            retry: "auto"
          });
          window.document.getElementById('cloudflare-skeleton').style.display = 'none';
        }
      };

      //eslint-disable-next-line
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');


      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.REACT_APP_FB_APP_ID,
          cookie: true,
          xfbml: true,
          version: process.env.REACT_APP_FACEBOOK_APP_VERSION ? process.env.REACT_APP_FACEBOOK_APP_VERSION : 'v5.0'
        });
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));

      window.fbq('init', '747329475670610');
      //if (!localStorage.getItem('authToken')) {
      window.fbq('track', 'PageView');

      //end facebook

      //Google Tag Manager
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-NK2N86L');
      //end Google Tag Manager


      //Global site tag (gtag.js) - Google Analytic
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { window.dataLayer.push(arguments); }
      window.gtag('js', new Date());
      window.gtag('config', 'UA-157582111-1');

      var scriptGoogle = document.createElement('script');
      scriptGoogle.src = "https://www.googletagmanager.com/gtag/js?id=UA-157582111-1";
      scriptGoogle.async = true;
      document.getElementsByTagName('head')[0].appendChild(scriptGoogle);

      setTimeout(() => {
        window.gtag('config', 'AW-663600856');
      }, 1000);
      //end Global site tag (gtag.js) - Google Analytic

      //titel tag
      document.getElementsByTagName('title')[0].innerHTML = 'New WiserNotify : Social proof Notification solution | Improve conversion rates upto 18% | Dashboard Panel';

      if (localStorage.getItem('isMaster') !== 'true') {
        //function for froged chat tool
        window.frogedSettings = { appId: "bq9zyk" }
        setTimeout(() => {
          (function () {
            var a = document, b = window;

            // Check if Froged function is not already defined
            if (typeof window.Froged !== "function") {
              // Initialize Froged
              window.Froged = function (a, b, c) {
                window.Froged.c(a, b, c);
              };
              window.Froged.q = [];
              window.Froged.c = function (a, b, c) {
                window.Froged.q.push([a, b, c]);
              };

              // Data to be passed to Froged
              var fg_data = { hooks: {} };

              // Function to dynamically load the Froged script
              var c = function () {
                var b = a.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src = "https://bq9zyk.sdk.froged.com"; // Froged SDK URL

                // Insert the script in the document
                var c = a.getElementsByTagName("script")[0];
                c.parentNode.insertBefore(b, c);
              };

              // Check if document is ready, and load the script
              if (a.readyState === "complete") {
                c();
              } else if (window.attachEvent) {
                b.attachEvent("onload", c);
              } else {
                b.addEventListener("load", c, false);
              }
            }

            // Check for user information in localStorage and set it in Froged
            if (localStorage.getItem('ti')) {
              window.Froged('set', {
                userId: localStorage.getItem('ti'),
                email: localStorage.getItem('e'),
                name: localStorage.getItem('username')
              });
            }
          })();

        });

        //function for set customer.io 
        window._cio = window._cio || [];
        (function () {
          var a, b, c; a = function (f) {
            return function () {
              window._cio.push([f].concat(Array.prototype.slice.call(arguments, 0)))
            }
          };
          b = ["load", "identify", "sidentify", "track", "page"];
          for (c = 0; c < b.length; c++) { window._cio[b[c]] = a(b[c]) };
          var t = document.createElement('script'), s = document.getElementsByTagName('script')[0];
          t.async = true;
          t.id = 'cio-tracker';
          t.setAttribute('data-site-id', '775dd4155b976fce05b8');
          t.setAttribute('data-auto-track-page', false);
          t.setAttribute('data-cross-site-support', true);
          t.setAttribute('data-enable-in-memory-storage', true);
          t.src = 'https://assets.customer.io/assets/track.js';
          s.parentNode.insertBefore(t, s);
        })();

        if (localStorage.getItem('ti') && window._cio) {
          window._cio.identify({
            id: localStorage.getItem('ti'),
            email: localStorage.getItem('e'),
            name: localStorage.getItem('username')
          });
        }
      }

      //linkdin traking 
      window._linkedin_partner_id = "5685297";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

      (function (l) {
        if (!l) {
          window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
          window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
    }

  }, []);
  return (
    <div>

    </div>
  );
}

export default ThirdParty;