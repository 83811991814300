import { Badge, BlockStack, Box, Button, Card, Divider, InlineStack, Layout, Page, Text, ButtonGroup, InlineGrid, Select, TextField, SkeletonDisplayText, SkeletonBodyText, Tooltip, Modal } from '@shopify/polaris';
import React from 'react';
import { checkCircle_icon, planTrue_icon } from '../../img';
import BillingSummery from '../PricingPlan/BillingSummery';
import { useSelector } from 'react-redux';
// import { BillingInvoice } from '../../containers';
import BillingInvoice from '../../containers/BillingInvoice/BillingInvoice';

function ShopifyPricingPlanCard(props) {
  const { state, changeNameValue, translate } = props;
  const isLoading = useSelector(state => state.loading.isLoading);

  const planDescList = [
    { icon: checkCircle_icon, label: translate('shopifyPlan').MoneyBack },
    { icon: checkCircle_icon, label: translate('shopifyPlan').CngPlanTime }
  ]

  const tabs = [
    { tabName: 'month', label: translate('shopifyPlan').PayMonthly },
    { tabName: 'year', label: translate('shopifyPlan').PayYearly },
  ];

  const proIncludeList = [
    { icon: planTrue_icon, label: translate('shopifyPlan').PremTemp, tooltip: translate('shopifyPlan').PremTempTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').UnlimitedNotif, tooltip: translate('shopifyPlan').UnlimitedNotifTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').BrndCtrl, tooltip: translate('shopifyPlan').BrndCtrlTooltip },
    {
      icon: planTrue_icon, label: translate('shopifyPlan').AbTestTrack, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('shopifyPlan').AbTestTrackTooltip}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('shopifyPlan').AbTestTrackTooltipSecond}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('shopifyPlan').CustomCss, tooltip: translate('shopifyPlan').CustomCssTooltip }
  ]

  const growthIncludeList = [
    { icon: planTrue_icon, label: translate('shopifyPlan').Inlinenotif, tooltip: translate('shopifyPlan').InlinenotifTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').DepAcManager, tooltip: translate('shopifyPlan').DepAcManagerTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').CustomDesign, tooltip: translate('shopifyPlan').CustomDesignTooltip }
  ]

  const basicIncludeList = [
    { icon: planTrue_icon, label: translate('shopifyPlan').LimitedTemp, tooltip: translate('shopifyPlan').LimitedTempTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').ActiveNotif, tooltip: translate('shopifyPlan').ActiveNotifTooltip },
    {
      icon: planTrue_icon, label: translate('shopifyPlan').UrgencyWidgets, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('shopifyPlan').UrgencyWidgetsSubTxt}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('shopifyPlan').UrgencyWidgetsSubTxtSecond}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('shopifyPlan').UrgencyWidgetsSubTxtThird}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('shopifyPlan').UrgencyWidgetsSubTxtFourth}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text>{translate('shopifyPlan').UrgencyWidgetsSubTxtFifth}</Text>
          </div>
        </BlockStack>
    },
    { icon: planTrue_icon, label: translate('shopifyPlan').MultipleWebsite, tooltip: translate('shopifyPlan').MultipleWebsitesTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').Integrations, tooltip: translate('shopifyPlan').IntegrationsTooltip },
    { icon: planTrue_icon, label: translate('shopifyPlan').LiveSupport, tooltip: translate('shopifyPlan').LiveSupportTooltip },
    {
      icon: planTrue_icon, label: translate('shopifyPlan').ReportSetting, tooltip:
        <BlockStack>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('shopifyPlan').ReportSettingSubTxt}</Text>
          </div>
          <div className='tooltipBulletin'>
            <Text variant="bodyMd">&#8226;</Text>
            <Text variant="bodyMd">{translate('shopifyPlan').ReportSettingSubTxtSecond}</Text>
          </div>
        </BlockStack>
    }
  ]

  return (
    <div className='page-wrap page-plan-wrap'>
      <Box paddingBlockEnd={500}>
        <Page title={translate('shopifyPlan').Plan}>
          <Card>
            <BlockStack gap={300}>
              <Text variant='bodyLg' as='h6' fontWeight='semibold'>{translate('shopifyPlan').SetPlanTraffic}</Text>
              <Box>
                <InlineStack gap={200} wrap={false} blockAlign='center'>
                  {planDescList && planDescList.map((data, index) => (
                    <InlineStack key={index} gap={100} blockAlign='center'>
                      <img src={data.icon} alt='' />
                      <Text as='p' fontWeight='regular'>{data.label}</Text>
                    </InlineStack>
                  ))}
                </InlineStack>
              </Box>
            </BlockStack>

            <div id='plan_block'>
              <Box paddingBlockEnd={500}>
                <Layout>
                  <Layout.Section variant='oneThird'>
                    <Box id='shp_pln_basic_block' padding={400} borderRadius={300}>
                      <BlockStack gap={300}>
                        <Box>
                          <Badge tone="warning">{translate('shopifyPlan').FreePlan}</Badge>
                        </Box>
                        <Text variant='bodySm' as='p' fontWeight='regular'>{translate('shopifyPlan').FreePlanSubTxt}</Text>
                        <Divider borderWidth='050' />
                        {isLoading ?
                          <Box>
                            <Box width='30%' paddingBlockStart={300}>
                              <SkeletonBodyText lines={1} />
                            </Box>
                            <Box paddingBlock={400}>
                              <SkeletonDisplayText size='medium' maxWidth='100%' />
                            </Box>
                          </Box> :
                          <>
                            <Box>
                              <Text variant='headingLg' as='h3' fontWeight='semibold'>$0</Text>
                            </Box>
                            <TextField
                              value={translate('shopifyPlan').VisitorsMonth}
                              autoComplete="off"
                              disabled
                            />
                          </>}
                        <Box paddingBlockStart={400}>
                          <Button id='primary_btn' fullWidth disabled>
                            <Text variant='bodyMd' fontWeight='semibold'>{translate('shopifyPlan').FreePlan} {state.profile && state.profile.csub === 'free' ? translate('shopifyPlan').Activated : ''}</Text>
                          </Button>
                        </Box>
                        <Box>
                          <BlockStack gap={100}>
                            <Text variant='bodyMd' fontWeight='semibold'>
                              {translate('shopifyPlan').Includes}
                            </Text>
                            {basicIncludeList && basicIncludeList.map((data, index) => (
                              <InlineStack key={index} gap={100} blockAlign='center'>
                                <img src={data.icon} alt='' />
                                <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                  <Text variant='headingSm' as='p' fontWeight='regular' tone='subdued'>{data.label}</Text>
                                </Tooltip>
                              </InlineStack>
                            ))}
                          </BlockStack>
                        </Box>
                      </BlockStack>
                    </Box>
                  </Layout.Section>
                  <Layout.Section>
                    <Box background='bg-surface-tertiary' id='plan_pg_block' borderRadius={300} paddingInline={800} paddingBlockEnd={500} paddingBlockStart={500}>
                      <InlineStack blockAlign='center' align='center'>
                        <Box background='bg-surface' borderRadius='300' padding={100} borderColor="border" borderWidth="025">
                          <ButtonGroup>
                            {tabs.map((data, index) => (
                              <Button key={index} variant={state.plv === data.tabName ? 'primary' : ''}
                                onClick={() => props.handleTabChange(data)}>
                                <Text variant='bodyLg' as='p' fontWeight='regular'>{data.label}</Text>
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      </InlineStack>
                      <Box paddingBlockStart={400}>
                        <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400}>
                          <Box background='bg-surface' padding={400} borderRadius='300' minHeight='429.99px'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="info">Pro</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>{translate('shopifyPlan').IdelForD2C}</Text>
                              <Divider />

                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                :
                                <>< Box >
                                  <InlineStack gap={100} blockAlign='center'>
                                    <Text variant='headingLg' as='p' fontWeight='semibold'>${state.selctdProPlanPrice ? state.selctdProPlanPrice : '-'}</Text>
                                    <Text variant='headingXs' as='p' fontWeight='regular'>{`per ${state.plv}`}</Text>
                                    {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) && (
                                      <Badge tone="success">{translate('shopifyPlan').Selected}</Badge>
                                    )}
                                  </InlineStack>
                                </Box>

                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.proOptionList}
                                      onChange={(e) => changeNameValue({ selctdProPlanVisitor: parseInt(e) })}
                                      value={state.selctdProPlanVisitor}
                                    />
                                  </Box>
                                </>}

                              {state?.selctdPlanId && state.proPlanList.find(plan => plan.uaq === state.selctdProPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) ?
                                <Button id='primary_btn' fullWidth disabled>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('shopifyPlan').CurrentActivePlan}</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleShopifyPayment(state.selctdProPlanPrice)} disabled={state.selctdProPlanPrice <= state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      (state.plv === 'year') ? translate('shopifyPlan').UpgradeYearly : translate('shopifyPlan').UpgradeMonthly : translate('shopifyPlan').GetStartedNow
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('shopifyPlan').EverythingInBasic}</Text>
                                </Box>
                                <BlockStack gap={100}>

                                  {proIncludeList && proIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center' wrap={false}>
                                      <img src={data.icon} alt='' />
                                      <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}

                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>

                          <Box background='bg-surface' padding={400} borderRadius='300'>
                            <BlockStack gap={300}>
                              <Box>
                                <Badge tone="attention">{translate('shopifyPlan').Growth}</Badge>
                              </Box>
                              <Text variant='bodySm' as='p' fontWeight='regular'>{translate('shopifyPlan').GrowthSubTxt}</Text>
                              <Divider />
                              {isLoading ?
                                <Box>
                                  <Box width='30%' paddingBlockStart={300}>
                                    <SkeletonBodyText lines={1} />
                                  </Box>
                                  <Box paddingBlock={400}>
                                    <SkeletonDisplayText size='medium' maxWidth='100%' />
                                  </Box>
                                </Box>
                                : <>
                                  <Box>
                                    <InlineStack gap={100} blockAlign='center' >
                                      <Text variant='headingLg' as='h3'>
                                        ${state.selctdGrowthPlanPrice ? state.selctdGrowthPlanPrice : '-'}
                                      </Text>
                                      <Text variant='bodySm' as='p' >{`per ${state.plv}`}</Text>

                                      {state?.selctdPlanId && state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) && (
                                        <Badge tone="success">{translate('shopifyPlan').Selected}</Badge>
                                      )}

                                    </InlineStack>
                                  </Box>
                                  <Box minHeight='50px'>
                                    <Select
                                      options={state.growthOptionList}
                                      onChange={(e) => changeNameValue({ selctdGrowthPlanVisitor: parseInt(e) })}
                                      value={state.selctdGrowthPlanVisitor}
                                    />
                                  </Box>
                                </>}

                              {state?.selctdPlanId && state.growthPlanList.find(plan => plan.uaq === state.selctdGrowthPlanVisitor && plan._id === state?.selctdPlanId && plan.pt === state.plv) ?
                                <Button id='primary_btn' fullWidth disabled>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>{translate('shopifyPlan').CurrentActivePlan}</Text>
                                </Button>
                                :
                                <Button id='primary_btn' fullWidth onClick={() => props.handleShopifyPayment(state.selctdGrowthPlanPrice)} disabled={state.selctdGrowthPlanPrice <= state.selctdPlanPrice ? true : false}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold' >
                                    {(state.profile && state.profile.billing && state.profile.billing.icnc !== true) ?
                                      (state.plv === 'year') ? translate('shopifyPlan').UpgradeYearly : translate('shopifyPlan').UpgradeMonthly : translate('shopifyPlan').GetStartedNow
                                    }
                                  </Text>
                                </Button>
                              }

                              <Box>
                                <Box paddingBlockEnd={100}>
                                  <Text variant='bodyMd' as='p' fontWeight='semibold'>
                                    {translate('shopifyPlan').EverythingInPro}
                                  </Text>
                                </Box>
                                <BlockStack gap={100}>
                                  {growthIncludeList && growthIncludeList.map((data, index) => (
                                    <InlineStack key={index} gap={100} blockAlign='center' wrap={false} >
                                      <img src={data.icon} alt='' />
                                      <Tooltip dismissOnMouseOut padding='400' content={data.tooltip} hasUnderline width='wide'>
                                        <Text variant='headingSm' as='p' fontWeight='regular'>{data.label}</Text>
                                      </Tooltip>
                                    </InlineStack>
                                  ))}
                                </BlockStack>
                              </Box>
                            </BlockStack>
                          </Box>
                        </InlineGrid>
                      </Box>
                    </Box>
                  </Layout.Section>
                </Layout>
              </Box>
              <Card>
                <InlineStack align='space-between' blockAlign='center'>
                  <BlockStack gap={100}>
                    <Text variant='bodySm' as='h6' fontWeight='semibold'>{translate('shopifyPlan').HaveMoreVisitor}</Text>
                    <Text tone="subdued" variant='bodySm' as='p' fontWeight='regular'>{translate('shopifyPlan').HaveMoreVisitorSubTxt} </Text>
                  </BlockStack>
                  <Button>{translate('shopifyPlan').ChatWithUs}</Button>
                </InlineStack>
              </Card>
            </div>

          </Card >
          {
            state.profile && state.profile.billing && state.profile.billing.icnc === false &&
            <Box paddingBlock={700}>
              <Box paddingBlockEnd={'200'}>
                <InlineStack align='space-between'>
                  <Text as='p' variant='bodyLg' fontWeight='semibold'>{translate('shopifyPlan').ActivePlan}</Text>
                  <Button variant="plain" onClick={() => changeNameValue({ billingInvoiceOpen: true })}>{translate('shopifyPlan').BillingHistory}</Button>
                </InlineStack>
              </Box>
              <BillingSummery props={props} />
            </Box>
          }
        </Page >
      </Box >

      {/* open billing Invoice modal */}
      <Modal
        size='large'
        open={state.billingInvoiceOpen}
        onClose={() => changeNameValue({ billingInvoiceOpen: false })}
      >
        <div className='full-screen-open'>
          <Box minHeight='90vh' id='add_inte_wrap' paddingInlineEnd={300}>
            <BillingInvoice />
          </Box>
        </div>
      </Modal>
    </div >

  );
}

export default ShopifyPricingPlanCard;
