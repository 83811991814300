import React, { useState, useCallback, useEffect, } from "react";
import BillingInvoiceCard from "../../components/BillingInvoice/BillingInvoiceCard";
import "./BillingInvoice.css";
import { useDispatch, useSelector } from "react-redux";
import * as billing from "../../ducks/billingInvoice";
import * as authDucks from '../../ducks/auth';
import { useLanguage } from '../../LanguageContext';

const BillingInvoice = () => {
  const dispatch = useDispatch();
  const { translate } = useLanguage();

  const [state, setState] = useState({
    billingInvoice: [],
    shopifyBill: [],
    isOpenChargebeePymFrm: false,
    openBilling: false,//open update billing modal
    e: '',
    fn: '',
    ln: '',
    country: '',
    company: '',
    city: '',
    zip: '',
    flagUpdateBillingModel: false//handle auto open update billing modal
  });

  //set data in state
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }));
  }, []);

  //get data from store
  const billingInvoice = useSelector(state => state.billingInvoice?.billingHistory);
  const chargebeePay = useSelector(state => state.billingInvoice?.mangePaymentSrc);
  const shopifyBill = useSelector(state => state.billingInvoice?.shopifyBill);
  const profile = useSelector(state => state.auth.profile);
  let isLoading = useSelector(state => state?.loading?.isLoading);
  const getBilling = useSelector(state => state.billingInvoice?.billingInfo);

  //api call on page load
  useEffect(() => {
    if (localStorage.getItem('pvd') !== 'shopify') {
      dispatch(billing.getBillingInvoice());
      // dispatch(billing.getUserCard());
    }
    else {
      dispatch(billing.getShopifyBill());
    }
    dispatch(authDucks.getProfile());
  }, [])

  //set data in state
  useEffect(() => {
    changeNameValue({ billingInvoice: billingInvoice, shopifyBill: shopifyBill });
  }, [billingInvoice, shopifyBill])

  //manage payment source
  const mangePaymentSrc = () => {
    dispatch(billing.mangePaymentSrc());
    changeNameValue({ isOpenChargebeePymFrm: true })
  }

  //download invoice
  const downloadInvoice = (id) => {
    let obj = {
      iv: id
    };
    dispatch(billing.downloadInvoice(obj));
  }

  useEffect(() => {
    if (chargebeePay && state.isOpenChargebeePymFrm === true) {
      if (chargebeePay) {
        var chargebeeInstance = window.Chargebee.getInstance();
        chargebeeInstance.openCheckout({
          hostedPage: function () {
            return new Promise(function (resolve) {
              var sampleResponse = chargebeePay.hosted_page;
              resolve(sampleResponse);
            });
          },
        });
        changeNameValue({ isOpenChargebeePymFrm: false });
      }
    }
  }, [chargebeePay])

  //open close modal
  const openCloseModal = useCallback((name, value, type) => {
    if (type !== undefined) {
      changeNameValue({ [name]: !value });
    }
  }, []);

  //update billing modal
  const handleUpdateBilling = () => {
    let obj = {
      cbe: profile.cbe?.client_id,
    };
    dispatch(billing.getBillingInfo(obj));
    changeNameValue({ flagUpdateBillingModel: true });
  }

  //set state when data is come
  useEffect(() => {
    if (getBilling && state.flagUpdateBillingModel) {
      changeNameValue({
        e: getBilling?.email,
        fn: getBilling?.first_name,
        ln: getBilling?.last_name,
        country: getBilling?.country,
        company: getBilling?.company,
        city: getBilling?.city,
        zip: getBilling?.zip,
      })
      openCloseModal('openBilling', state.openBilling, 'open');
    }
    // return () => {
    //   changeNameValue({ flagUpdateBillingModel: false });
    // }
  }, [getBilling])

  //on update button click in modal
  const handleUpdate = () => {
    let obj = {
      cbe: profile.cbe?.client_id,
      first_name: state.fn,
      last_name: state.ln,
      country: state.country,
      company: state.company,
      city: state.city,
      zip: state.zip,
    };
    dispatch(billing.updCbeCustomer(obj));
    changeNameValue({ fn: '', ln: '', country: '', company: '', city: '', zip: '', });
    openCloseModal('openBilling', state.openBilling, 'close');
  }

  return (
    <BillingInvoiceCard
      translate={translate}
      state={state}
      changeNameValue={changeNameValue}
      mangePaymentSrc={mangePaymentSrc}
      downloadInvoice={downloadInvoice}
      profile={profile}
      isLoading={isLoading}
      handleUpdateBilling={handleUpdateBilling}//open update billing modal
      openCloseModal={openCloseModal}
      handleUpdate={handleUpdate}//on update button click in modal
    />
  );
};

export default BillingInvoice;
