import { put, fork, takeLatest, call } from 'redux-saga/effects';
import * as api from '../services/api';
import * as apiIntegrate from '../services/apiIntegrate';
import { load, loaded } from '../ducks/loading';
import * as actions from '../ducks/integration';
import { toastify } from '../ducks/toast';
import { isWhitelable } from '../services/permissions';
let isWhite = isWhitelable();

function* getIntegration(action) {
  try {

    const res = yield call(api.GET, '/getIntegration', action.payload);

    if (res.status === 'success') {
      yield put(actions.getIntegrationSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }

  } catch (error) {

    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getIntMstList(action) {
  try {
    const res = yield call(api.GET, '/getIntMstList?sa=' + action.payload.sa + '&ty=' + action.payload.ty + '&iswht=' +
      !isWhite + '&istm=' + localStorage.getItem('istm') + '&pvd=' + localStorage.getItem('pvd') +
      '&isbusr=' + localStorage.getItem('isbusr'));
    if (res.status === 'success') {
      yield put(actions.getIntMstListSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  } catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.====' }));
  }
}

//SendInBlue
function* getSendInBlue() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/sendinblue/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSendInBlue(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendinblue/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSendInBlue(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/sendinblue/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Convertkit
function* getConvertkit() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/convertkit/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createConvertkit(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/convertkit/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteConvertkit(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/convertkit/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Google
function* getGoogle() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/google/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGoogle(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/google/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGoogle(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/google/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Lemlist
function* getLemlist() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/lemlist/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createLemlist(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/lemlist/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteLemlist(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/lemlist/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Aweber
function* getAweber() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/aweber/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAweber(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/aweber/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAweberList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/aweber/addList', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/aweber/deleteList?_id=' + action.payload._id + '&ld=' + action.payload.ld);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAweber(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/aweber/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncAweberList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/aweber/syncList?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//Mailchimp
function* getMailchimp() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/mailchimp/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createMailchimp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/mailchimp/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createMailchimpWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/mailchimp/createWebhook', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteMCList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/mailchimp/deleteList?_id=' + action.payload._id + '&ld=' + action.payload.id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteMC(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/mailchimp/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Facebook
function* createFBAC(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/facebook/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getFBAC() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/facebook/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createFBPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/facebook/createPage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/facebook/deleteList?_id=' + action.payload._id + '&id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/facebook/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//BigCommerce
function* getBigCommerce() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/bigcommerce/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createBigCommerce(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/bigcommerce/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteBigCommerce(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/bigcommerce/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updBigcmWebhook(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/bigcommerce/updBigcmWebhook', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//GMB
function* getGMB() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/gmb/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGMB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/gmb/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'exist') {
      yield put(toastify({ type: 'info', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGMBPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/gmb/createPage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/gmb/deletePage', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGMB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/gmb/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncGooglePage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/gmb/syncGooglePage?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Zapier
function* getZap() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/zapier/getZap');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createZap(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/zapier/createZap', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteZap(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/zapier/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Neto
function* getNeto() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/neto/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createNeto(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/neto/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteNeto(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/neto/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Calendly
function* getCalendly() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/calendly/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCalendly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/calendly/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCalendly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/calendly/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


function* getPl(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/pl/get?fa=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePl(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/pl/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Pipedrive
function* getPipedrive() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/pipedrive/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createPipedrive(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/pipedrive/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePipedrive(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/pipedrive/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// Kit
function* getKit() {
  try {
    yield put(load());    
    const res = yield call(api.GET, '/kit/getKit');    
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//delete
function* deleteKit(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/kit/deleteKit?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// create kitform list
function* createKitForm(action) {
  console.log("action ", action);
  
  try {
    yield put(load());
    const res = yield call(api.POST, '/kit/createKitForm', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFormList(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/kit/deleteFormList?_id=' + action.payload._id + '&id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//Shopify
//get shopify integration
function* getShopify() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/shopify/getInt');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//add pixel code
function* addPixelTag(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/shopify/addPixelTag', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//remove pixel code
function* deletePixel(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/shopify/deletePixel?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Shopaccino
//get Shopaccino integration
function* getShopaccino() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, `/shopaccino/get`);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//create Shopaccino code
function* createShopaccino(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/shopaccino/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//remove Shopaccino code
function* deleteShopaccino(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/shopaccino/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Shopline
//get shopline integration
function* getShopline() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/shopline/getInt');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//add pixel code shopline
function* addPixelTagShopline(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/shopline/addPixelTag', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//remove pixel code shopline
function* deletePixelShopline(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/shopline/deletePixel?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//MailerLite
function* getMailerLite() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/mailerlite/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createMailerLite(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/mailerlite/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteMailerLite(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/mailerlite/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Thrivecart
function* getThrivecart() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/thrivecart/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createThrivecart(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/thrivecart/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createThrivecartWB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/thrivecart/addWB', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteThrivecartWB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/thrivecart/deleteWB?_id=' + action.payload._id + '&ev=' + action.payload.ev);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteThrivecart(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/thrivecart/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Webflow
function* getWebflow() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/webflow/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWebflow(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/webflow/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWebflow(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/webflow/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Cliengo
function* getCliengo() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/cliengo/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCliengo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/cliengo/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCliengo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/cliengo/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Autopilot
function* getAutopilot() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/autopilot/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAutopilot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/autopilot/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAutopilot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/autopilot/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Eventbrite
function* getEventbrite() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/eventbrite/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEventbrite(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/eventbrite/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Braintree
function* getBraintree() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/braintree/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createBraintree(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/braintree/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteBraintree(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/braintree/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Scheduleonce
function* getScheduleonce() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/scheduleonce/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createScheduleonce(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/scheduleonce/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteScheduleonce(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/scheduleonce/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Stripe
function* getStripe() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/stripe/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createStripe(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/stripe/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteStripe(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/stripe/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//WordpressReview
function* getWordpressReview() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/wp/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWordpressReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/wp/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWordpressReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/wp/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//AcuityScheduling
function* getAcuityScheduling() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/acuityscheduling/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAcuityScheduling(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/acuityscheduling/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAcuityScheduling(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/acuityscheduling/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//SendOwl
function* getSendOwl() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/sendowl/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSendOwl(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendowl/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
      if (res.url) { window.location.href = res.url; }
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSendOwl(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/sendowl/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//CampaignMonitor
function* getCampaignMonitor() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/campaignmonitor/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCampaignMonitor(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/campaignmonitor/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
      if (res.url) { window.location.href = res.url; }
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCampaignMonitor(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/campaignmonitor/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Bonusly
function* getBonusly() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/bonusly/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createBonusly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/bonusly/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
      if (res.url) { window.location.href = res.url; }
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteBonusly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/bonusly/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//HubSpot
function* getHubSpot() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/hubspot/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createHubSpot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/hubspot/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      window.location.href = process.env.REACT_APP_HUBSPOT_URL;
    }
    else if (res.status === 'fail') {
      if (res.url) window.location.href = process.env.REACT_APP_HUBSPOT_URL;
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteHubSpot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/hubspot/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Twitter
function* getTwitter() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/twitter/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTwitter(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/twitter/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getTwitter());
      yield put(actions.intSuccess(res.data));
    }
    if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }

    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTwitter(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/twitter/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getTwitter());
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//youtube channel
function* getYtChannel() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/youtubeChannel/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createYtChannel(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/youtubeChannel/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getYtChannel());
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteYtChannel(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/youtubeChannel/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getYtChannel());
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

///youtube video
function* getYtVideo() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/youtubeVideo/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getPlayList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/youtubeVideo/getPlayList?ytps=' + action.payload);
    if (res.status === 'success') {

      yield put(actions.getPlayListSuccess(res.data));
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createVideo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/youtubeVideo/createVideo', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(actions.getYtVideo());
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteYtVideo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/youtubeVideo/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getYtVideo());
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteYtRecord(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/youtubeVideo/deleteRecord?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.getYtVideo());
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* checkVideo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/youtubeVideo/checkVideo?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.checkVideoSuccess(res.data));
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Capterra
function* getCapterra() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/capterra/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//createCapterra
function* createCapterra(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/capterra/createCapterra', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//deleteCapterra
function* deleteCapterra(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/capterra/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Integromat
function* getIntegromat() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/integromat/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createIntegromat(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/integromat/createCon', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteIntegromat(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/integromat/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//twitter follower
function* getTwitFoll() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/twitter/getTwitFoll');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTwitFoll(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/twitter/createTwitFoll', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFollower(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/twitter/deleteFollower?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//instagram follower
function* getInstaFoll() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/instagram/getInstaFoll');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createInstaFoll(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/instagram/createInstaFoll', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInstaFollower(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/instagram/deleteInstaFollower?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* linkInstaPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/instagram/createPage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInstaList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/instagram/deletePage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

// //createLinkedin
// function* createLinkedin(action) {
//   try {
//     yield put(load());
//     const res = yield call(apiIntegrate.POST, '/linkedin/createLinkedin', action.payload);
//     if (res.status === 'success') {
//       yield put(actions.intSuccess(res.data));
//       yield put(actions.intSuccess(res.data));
//     }
//     else if (res.status === 'not_Found') {
//       yield put(toastify({ type: 'error', msg: res.m }));
//      }
//     else if (res.status === 'fail') { 
//       yield put(toastify({ type: 'error', msg: res.m }));
//     }
//     yield put(loaded());
//   }
//   catch (e) {
//     yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
//   }
// }

function* getLinkedin() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/linkedin/getLinkedin');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteLinkedin(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/linkedin/deleteLinkedin?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* linkLinkedinPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/linkedin/linkLinkedinPage', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteLinkList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/linkedin/deleteLinkList?_id=' + action.payload._id + '&id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Thinkific
function* getThinkific() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/thinkific/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'not_found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createThinkific(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/thinkific/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'not_Found') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteThinkific(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/thinkific/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Facebook
function* createFBLAC(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/fblead/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getFBLAC() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/fblead/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncLeadForms(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/fblead/syncLeadForms?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createFBLPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/fblead/createPage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBLList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/fblead/deleteList?_id=' + action.payload._id + '&id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBL(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/fblead/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//CustomWebhook
function* getCustomWebhook() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/custom/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustomWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/custom/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCustomWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/custom/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getCustomWebhookField(action) {
  try {
    // yield put(load());
    const res = yield call(apiIntegrate.GET, '/custom/getField?fuid=' + action.payload.usind);
    if (res.status === 'success') {
      yield put(actions.successCustomWebhookField(res.data));
      yield put(actions.intSuccess(res.uint));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustomWebhookFieldUint(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/custom/createField', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      //yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delCustomWebhookFieldUint(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/custom/delField', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* activeCustFld(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/custom/activeCustFld', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updCustField(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/custom/updateCustField', action.payload);
    if (res.status === 'success') {
      yield put(actions.successCustomWebhookField(res.data));
      yield put(actions.intSuccess(res.uint));
      //yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updIntIsActive(action) {
  try {
    //yield put(load());
    const res = yield call(api.POST, '/updIntIsActive', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      //yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//getNotifForInt
function* getNotifForInt() {
  try {
    // yield put(load());
    const res = yield call(api.GET, '/getNotifForInt');
    if (res.status === 'success') {
      yield put(actions.notlistSuccess(res.data));
    }
    // yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//custom webform
function* getCustomWebform() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/customwebform/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCustomWebform(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/customwebform/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCustomWebform(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/customwebform/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getWBFormField(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.GET, '/customwebform/getField?fuid=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.uint));
      yield put(actions.successCustomWBFormField(res.cwff));
    }
    // yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updCWFFld(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/customwebform/updateCustField', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.uint));
      yield put(actions.successCustomWBFormField(res.cwff));
      //yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCWFKy(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/customwebform/createField', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      //yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* delCWFKy(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.POST, '/customwebform/delField', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Intercom
function* getIntercom() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/intercom/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createIntercom(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/intercom/create', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteIntercom(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/intercom/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Sendfox
function* getSendfox() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/sendfox/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSendfox(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendfox/create', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addSendfoxList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendfox/addSendfoxList', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSendfoxList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendfox/deleteSendfoxList', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSendfox(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/sendfox/deleteSendfox', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

////automizy
function* getAutomizy() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/automizy/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAutomizy(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/automizy/create', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* addAutomizyList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/automizy/addAutomizyList', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAutomizyList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/automizy/deleteAutomizyList', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAutomizy(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/automizy/deleteAutomizy', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

///Yelp
function* getYelp() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/yelp/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createYelp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/yelp/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteYelp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/yelp/deleteYelp', action.payload);
    if (res.status === 'success') {
      if (res.url) { window.location.href = res.url; }
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Livewebinar
function* getLivewebinar() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/livewebinar/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createLivewebinar(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/livewebinar/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteLivewebinar(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/livewebinar/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Amember
function* getAmember() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/amember/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAmember(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/amember/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAmember(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/amember/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

////newZenler
function* getNewzenler() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/newzenler/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createNewzenler(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/newzenler/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteNewzenler(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/newzenler/delete?_id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//////yopto
function* getYotpo() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/yotpo/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createYotpo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/yotpo/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteYotpo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/yotpo/delete?_id=' + action.payload.id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Repuso
function* getRepuso() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/repuso/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createRepuso(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/repuso/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteRepuso(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/repuso/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Heysummit
function* getHeysummit() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/heysummit/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createHeysummit(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/heysummit/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createHeySummitWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/heysummit/createwebhook', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteHeysummit(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/heysummit/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncHeysummitEvent(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/heysummit/syncevent?ak=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.hsSuccessEventData(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//edd
function* getEdd() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/edd/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEdd(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/edd/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Elitefunnels
function* getElitefunnels() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/elitefunnels/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createElitefunnels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/elitefunnels/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteElitefunnels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/elitefunnels/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Fastfunnels
function* getFastfunnels() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/fastfunnels/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createFastfunnels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/fastfunnels/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFastfunnels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/fastfunnels/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Getdrip
function* getGetdrip() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/getdrip/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGetdrip(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/getdrip/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGetdrip(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/getdrip/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* getSpotify() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/spotify/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSpotify(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/spotify/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSpotify(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/spotify/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAlTR(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/spotify/createAlTR', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteARAL(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/spotify/deleteARAL?_id=' + action.payload._id + '&id=' + action.payload.id + '&typ=' + action.payload.typ);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//OpenCart
function* getOpenCart() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/opencart/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteOpenCart(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/opencart/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Surveymonkey
function* getSurveymonkey() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/surveymonkey/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSMWB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/surveymonkey/createSMWB', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSMWB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/surveymonkey/deleteSMWB?id=' + action.payload.id + '&_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSurveyMonkey(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/surveymonkey/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//TwitchTV
function* getTwitchTV() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/twitchtv/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTwitchTV(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/twitchtv/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTwitchTV(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/twitchtv/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//import review
function* getImportreview() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/importreview/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createImportReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POSTFILE, '/importreview/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteImportReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/importreview/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Prestashop
function* getPrestashop() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/prestashop/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePrestashop(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/prestashop/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//wishpond
function* getWishpond() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/wishpond/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWishpond(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/wishpond/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWishpond(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/wishpond/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Zoomai
function* getZoomai() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/zoomai/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createZoomai(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/zoomai/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteZoomai(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/zoomai/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Booking
function* getBooking() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/booking/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createBooking(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/booking/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteBooking(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/booking/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Trustpilot
function* getTrustpilot() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/trustpilot/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTrustpilot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/trustpilot/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTrustpilot(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/trustpilot/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//tripadvisor
function* getTRPA() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/tripadvisor/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTRPA(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/tripadvisor/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTRPA(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/tripadvisor/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Ontraport
function* getOntraport() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/ontraport/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createOntraport(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/ontraport/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteOntraport(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/ontraport/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//stamped review
function* getStamped() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/stamped/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createStamped(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/stamped/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteStamped(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/stamped/delete?id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Clixli
function* getClixli() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/clixli/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createClixli(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/clixli/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteClixli(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/clixli/delete?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Airbnb
function* getAirbnb() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/airbnb/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAirbnb(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/airbnb/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAirbnb(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/airbnb/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//wiserreview
function* getWiserReview() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/wiserreview/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWiserReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/wiserreview/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWiserReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/wiserreview/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Wix
function* getWix() {
  try {
    yield put(load());
    const res = yield call(api.GET, '/wix/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWix(action) {
  try {
    yield put(load());
    const res = yield call(api.POST, '/wix/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWix(action) {
  try {
    yield put(load());
    const res = yield call(api.DELETE, '/wix/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Reviewio
function* getReviewio() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/reviewsio/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createReviewio(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/reviewsio/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteReviewio(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/reviewsio/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//Simvoly
function* getSimvoly() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/simvoly/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createSimvoly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/simvoly/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//delete
function* deleteSimvoly(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/simvoly/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Modwebs
function* getModwebs() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/modwebs/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createModwebs(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/modwebs/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteModwebs(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/modwebs/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//AppSumo
function* getAppSumo() {
  try {
    const res = yield call(apiIntegrate.GET, '/appsumo/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
  }
  catch (error) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAppSumo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/appsumo/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAppSumo(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/appsumo/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Practo
function* getPracto() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/practo/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createPracto(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/practo/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletePracto(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/practo/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//GooglePlay
function* getGooglePlay() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/googleplay/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createGooglePlay(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/googleplay/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteGooglePlay(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/googleplay/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Ecwid
function* getEcwid() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/ecwid/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEcwid(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/ecwid/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Facebook group
function* getFBGrp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/facebookgroupfollowers/get', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createFBGrp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/facebookgroupfollowers/createFBGrp', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));;
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBGrp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/facebookgroupfollowers/deleteFBGrp', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteFBGrpAcc(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/facebookgroupfollowers/deleteFBGrpAcc?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* syncGroups(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.GET, '/facebookgroupfollowers/syncGroups?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));;
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//Encharge
function* getEncharge() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/encharge/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createEncharge(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/encharge/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEncharge(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/encharge/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//3dCart
function* get3dCart() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/3dcart/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Amazon
function* getAmazon() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/amazon/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAmazon(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/amazon/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAmazon(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/amazon/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//DealerRater
function* getDealerRater() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/dealerrater/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createDealerRater(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/dealerrater/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteDealerRater(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/dealerrater/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//AppleApp
function* getAppleApp() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/appleapp/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createAppleApp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/appleapp/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteAppleApp(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/appleapp/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//BBB
function* getBBB() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/bbb/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createBBB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/bbb/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteBBB(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/bbb/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//eBay
function* geteBay() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/ebay/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createeBay(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/ebay/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteeBay(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/ebay/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Edmunds
function* getEdmunds() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/edmunds/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createEdmunds(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/edmunds/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEdmunds(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/edmunds/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Zoom
function* getZoom() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/zoom/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//Etsy
function* getEtsy() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/etsy/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createEtsy(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/etsy/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteEtsy(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/etsy/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Hotels
function* getHotels() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/hotels/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createHotels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/hotels/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteHotels(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/hotels/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Houzz
function* getHouzz() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/houzz/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createHouzz(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/houzz/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteHouzz(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/houzz/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//OpenTable
function* getOpenTable() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/opentable/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createOpenTable(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/opentable/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteOpenTable(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/opentable/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//MyDukkan
function* getMyDukkan() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/mydukaan/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteMyDukkan(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/mydukaan/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Instagram post
function* getInstaPostInt() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/instapost/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInstaPost(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/instapost/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* linkInstaPostPage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/instapost/createPage', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteInstaPostList(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/instapost/deletePage?pgid=' + action.payload.pgid + '&_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//TikTok
function* getTikTok() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/tiktok/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createTikTok(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/tiktok/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteTikTok(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/tiktok/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Commercetools
function* getCommercetools() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/commercetools/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCommercetools(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/commercetools/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCommercetools(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/commercetools/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Elopage
function* getElopage() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/elopage/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createElopage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/elopage/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteElopage(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/elopage/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//G2
function* getG2() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/g2/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createG2(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/g2/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteG2(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/g2/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Squarespace
function* getSquarespace() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/squarespace/getSquarespace');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteSquarespace(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/squarespace/deleteSquarespace?_id=' + action.payload._id);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//ShopperApproved
function* getShopperApproved() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/shopperapproved/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createShopperApproved(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/shopperapproved/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteShopperApproved(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/shopperapproved/delete?id=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//nopCommerce
function* getnopCommerce() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/nopcommerce/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deletenopCommerce(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/nopcommerce/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//Webhook
function* getWebhook(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.GET, '/webhook/get?inm=' + action.payload);
    if (res.status === 'success') {
      yield put(actions.successWebhook(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/webhook/create', action.payload);
    if (res.status === 'success') {
      yield put(actions.successWebhook(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteWebhook(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/webhook/delete?_id=' + action.payload.id + '&sa=' + action.payload.sa + '&ty=' + action.payload.ty + '&inm=' + action.payload.inm);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.successWebhook(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}


//getImages
function* getImages(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/common/getImages', action.payload);
    if (res.status === 'success') {
      yield put(actions.iconSuccess(res.data));
    }
    else if (res.status === 'duplicate') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//CutomReview
function* addCutomReview(action) {
  try {
    // yield put(load());
    const res = yield call(apiIntegrate.POST, '/common/addCutomReview', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    // yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* updateReview(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/common/updateReview', action.payload);
    if (res.status === 'success') {
      yield put(actions.iconSuccess(res.data));
      yield put(actions.updateReviewSuccess(res.data));
      yield put(actions.sucessReviewData(res.reviews));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//uploadCSV
function* uploadCSV(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POSTFILE, '/importreview/uploadCSV', action.payload);
    if (res.status === 'success') {
      yield put(actions.sucessReviewData(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//deleteImage
function* deleteImage(action) {
  try {
    //yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/common/deleteImage?id=' + action.payload.id + '&wbstlnk=' + action.payload.wbstlnk);
    if (res.status === 'success') {
      yield put(actions.iconSuccess(res.data));
      yield put(toastify({ type: 'success', msg: res.m }));
    }
    else if (res.status === 'duplicate') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    //yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

//CashFree
function* getCashFree() {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.GET, '/cashfree/get');
    if (res.status === 'success') {
      yield put(actions.intSuccess(res.data));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* createCashFree(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.POST, '/cashfree/create', action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}

function* deleteCashFree(action) {
  try {
    yield put(load());
    const res = yield call(apiIntegrate.DELETE, '/cashfree/delete?_id=' + action.payload);
    if (res.status === 'success') {
      yield put(toastify({ type: 'success', msg: res.m }));
      yield put(actions.intSuccess(res.data));
    }
    else if (res.status === 'fail') {
      yield put(toastify({ type: 'error', msg: res.m }));
    }
    yield put(loaded());
  }
  catch (e) {
    yield put(toastify({ type: 'error', msg: 'Something went wrong while doing. Please try again.' }));
  }
}



export function* watchGetIntegration() {
  yield takeLatest(actions.getIntegration.type, getIntegration);
}
export function* watchGetIntMstList() {
  yield takeLatest(actions.getIntMstList.type, getIntMstList);
}

//send in blue
export function* watchGetSendInBlue() {
  yield takeLatest(actions.getSendInBlue.type, getSendInBlue);
}
export function* watchCreateSendInBlue() {
  yield takeLatest(actions.createSendInBlue.type, createSendInBlue);
}
export function* watchDeleteSendInBlue() {
  yield takeLatest(actions.deleteSendInBlue.type, deleteSendInBlue);
}

//convertkit
export function* watchGetConvertkit() {
  yield takeLatest(actions.getConvertkit.type, getConvertkit);
}
export function* watchCreateConvertkit() {
  yield takeLatest(actions.createConvertkit.type, createConvertkit);
}
export function* watchDeleteConvertkit() {
  yield takeLatest(actions.deleteConvertkit.type, deleteConvertkit);
}

//google map
export function* watchGetGoogle() {
  yield takeLatest(actions.getGoogle.type, getGoogle);
}
export function* watchCreateGoogle() {
  yield takeLatest(actions.createGoogle.type, createGoogle);
}
export function* watchDeleteGoogle() {
  yield takeLatest(actions.deleteGoogle.type, deleteGoogle);
}

//lemlist
export function* watchGetLemlist() {
  yield takeLatest(actions.getLemlist.type, getLemlist);
}
export function* watchCreateLemlist() {
  yield takeLatest(actions.createLemlist.type, createLemlist);
}
export function* watchDeleteLemlist() {
  yield takeLatest(actions.deleteLemlist.type, deleteLemlist);
}

//Aweber
export function* watchGetAweber() {
  yield takeLatest(actions.getAweber.type, getAweber);
}
export function* watchCreateAweber() {
  yield takeLatest(actions.createAweber.type, createAweber);
}
export function* watchCreateAweberList() {
  yield takeLatest(actions.createAweberList.type, createAweberList);
}
export function* watchDeleteList() {
  yield takeLatest(actions.deleteList.type, deleteList);
}
export function* watchDeleteAweber() {
  yield takeLatest(actions.deleteAweber.type, deleteAweber);
}
export function* watchSyncAweberList() {
  yield takeLatest(actions.syncAweberList.type, syncAweberList);
}

//Mailchimp
export function* watchGetMailchimp() {
  yield takeLatest(actions.getMailchimp.type, getMailchimp);
}
export function* watchCreateMailchimp() {
  yield takeLatest(actions.createMailchimp.type, createMailchimp);
}
export function* watchCreateMailchimpWebhook() {
  yield takeLatest(actions.createMailchimpWebhook.type, createMailchimpWebhook);
}
export function* watchDeleteMC() {
  yield takeLatest(actions.deleteMC.type, deleteMC);
}
export function* watchDeleteMCList() {
  yield takeLatest(actions.deleteMCList.type, deleteMCList);
}

//Facebook
export function* watchGetFBAC() {
  yield takeLatest(actions.getFBAC.type, getFBAC);
}
export function* watchCreateFBAC() {
  yield takeLatest(actions.createFBAC.type, createFBAC);
}
export function* watchCreateFBPage() {
  yield takeLatest(actions.createFBPage.type, createFBPage);
}
export function* watchDeleteFBList() {
  yield takeLatest(actions.deleteFBList.type, deleteFBList);
}
export function* watchDeleteFB() {
  yield takeLatest(actions.deleteFB.type, deleteFB);
}

//bigcommerce
export function* watchGetBigCommerce() {
  yield takeLatest(actions.getBigCommerce.type, getBigCommerce);
}
export function* watchCreateBigCommerce() {
  yield takeLatest(actions.createBigCommerce.type, createBigCommerce);
}
export function* watchDeleteBigCommerce() {
  yield takeLatest(actions.deleteBigCommerce.type, deleteBigCommerce);
}
export function* watchUpdBigcmWebhook() {
  yield takeLatest(actions.updBigcmWebhook.type, updBigcmWebhook);
}

//GMB
export function* watchGetGMB() {
  yield takeLatest(actions.getGMB.type, getGMB);
}
export function* watchCreateGMB() {
  yield takeLatest(actions.createGMB.type, createGMB);
}
export function* watchCreateGMBPage() {
  yield takeLatest(actions.createGMBPage.type, createGMBPage);
}
export function* watchDeletePage() {
  yield takeLatest(actions.deletePage.type, deletePage);
}
export function* watchDeleteGMB() {
  yield takeLatest(actions.deleteGMB.type, deleteGMB);
}
export function* watchSyncGooglePage() {
  yield takeLatest(actions.syncGooglePage.type, syncGooglePage);
}

//Zapier
export function* watchGetZap() {
  yield takeLatest(actions.getZap.type, getZap);
}
export function* watchCreateZap() {
  yield takeLatest(actions.createZap.type, createZap);
}
export function* watchDeleteZap() {
  yield takeLatest(actions.deleteZap.type, deleteZap);
}

//Neto
export function* watchGetNeto() {
  yield takeLatest(actions.getNeto.type, getNeto);
}
export function* watchCreateNeto() {
  yield takeLatest(actions.createNeto.type, createNeto);
}
export function* watchDeleteNeto() {
  yield takeLatest(actions.deleteNeto.type, deleteNeto);
}

//Calendly
export function* watchGetCalendly() {
  yield takeLatest(actions.getCalendly.type, getCalendly);
}
export function* watchCreateCalendly() {
  yield takeLatest(actions.createCalendly.type, createCalendly);
}
export function* watchDeleteCalendly() {
  yield takeLatest(actions.deleteCalendly.type, deleteCalendly);
}

export function* watchGetPl() {
  yield takeLatest(actions.getPl.type, getPl);
}
export function* watchDeletePl() {
  yield takeLatest(actions.deletePl.type, deletePl);
}

//PIPEDRIVE
export function* watchGetPipedrive() {
  yield takeLatest(actions.getPipedrive.type, getPipedrive);
}
export function* watchCreatePipedrive() {
  yield takeLatest(actions.createPipedrive.type, createPipedrive);
}
export function* watchDeletePipedrive() {
  yield takeLatest(actions.deletePipedrive.type, deletePipedrive);
}

//kit
export function* watchGetKit() {
  yield takeLatest(actions.getKit.type, getKit);
}

export function* watchDeleteKit() {
  yield takeLatest(actions.deleteKit.type, deleteKit);
}

export function* watchCreateKitForm() {
  yield takeLatest(actions.createKitForm.type, createKitForm);
}

export function* watchDeleteFormList() {
  yield takeLatest(actions.deleteFormList.type, deleteFormList);
}

//shopify
export function* watchGetShopify() {
  yield takeLatest(actions.getShopify.type, getShopify);
}
export function* watchAddPixelTag() {
  yield takeLatest(actions.addPixelTag.type, addPixelTag);
}
export function* watchDeletePixel() {
  yield takeLatest(actions.deletePixel.type, deletePixel);
}

//Shopaccino
export function* watchGetShopaccino() {
  yield takeLatest(actions.getShopaccino.type, getShopaccino);
}
export function* watchCreateShopaccino() {
  yield takeLatest(actions.createShopaccino.type, createShopaccino);
}
export function* watchDeleteShopaccino() {
  yield takeLatest(actions.deleteShopaccino.type, deleteShopaccino);
}
//shopline
export function* watchGetShopline() {
  yield takeLatest(actions.getShopline.type, getShopline);
}
export function* watchAddPixelTagShopline() {
  yield takeLatest(actions.addPixelTagShopline.type, addPixelTagShopline);
}
export function* watchDeletePixelShopline() {
  yield takeLatest(actions.deletePixelShopline.type, deletePixelShopline);
}

//MAILERLITE
export function* watchGetMailerLite() {
  yield takeLatest(actions.getMailerLite.type, getMailerLite);
}
export function* watchCreateMailerLite() {
  yield takeLatest(actions.createMailerLite.type, createMailerLite);
}
export function* watchDeleteMailerLite() {
  yield takeLatest(actions.deleteMailerLite.type, deleteMailerLite);
}

//Thrivecart
export function* watchGetThrivecart() {
  yield takeLatest(actions.getThrivecart.type, getThrivecart);
}
export function* watchCreateThrivecart() {
  yield takeLatest(actions.createThrivecart.type, createThrivecart);
}
export function* watchCreateThrivecartWB() {
  yield takeLatest(actions.createThrivecartWB.type, createThrivecartWB);
}
export function* watchDeleteThrivecartWB() {
  yield takeLatest(actions.deleteThrivecartWB.type, deleteThrivecartWB);
}
export function* watchDeleteThrivecart() {
  yield takeLatest(actions.deleteThrivecart.type, deleteThrivecart);
}

//Webflow
export function* watchGetWebflow() {
  yield takeLatest(actions.getWebflow.type, getWebflow);
}
export function* watchCreateWebflow() {
  yield takeLatest(actions.createWebflow.type, createWebflow);
}
export function* watchDeleteWebflow() {
  yield takeLatest(actions.deleteWebflow.type, deleteWebflow);
}

//Cliengo
export function* watchGetCliengo() {
  yield takeLatest(actions.getCliengo.type, getCliengo);
}
export function* watchCreateCliengo() {
  yield takeLatest(actions.createCliengo.type, createCliengo);
}
export function* watchDeleteCliengo() {
  yield takeLatest(actions.deleteCliengo.type, deleteCliengo);
}

//Autopilot
export function* watchGetAutopilot() {
  yield takeLatest(actions.getAutopilot.type, getAutopilot);
}
export function* watchCreateAutopilot() {
  yield takeLatest(actions.createAutopilot.type, createAutopilot);
}
export function* watchDeleteAutopilot() {
  yield takeLatest(actions.deleteAutopilot.type, deleteAutopilot);
}

//Eventbrite
export function* watchGetEventbrite() {
  yield takeLatest(actions.getEventbrite.type, getEventbrite);
}
export function* watchDeleteEventbrite() {
  yield takeLatest(actions.deleteEventbrite.type, deleteEventbrite);
}

//Braintree
export function* watchGetBraintree() {
  yield takeLatest(actions.getBraintree.type, getBraintree);
}
export function* watchCreateBraintree() {
  yield takeLatest(actions.createBraintree.type, createBraintree);
}
export function* watchDeleteBraintree() {
  yield takeLatest(actions.deleteBraintree.type, deleteBraintree);
}

//Scheduleonce
export function* watchGetScheduleonce() {
  yield takeLatest(actions.getScheduleonce.type, getScheduleonce);
}
export function* watchCreateScheduleonce() {
  yield takeLatest(actions.createScheduleonce.type, createScheduleonce);
}
export function* watchDeleteScheduleonce() {
  yield takeLatest(actions.deleteScheduleonce.type, deleteScheduleonce);
}

//Stripe
export function* watchGetStripe() {
  yield takeLatest(actions.getStripe.type, getStripe);
}
export function* watchCreateStripe() {
  yield takeLatest(actions.createStripe.type, createStripe);
}
export function* watchDeleteStripe() {
  yield takeLatest(actions.deleteStripe.type, deleteStripe);
}

//WordpressReview
export function* watchGetWordpressReview() {
  yield takeLatest(actions.getWordpressReview.type, getWordpressReview);
}
export function* watchCreateWordpressReview() {
  yield takeLatest(actions.createWordpressReview.type, createWordpressReview);
}
export function* watchDeleteWordpressReview() {
  yield takeLatest(actions.deleteWordpressReview.type, deleteWordpressReview);
}

//AcuityScheduling
export function* watchGetAcuityScheduling() {
  yield takeLatest(actions.getAcuityScheduling.type, getAcuityScheduling);
}
export function* watchCreateAcuityScheduling() {
  yield takeLatest(actions.createAcuityScheduling.type, createAcuityScheduling);
}
export function* watchDeleteAcuityScheduling() {
  yield takeLatest(actions.deleteAcuityScheduling.type, deleteAcuityScheduling);
}

//SendOwl
export function* watchGetSendOwl() {
  yield takeLatest(actions.getSendOwl.type, getSendOwl);
}
export function* watchCreateSendOwl() {
  yield takeLatest(actions.createSendOwl.type, createSendOwl);
}
export function* watchDeleteSendOwl() {
  yield takeLatest(actions.deleteSendOwl.type, deleteSendOwl);
}

//CampaignMonitor
export function* watchGetCampaignMonitor() {
  yield takeLatest(actions.getCampaignMonitor.type, getCampaignMonitor);
}
export function* watchCreateCampaignMonitor() {
  yield takeLatest(actions.createCampaignMonitor.type, createCampaignMonitor);
}
export function* watchDeleteCampaignMonitor() {
  yield takeLatest(actions.deleteCampaignMonitor.type, deleteCampaignMonitor);
}

//Bonusly
export function* watchGetBonusly() {
  yield takeLatest(actions.getBonusly.type, getBonusly);
}
export function* watchCreateBonusly() {
  yield takeLatest(actions.createBonusly.type, createBonusly);
}
export function* watchDeleteBonusly() {
  yield takeLatest(actions.deleteBonusly.type, deleteBonusly);
}

//HubSpot
export function* watchGetHubSpot() {
  yield takeLatest(actions.getHubSpot.type, getHubSpot);
}
export function* watchCreateHubSpot() {
  yield takeLatest(actions.createHubSpot.type, createHubSpot);
}
export function* watchDeleteHubSpot() {
  yield takeLatest(actions.deleteHubSpot.type, deleteHubSpot);
}

//Twitter
export function* watchGetTwitter() {
  yield takeLatest(actions.getTwitter.type, getTwitter);
}
export function* watchCreateTwitter() {
  yield takeLatest(actions.createTwitter.type, createTwitter);
}
export function* watchDeleteTwitter() {
  yield takeLatest(actions.deleteTwitter.type, deleteTwitter);
}

//YtChannel
export function* watchGetYtChannel() {
  yield takeLatest(actions.getYtChannel.type, getYtChannel);
}
export function* watchCreateYtChannel() {
  yield takeLatest(actions.createYtChannel.type, createYtChannel);
}
export function* watchDeleteYtChannel() {
  yield takeLatest(actions.deleteYtChannel.type, deleteYtChannel);
}

//YtVideo
export function* watchGetYtVideo() {
  yield takeLatest(actions.getYtVideo.type, getYtVideo);
}
export function* watchGetPlayList() {
  yield takeLatest(actions.getPlayList.type, getPlayList);
}
export function* watchCreateVideo() {
  yield takeLatest(actions.createVideo.type, createVideo);
}
export function* watchDeleteYtVideo() {
  yield takeLatest(actions.deleteYtVideo.type, deleteYtVideo);
}
export function* watchDeleteYtRecord() {
  yield takeLatest(actions.deleteYtRecord.type, deleteYtRecord);
}
export function* watchCheckVideo() {
  yield takeLatest(actions.checkVideo.type, checkVideo);
}

//Capterra
export function* watchGetCapterra() {
  yield takeLatest(actions.getCapterra.type, getCapterra);
}
export function* watchCreateCapterra() {
  yield takeLatest(actions.createCapterra.type, createCapterra);
}
export function* watchDeleteCapterra() {
  yield takeLatest(actions.deleteCapterra.type, deleteCapterra);
}

//Integromat
export function* watchGetIntegromat() {
  yield takeLatest(actions.getIntegromat.type, getIntegromat);
}
export function* watchCreateIntegromat() {
  yield takeLatest(actions.createIntegromat.type, createIntegromat);
}
export function* watchDeleteIntegromat() {
  yield takeLatest(actions.deleteIntegromat.type, deleteIntegromat);
}

//TwitFoll
export function* watchGetTwitFoll() {
  yield takeLatest(actions.getTwitFoll.type, getTwitFoll);
}
export function* watchCreateTwitFoll() {
  yield takeLatest(actions.createTwitFoll.type, createTwitFoll);
}
export function* watchDeleteFollower() {
  yield takeLatest(actions.deleteFollower.type, deleteFollower);
}

//insta follower
export function* watchGetInstaFoll() {
  yield takeLatest(actions.getInstaFoll.type, getInstaFoll);
}
export function* watchCreateInstaFoll() {
  yield takeLatest(actions.createInstaFoll.type, createInstaFoll);
}
export function* watchDeleteInstaFollower() {
  yield takeLatest(actions.deleteInstaFollower.type, deleteInstaFollower);
}
export function* watchLinkInstaPage() {
  yield takeLatest(actions.linkInstaPage.type, linkInstaPage);
}
export function* watchDeleteInstaList() {
  yield takeLatest(actions.deleteInstaList.type, deleteInstaList);
}

//Linkedin
export function* watchGetLinkedin() {
  yield takeLatest(actions.getLinkedin.type, getLinkedin);
}
// export function* watchCreateLinkedin() {
//   yield takeLatest(actions.createLinkedin.type, createLinkedin);
// }
export function* watchDeleteLinkedin() {
  yield takeLatest(actions.deleteLinkedin.type, deleteLinkedin);
}
export function* watchLinkLinkedinPag() {
  yield takeLatest(actions.linkLinkedinPage.type, linkLinkedinPage);
}
export function* watchDeleteLinkList() {
  yield takeLatest(actions.deleteLinkList.type, deleteLinkList);
}

//Thinkific
export function* watchGetThinkific() {
  yield takeLatest(actions.getThinkific.type, getThinkific);
}
export function* watchCreateThinkific() {
  yield takeLatest(actions.createThinkific.type, createThinkific);
}
export function* watchDeleteThinkific() {
  yield takeLatest(actions.deleteThinkific.type, deleteThinkific);
}

//facebook lead
export function* watchGetFBLAC() {
  yield takeLatest(actions.getFBLAC.type, getFBLAC);
}
export function* watchSyncLeadForms() {
  yield takeLatest(actions.syncLeadForms.type, syncLeadForms);
}
export function* watchCreateFBLAC() {
  yield takeLatest(actions.createFBLAC.type, createFBLAC);
}
export function* watchCreateFBLPage() {
  yield takeLatest(actions.createFBLPage.type, createFBLPage);
}
export function* watchDeleteFBLList() {
  yield takeLatest(actions.deleteFBLList.type, deleteFBLList);
}
export function* watchDeleteFBL() {
  yield takeLatest(actions.deleteFBL.type, deleteFBL);
}

//CustomWebhook
export function* watchGetCustomWebhook() {
  yield takeLatest(actions.getCustomWebhook.type, getCustomWebhook);
}
export function* watchCreateCustomWebhook() {
  yield takeLatest(actions.createCustomWebhook.type, createCustomWebhook);
}
export function* watchDeleteCustomWebhook() {
  yield takeLatest(actions.deleteCustomWebhook.type, deleteCustomWebhook);
}
export function* watchGetCustomWebhookField() {
  yield takeLatest(actions.getCustomWebhookField.type, getCustomWebhookField);
}
export function* watchCreateCustomWebhookFieldUint() {
  yield takeLatest(actions.createCustomWebhookFieldUint.type, createCustomWebhookFieldUint);
}
export function* watchDelCustomWebhookFieldUint() {
  yield takeLatest(actions.delCustomWebhookFieldUint.type, delCustomWebhookFieldUint);
}
export function* watchActiveCustFld() {
  yield takeLatest(actions.activeCustFld.type, activeCustFld);
}
export function* watchUpdCustField() {
  yield takeLatest(actions.updCustField.type, updCustField);
}
export function* watchUpdIntIsActive() {
  yield takeLatest(actions.updIntIsActive.type, updIntIsActive);
}
export function* watchGetNotifForInt() {
  yield takeLatest(actions.getNotifForInt.type, getNotifForInt);
}



//custom webform
export function* watchGetCustomWebform() {
  yield takeLatest(actions.getCustomWebform.type, getCustomWebform);
}
export function* watchCreateCustomWebform() {
  yield takeLatest(actions.createCustomWebform.type, createCustomWebform);
}
export function* watchDeleteCustomWebform() {
  yield takeLatest(actions.deleteCustomWebform.type, deleteCustomWebform);
}
export function* watchGetWBFormField() {
  yield takeLatest(actions.getWBFormField.type, getWBFormField);
}
export function* watchUpdCWFFld() {
  yield takeLatest(actions.updCWFFld.type, updCWFFld);
}
export function* watchCreateCWFKy() {
  yield takeLatest(actions.createCWFKy.type, createCWFKy);
}
export function* watchDelCWFKy() {
  yield takeLatest(actions.delCWFKy.type, delCWFKy);
}

//intercom
export function* watchGetIntercom() {
  yield takeLatest(actions.getIntercom.type, getIntercom);
}
export function* watchCreateIntercom() {
  yield takeLatest(actions.createIntercom.type, createIntercom);
}
export function* watchDeleteIntercom() {
  yield takeLatest(actions.deleteIntercom.type, deleteIntercom);
}

//sendfox
export function* watchGetSendfox() {
  yield takeLatest(actions.getSendfox.type, getSendfox);
}
export function* watchCreateSend() {
  yield takeLatest(actions.createSendfox.type, createSendfox);
}
export function* watchAddSendfoxList() {
  yield takeLatest(actions.addSendfoxList.type, addSendfoxList);
}
export function* watchDeleteSendfoxList() {
  yield takeLatest(actions.deleteSendfoxList.type, deleteSendfoxList);
}
export function* watchDeleteSendfox() {
  yield takeLatest(actions.deleteSendfox.type, deleteSendfox);
}

//automizy
export function* watchGetAutomizy() {
  yield takeLatest(actions.getAutomizy.type, getAutomizy);
}
export function* watchCreateAutomizy() {
  yield takeLatest(actions.createAutomizy.type, createAutomizy);
}
export function* watchAddAutomizyList() {
  yield takeLatest(actions.addAutomizyList.type, addAutomizyList);
}
export function* watchDeleteAutomizyList() {
  yield takeLatest(actions.deleteAutomizyList.type, deleteAutomizyList);
}
export function* watchDeleteAutomizy() {
  yield takeLatest(actions.deleteAutomizy.type, deleteAutomizy);
}

//yelp
export function* watchGetYelp() {
  yield takeLatest(actions.getYelp.type, getYelp);
}
export function* watchCreateYelp() {
  yield takeLatest(actions.createYelp.type, createYelp);
}
export function* watchDeleteYelp() {
  yield takeLatest(actions.deleteYelp.type, deleteYelp);
}

//Livewebinar
export function* watchGetLivewebinar() {
  yield takeLatest(actions.getLivewebinar.type, getLivewebinar);
}
export function* watchCreateLivewebinar() {
  yield takeLatest(actions.createLivewebinar.type, createLivewebinar);
}
export function* watchDeleteLivewebinar() {
  yield takeLatest(actions.deleteLivewebinar.type, deleteLivewebinar);
}

//Amember
export function* watchGetAmember() {
  yield takeLatest(actions.getAmember.type, getAmember);
}
export function* watchCreateAmember() {
  yield takeLatest(actions.createAmember.type, createAmember);
}
export function* watchDeleteAmember() {
  yield takeLatest(actions.deleteAmember.type, deleteAmember);
}

//newzenler
export function* watchGetNewzenler() {
  yield takeLatest(actions.getNewzenler.type, getNewzenler);
}
export function* watchCreateNewzenler() {
  yield takeLatest(actions.createNewzenler.type, createNewzenler);
}
export function* watchDeleteNewzenler() {
  yield takeLatest(actions.deleteNewzenler.type, deleteNewzenler);
}

//yotpo
export function* watchGetYotpo() {
  yield takeLatest(actions.getYotpo.type, getYotpo);
}
export function* watchCreateYotpo() {
  yield takeLatest(actions.createYotpo.type, createYotpo);
}
export function* watchDeleteYotpo() {
  yield takeLatest(actions.deleteYotpo.type, deleteYotpo);
}

//Repuso
export function* watchGetRepuso() {
  yield takeLatest(actions.getRepuso.type, getRepuso);
}
export function* watchCreateRepuso() {
  yield takeLatest(actions.createRepuso.type, createRepuso);
}
export function* watchDeleteRepuso() {
  yield takeLatest(actions.deleteRepuso.type, deleteRepuso);
}

//Heysummit
export function* watchGetHeysummit() {
  yield takeLatest(actions.getHeysummit.type, getHeysummit);
}
export function* watchCreateHeysummit() {
  yield takeLatest(actions.createHeysummit.type, createHeysummit);
}
export function* watchCreateHeySummitWebhook() {
  yield takeLatest(actions.createHeySummitWebhook.type, createHeySummitWebhook);
}
export function* watchDeleteHeysummit() {
  yield takeLatest(actions.deleteHeysummit.type, deleteHeysummit);
}
export function* watchSyncHeysummitEvent() {
  yield takeLatest(actions.syncHeysummitEvent.type, syncHeysummitEvent);
}

//edd
export function* watchGetEdd() {
  yield takeLatest(actions.getEdd.type, getEdd);
}
export function* watchDeleteEdd() {
  yield takeLatest(actions.deleteEdd.type, deleteEdd);
}

//elite funnels
export function* watchGetElitefunnels() {
  yield takeLatest(actions.getElitefunnels.type, getElitefunnels);
}
export function* watchCreateElitefunnels() {
  yield takeLatest(actions.createElitefunnels.type, createElitefunnels);
}
export function* watchDeleteElitefunnels() {
  yield takeLatest(actions.deleteElitefunnels.type, deleteElitefunnels);
}

//fast funnels
export function* watchGetFastfunnels() {
  yield takeLatest(actions.getFastfunnels.type, getFastfunnels);
}
export function* watchCreateFastfunnels() {
  yield takeLatest(actions.createFastfunnels.type, createFastfunnels);
}
export function* watchDeleteFastfunnels() {
  yield takeLatest(actions.deleteFastfunnels.type, deleteFastfunnels);
}

//getdrip
export function* watchGetdrip() {
  yield takeLatest(actions.getGetdrip.type, getGetdrip);
}
export function* watchCreateGetdrip() {
  yield takeLatest(actions.createGetdrip.type, createGetdrip);
}
export function* watchDeleteGetdrip() {
  yield takeLatest(actions.deleteGetdrip.type, deleteGetdrip);
}

//spotify
export function* watchGetSpotify() {
  yield takeLatest(actions.getSpotify.type, getSpotify);
}
export function* watchCreateAlTR() {
  yield takeLatest(actions.createAlTR.type, createAlTR);
}
export function* watchDeleteARAL() {
  yield takeLatest(actions.deleteARAL.type, deleteARAL);
}
export function* watchCreateSpotify() {
  yield takeLatest(actions.createSpotify.type, createSpotify);
}
export function* watchDeleteSpotify() {
  yield takeLatest(actions.deleteSpotify.type, deleteSpotify);
}

//opencart
export function* watchGetOpenCart() {
  yield takeLatest(actions.getOpenCart.type, getOpenCart);
}
export function* watchDeleteOpenCart() {
  yield takeLatest(actions.deleteOpenCart.type, deleteOpenCart);
}

//Surveymonkey
export function* watchGetSurveymonkey() {
  yield takeLatest(actions.getSurveymonkey.type, getSurveymonkey);
}
export function* watchCreateSMWB() {
  yield takeLatest(actions.createSMWB.type, createSMWB);
}
export function* watchDeleteSMWB() {
  yield takeLatest(actions.deleteSMWB.type, deleteSMWB);
}
export function* watchDeleteSurveyMonkey() {
  yield takeLatest(actions.deleteSurveyMonkey.type, deleteSurveyMonkey);
}

//twitchtv
export function* watchGetTwitchTV() {
  yield takeLatest(actions.getTwitchTV.type, getTwitchTV);
}
export function* watchCreateTwitchTV() {
  yield takeLatest(actions.createTwitchTV.type, createTwitchTV);
}
export function* watchDeleteTwitchTV() {
  yield takeLatest(actions.deleteTwitchTV.type, deleteTwitchTV);
}

//import review
export function* watchGetImportreview() {
  yield takeLatest(actions.getImportreview.type, getImportreview);
}
export function* watchCreateImportReview() {
  yield takeLatest(actions.createImportReview.type, createImportReview);
}
export function* watchDeleteImportReview() {
  yield takeLatest(actions.deleteImportReview.type, deleteImportReview);
}

//prestashop
export function* watchGetPrestashop() {
  yield takeLatest(actions.getPrestashop.type, getPrestashop);
}
export function* watchDeletePrestashop() {
  yield takeLatest(actions.deletePrestashop.type, deletePrestashop);
}

//wishpond
export function* watchGetWishpond() {
  yield takeLatest(actions.getWishpond.type, getWishpond);
}
export function* watchCreateWishpond() {
  yield takeLatest(actions.createWishpond.type, createWishpond);
}
export function* watchDeleteWishpond() {
  yield takeLatest(actions.deleteWishpond.type, deleteWishpond);
}

//zoomai
export function* watchGetZoomai() {
  yield takeLatest(actions.getZoomai.type, getZoomai);
}
export function* watchCreateZoomai() {
  yield takeLatest(actions.createZoomai.type, createZoomai);
}
export function* watchDeleteZoomai() {
  yield takeLatest(actions.deleteZoomai.type, deleteZoomai);
}

//booking 
export function* watchGetBooking() {
  yield takeLatest(actions.getBooking.type, getBooking);
}
export function* watchCreateBooking() {
  yield takeLatest(actions.createBooking.type, createBooking);
}
export function* watchDeleteBooking() {
  yield takeLatest(actions.deleteBooking.type, deleteBooking);
}

//trustpilot
export function* watchGetTrustpilot() {
  yield takeLatest(actions.getTrustpilot.type, getTrustpilot);
}
export function* watchCreateTrustpilot() {
  yield takeLatest(actions.createTrustpilot.type, createTrustpilot);
}
export function* watchDeleteTrustpilot() {
  yield takeLatest(actions.deleteTrustpilot.type, deleteTrustpilot);
}

//tripadvisor
export function* watchGetTRPA() {
  yield takeLatest(actions.getTRPA.type, getTRPA);
}
export function* watchCreateTRPA() {
  yield takeLatest(actions.createTRPA.type, createTRPA);
}
export function* watchDeleteTRPA() {
  yield takeLatest(actions.deleteTRPA.type, deleteTRPA);
}

//ontraport
export function* watchgetOntraport() {
  yield takeLatest(actions.getOntraport.type, getOntraport);
}
export function* watchcreateOntraport() {
  yield takeLatest(actions.createOntraport.type, createOntraport);
}
export function* watchdeleteTRPA() {
  yield takeLatest(actions.deleteOntraport.type, deleteOntraport);
}

//Stamped
export function* watchGetStamped() {
  yield takeLatest(actions.getStamped.type, getStamped);
}
export function* watchCreateStamped() {
  yield takeLatest(actions.createStamped.type, createStamped);
}
export function* watchDeleteStamped() {
  yield takeLatest(actions.deleteStamped.type, deleteStamped);
}

//clixli
export function* watchGetClixli() {
  yield takeLatest(actions.getClixli.type, getClixli);
}
export function* watchCreateClixli() {
  yield takeLatest(actions.createClixli.type, createClixli);
}
export function* watchDeleteClixli() {
  yield takeLatest(actions.deleteClixli.type, deleteClixli);
}

//airbnb
export function* watchGetAirbnb() {
  yield takeLatest(actions.getAirbnb.type, getAirbnb);
}
export function* watchCreateAirbnb() {
  yield takeLatest(actions.createAirbnb.type, createAirbnb);
}
export function* watchDeleteAirbnb() {
  yield takeLatest(actions.deleteAirbnb.type, deleteAirbnb);
}

//wiserreview
export function* watchGetWiserReview() {
  yield takeLatest(actions.getWiserReview.type, getWiserReview);
}
export function* watchCreateWiserReview() {
  yield takeLatest(actions.createWiserReview.type, createWiserReview);
}
export function* watchDeleteWiserReview() {
  yield takeLatest(actions.deleteWiserReview.type, deleteWiserReview);
}

//wix
export function* watchGetWix() {
  yield takeLatest(actions.getWix.type, getWix);
}
export function* watchCreateWix() {
  yield takeLatest(actions.createWix.type, createWix);
}
export function* watchDeleteWix() {
  yield takeLatest(actions.deleteWix.type, deleteWix);
}

//reviewio
export function* watchGetReviewio() {
  yield takeLatest(actions.getReviewio.type, getReviewio);
}
export function* watchCreateReviewio() {
  yield takeLatest(actions.createReviewio.type, createReviewio);
}
export function* watchDeleteReviewio() {
  yield takeLatest(actions.deleteReviewio.type, deleteReviewio);
}

//simvoly
export function* watchGetSimvoly() {
  yield takeLatest(actions.getSimvoly.type, getSimvoly);
}
export function* watchCreateSimvoly() {
  yield takeLatest(actions.createSimvoly.type, createSimvoly);
}
export function* watchDeleteSimvoly() {
  yield takeLatest(actions.deleteSimvoly.type, deleteSimvoly);
}

//modwebs
export function* watchGetModwebs() {
  yield takeLatest(actions.getModwebs.type, getModwebs);
}
export function* watchCreateModwebs() {
  yield takeLatest(actions.createModwebs.type, createModwebs);
}
export function* watchDeleteModwebs() {
  yield takeLatest(actions.deleteModwebs.type, deleteModwebs);
}

//appsumo
export function* watchGetAppSumo() {
  yield takeLatest(actions.getAppSumo.type, getAppSumo);
}
export function* watchCreateAppSumo() {
  yield takeLatest(actions.createAppSumo.type, createAppSumo);
}
export function* watchDeleteAppSumo() {
  yield takeLatest(actions.deleteAppSumo.type, deleteAppSumo);
}

//practo
export function* watchGetPracto() {
  yield takeLatest(actions.getPracto.type, getPracto);
}
export function* watchCreatePracto() {
  yield takeLatest(actions.createPracto.type, createPracto);
}
export function* watchDeletePracto() {
  yield takeLatest(actions.deletePracto.type, deletePracto);
}

//google play
export function* watchGetGooglePlay() {
  yield takeLatest(actions.getGooglePlay.type, getGooglePlay);
}
export function* watchCreateGooglePlay() {
  yield takeLatest(actions.createGooglePlay.type, createGooglePlay);
}
export function* watchDeleteGooglePlay() {
  yield takeLatest(actions.deleteGooglePlay.type, deleteGooglePlay);
}

//ecwid
export function* watchGetEcwid() {
  yield takeLatest(actions.getEcwid.type, getEcwid);
}
export function* watchDeleteEcwid() {
  yield takeLatest(actions.deleteEcwid.type, deleteEcwid);
}

//facebook gruop
export function* watchGetFBGrp() {
  yield takeLatest(actions.getFBGrp.type, getFBGrp);
}
export function* watchCreateFBGrp() {
  yield takeLatest(actions.createFBGrp.type, createFBGrp);
}
export function* watchDeleteFBGrp() {
  yield takeLatest(actions.deleteFBGrp.type, deleteFBGrp);
}
export function* watchDeleteFBGrpAcc() {
  yield takeLatest(actions.deleteFBGrpAcc.type, deleteFBGrpAcc);
}
export function* watchSyncGroups() {
  yield takeLatest(actions.syncGroups.type, syncGroups);
}

//Encharge
export function* watchGetEncharge() {
  yield takeLatest(actions.getEncharge.type, getEncharge);
}
export function* watchCreateEncharge() {
  yield takeLatest(actions.createEncharge.type, createEncharge);
}
export function* watchDeleteEncharge() {
  yield takeLatest(actions.deleteEncharge.type, deleteEncharge);
}

//3dCart
export function* watchGet3dCart() {
  yield takeLatest(actions.get3dCart.type, get3dCart);
}

//amzone
export function* watchGetAmazon() {
  yield takeLatest(actions.getAmazon.type, getAmazon);
}
export function* watchCreateAmazon() {
  yield takeLatest(actions.createAmazon.type, createAmazon);
}
export function* watchDeleteAmazon() {
  yield takeLatest(actions.deleteAmazon.type, deleteAmazon);
}

//dealerrater
export function* watchGetDealerRater() {
  yield takeLatest(actions.getDealerRater.type, getDealerRater);
}
export function* watchCreateDealerRater() {
  yield takeLatest(actions.createDealerRater.type, createDealerRater);
}
export function* watchDeleteDealerRater() {
  yield takeLatest(actions.deleteDealerRater.type, deleteDealerRater);
}

//apple app
export function* watchGetAppleApp() {
  yield takeLatest(actions.getAppleApp.type, getAppleApp);
}
export function* watchCreateAppleApp() {
  yield takeLatest(actions.createAppleApp.type, createAppleApp);
}
export function* watchDeleteAppleApp() {
  yield takeLatest(actions.deleteAppleApp.type, deleteAppleApp);
}

//BBB
export function* watchGetBBB() {
  yield takeLatest(actions.getBBB.type, getBBB);
}
export function* watchCreateBBB() {
  yield takeLatest(actions.createBBB.type, createBBB);
}
export function* watchDeleteBBB() {
  yield takeLatest(actions.deleteBBB.type, deleteBBB);
}

//ebay
export function* watchGeteBay() {
  yield takeLatest(actions.geteBay.type, geteBay);
}
export function* watchCreateeBay() {
  yield takeLatest(actions.createeBay.type, createeBay);
}
export function* watchDeleteeBay() {
  yield takeLatest(actions.deleteeBay.type, deleteeBay);
}

//edmunds
export function* watchGetEdmunds() {
  yield takeLatest(actions.getEdmunds.type, getEdmunds);
}
export function* watchCreateEdmunds() {
  yield takeLatest(actions.createEdmunds.type, createEdmunds);
}
export function* watchDeleteEdmunds() {
  yield takeLatest(actions.deleteEdmunds.type, deleteEdmunds);
}

//zoom
export function* watchGetZoom() {
  yield takeLatest(actions.getZoom.type, getZoom);
}

//Etsy
export function* watchGetEtsy() {
  yield takeLatest(actions.getEtsy.type, getEtsy);
}
export function* watchCreateEtsy() {
  yield takeLatest(actions.createEtsy.type, createEtsy);
}
export function* watchDeleteEtsy() {
  yield takeLatest(actions.deleteEtsy.type, deleteEtsy);
}

//hotels
export function* watchGetHotels() {
  yield takeLatest(actions.getHotels.type, getHotels);
}
export function* watchCreateHotels() {
  yield takeLatest(actions.createHotels.type, createHotels);
}
export function* watchDeleteHotels() {
  yield takeLatest(actions.deleteHotels.type, deleteHotels);
}

//hotels
export function* watchGetHouzz() {
  yield takeLatest(actions.getHouzz.type, getHouzz);
}
export function* watchCreateHouzz() {
  yield takeLatest(actions.createHouzz.type, createHouzz);
}
export function* watchDeleteHouzz() {
  yield takeLatest(actions.deleteHouzz.type, deleteHouzz);
}

//opentable
export function* watchGetOpenTable() {
  yield takeLatest(actions.getOpenTable.type, getOpenTable);
}
export function* watchCreateOpenTable() {
  yield takeLatest(actions.createOpenTable.type, createOpenTable);
}
export function* watchDeleteOpenTable() {
  yield takeLatest(actions.deleteOpenTable.type, deleteOpenTable);
}

//mydukkan
export function* watchGetMyDukkan() {
  yield takeLatest(actions.getMyDukkan.type, getMyDukkan);
}
export function* watchDeleteMyDukkan() {
  yield takeLatest(actions.deleteMyDukkan.type, deleteMyDukkan);
}

//instapost
export function* watchGetInstaPostInt() {
  yield takeLatest(actions.getInstaPostInt.type, getInstaPostInt);
}
export function* watchLinkInstaPostPage() {
  yield takeLatest(actions.linkInstaPostPage.type, linkInstaPostPage);
}
export function* watchDeleteInstaPostList() {
  yield takeLatest(actions.deleteInstaPostList.type, deleteInstaPostList);
}
export function* watchDeleteInstaPost() {
  yield takeLatest(actions.deleteInstaPost.type, deleteInstaPost);
}

//tiktok
export function* watchGetTikTok() {
  yield takeLatest(actions.getTikTok.type, getTikTok);
}
export function* watchCreateTikTok() {
  yield takeLatest(actions.createTikTok.type, createTikTok);
}
export function* watchDeleteTikTok() {
  yield takeLatest(actions.deleteTikTok.type, deleteTikTok);
}

//Commercetools
export function* watchGetCommercetools() {
  yield takeLatest(actions.getCommercetools.type, getCommercetools);
}
export function* watchCreateCommercetools() {
  yield takeLatest(actions.createCommercetools.type, createCommercetools);
}
export function* watchDeleteCommercetools() {
  yield takeLatest(actions.deleteCommercetools.type, deleteCommercetools);
}

//Elopage
export function* watchGetElopage() {
  yield takeLatest(actions.getElopage.type, getElopage);
}
export function* watchCreateElopage() {
  yield takeLatest(actions.createElopage.type, createElopage);
}
export function* watchDeleteElopage() {
  yield takeLatest(actions.deleteElopage.type, deleteElopage);
}

//g2
export function* watchGetG2() {
  yield takeLatest(actions.getG2.type, getG2);
}
export function* watchCreateG2() {
  yield takeLatest(actions.createG2.type, createG2);
}
export function* watchDeleteG2() {
  yield takeLatest(actions.deleteG2.type, deleteG2);
}

//Squarespace
export function* watchGetSquarespace() {
  yield takeLatest(actions.getSquarespace.type, getSquarespace);
}
export function* watchDeleteSquarespace() {
  yield takeLatest(actions.deleteSquarespace.type, deleteSquarespace);
}

//ShopperApproved
export function* watchGetShopperApproved() {
  yield takeLatest(actions.getShopperApproved.type, getShopperApproved);
}
export function* watchCreateShopperApproved() {
  yield takeLatest(actions.createShopperApproved.type, createShopperApproved);
}
export function* watchDeleteShopperApproved() {
  yield takeLatest(actions.deleteShopperApproved.type, deleteShopperApproved);
}

//nop commerce
export function* watchGetnopCommerce() {
  yield takeLatest(actions.getnopCommerce.type, getnopCommerce);
}
export function* watchDeletenopCommerce() {
  yield takeLatest(actions.deletenopCommerce.type, deletenopCommerce);
}

//webhhok
export function* watchGetWebhook() {
  yield takeLatest(actions.getWebhook.type, getWebhook);
}
export function* watchCreateWebhook() {
  yield takeLatest(actions.createWebhook.type, createWebhook);
}
export function* watchDeleteWebhook() {
  yield takeLatest(actions.deleteWebhook.type, deleteWebhook);
}

//getImages
export function* watchGetImages() {
  yield takeLatest(actions.getImages.type, getImages);
}

//custom review 
export function* watchAddCutomReview() {
  yield takeLatest(actions.addCutomReview.type, addCutomReview);
}
export function* watchUpdateReview() {
  yield takeLatest(actions.updateReview.type, updateReview);
}

//uploadCSV
export function* watchUploadCSV() {
  yield takeLatest(actions.uploadCSV.type, uploadCSV);
}
export function* watchDeleteImage() {
  yield takeLatest(actions.deleteImage.type, deleteImage);
}

// CashFree
export function* watchGetCashFree() {
  yield takeLatest(actions.getCashFree.type, getCashFree);
}
export function* watchCreateCashFree() {
  yield takeLatest(actions.createCashFree.type, createCashFree);
}
export function* watchDeleteCashFree() {
  yield takeLatest(actions.deleteCashFree.type, deleteCashFree);
}



export default function* rootSaga() {
  yield fork(watchGetWebhook);
  yield fork(watchCreateWebhook);
  yield fork(watchDeleteWebhook);

  yield fork(watchGetnopCommerce);
  yield fork(watchDeletenopCommerce);

  yield fork(watchGetShopperApproved);
  yield fork(watchCreateShopperApproved);
  yield fork(watchDeleteShopperApproved);

  yield fork(watchGetSquarespace);
  yield fork(watchDeleteSquarespace);

  yield fork(watchGetG2);
  yield fork(watchCreateG2);
  yield fork(watchDeleteG2);

  yield fork(watchGetElopage);
  yield fork(watchCreateElopage);
  yield fork(watchDeleteElopage);

  yield fork(watchGetCommercetools);
  yield fork(watchCreateCommercetools);
  yield fork(watchDeleteCommercetools);

  yield fork(watchGetTikTok);
  yield fork(watchCreateTikTok);
  yield fork(watchDeleteTikTok);

  yield fork(watchGetInstaPostInt);
  yield fork(watchLinkInstaPostPage);
  yield fork(watchDeleteInstaPostList);
  yield fork(watchDeleteInstaPost);

  yield fork(watchGetMyDukkan);
  yield fork(watchDeleteMyDukkan);

  yield fork(watchGetOpenTable);
  yield fork(watchCreateOpenTable);
  yield fork(watchDeleteOpenTable);

  yield fork(watchGetHouzz);
  yield fork(watchCreateHouzz);
  yield fork(watchDeleteHouzz);

  yield fork(watchGetHotels);
  yield fork(watchCreateHotels);
  yield fork(watchDeleteHotels);

  yield fork(watchGetEtsy);
  yield fork(watchCreateEtsy);
  yield fork(watchDeleteEtsy);

  yield fork(watchGetZoom);

  yield fork(watchGetEdmunds);
  yield fork(watchCreateEdmunds);
  yield fork(watchDeleteEdmunds);

  yield fork(watchGeteBay);
  yield fork(watchCreateeBay);
  yield fork(watchDeleteeBay);

  yield fork(watchGetBBB);
  yield fork(watchCreateBBB);
  yield fork(watchDeleteBBB);

  yield fork(watchGetAppleApp);
  yield fork(watchCreateAppleApp);
  yield fork(watchDeleteAppleApp);

  yield fork(watchGetDealerRater);
  yield fork(watchCreateDealerRater);
  yield fork(watchDeleteDealerRater);

  yield fork(watchGetAmazon);
  yield fork(watchCreateAmazon);
  yield fork(watchDeleteAmazon);

  yield fork(watchGet3dCart);

  yield fork(watchGetEncharge);
  yield fork(watchCreateEncharge);
  yield fork(watchDeleteEncharge);

  yield fork(watchGetFBGrp);
  yield fork(watchCreateFBGrp);
  yield fork(watchDeleteFBGrp);
  yield fork(watchDeleteFBGrpAcc);
  yield fork(watchSyncGroups);

  yield fork(watchGetEcwid);
  yield fork(watchDeleteEcwid);

  yield fork(watchGetGooglePlay);
  yield fork(watchCreateGooglePlay);
  yield fork(watchDeleteGooglePlay);

  yield fork(watchGetPracto);
  yield fork(watchCreatePracto);
  yield fork(watchDeletePracto);

  yield fork(watchGetAppSumo);
  yield fork(watchCreateAppSumo);
  yield fork(watchDeleteAppSumo);

  yield fork(watchGetReviewio);
  yield fork(watchCreateReviewio);
  yield fork(watchDeleteReviewio);

  yield fork(watchGetSimvoly);
  yield fork(watchCreateSimvoly);
  yield fork(watchDeleteSimvoly);

  yield fork(watchGetWix);
  yield fork(watchCreateWix);
  yield fork(watchDeleteWix);

  yield fork(watchGetClixli);
  yield fork(watchCreateClixli);
  yield fork(watchDeleteClixli);

  yield fork(watchGetAirbnb);
  yield fork(watchCreateAirbnb);
  yield fork(watchDeleteAirbnb);

  yield fork(watchGetWiserReview);
  yield fork(watchCreateWiserReview);
  yield fork(watchDeleteWiserReview);

  yield fork(watchGetStamped);
  yield fork(watchCreateStamped);
  yield fork(watchDeleteStamped);

  yield fork(watchGetTRPA);
  yield fork(watchCreateTRPA);
  yield fork(watchDeleteTRPA);

  yield fork(watchGetTrustpilot);
  yield fork(watchCreateTrustpilot);
  yield fork(watchDeleteTrustpilot);

  yield fork(watchGetBooking);
  yield fork(watchCreateBooking);
  yield fork(watchDeleteBooking);

  yield fork(watchGetZoomai);
  yield fork(watchCreateZoomai);
  yield fork(watchDeleteZoomai);

  yield fork(watchGetWishpond);
  yield fork(watchCreateWishpond);
  yield fork(watchDeleteWishpond);

  yield fork(watchGetPrestashop);
  yield fork(watchDeletePrestashop);

  yield fork(watchGetImportreview);
  yield fork(watchCreateImportReview);
  yield fork(watchDeleteImportReview);

  yield fork(watchGetTwitchTV);
  yield fork(watchCreateTwitchTV);
  yield fork(watchDeleteTwitchTV);

  yield fork(watchGetSurveymonkey);
  yield fork(watchCreateSMWB);
  yield fork(watchDeleteSMWB);
  yield fork(watchDeleteSurveyMonkey);

  yield fork(watchGetOpenCart);
  yield fork(watchDeleteOpenCart);

  yield fork(watchGetSpotify);
  yield fork(watchCreateAlTR);
  yield fork(watchDeleteARAL);
  yield fork(watchCreateSpotify);
  yield fork(watchDeleteSpotify);

  yield fork(watchGetdrip);
  yield fork(watchCreateGetdrip);
  yield fork(watchDeleteGetdrip);

  yield fork(watchGetFastfunnels);
  yield fork(watchCreateFastfunnels);
  yield fork(watchDeleteFastfunnels);

  yield fork(watchGetElitefunnels);
  yield fork(watchCreateElitefunnels);
  yield fork(watchDeleteElitefunnels);

  yield fork(watchGetEdd);
  yield fork(watchDeleteEdd);

  yield fork(watchGetHeysummit);
  yield fork(watchCreateHeysummit);
  yield fork(watchCreateHeySummitWebhook);
  yield fork(watchDeleteHeysummit);
  yield fork(watchSyncHeysummitEvent);

  yield fork(watchGetRepuso);
  yield fork(watchCreateRepuso);
  yield fork(watchDeleteRepuso);

  yield fork(watchGetYotpo);
  yield fork(watchCreateYotpo);
  yield fork(watchDeleteYotpo);

  yield fork(watchGetNewzenler);
  yield fork(watchCreateNewzenler);
  yield fork(watchDeleteNewzenler);

  yield fork(watchGetAmember);
  yield fork(watchCreateAmember);
  yield fork(watchDeleteAmember);

  yield fork(watchGetLivewebinar);
  yield fork(watchCreateLivewebinar);
  yield fork(watchDeleteLivewebinar);

  yield fork(watchGetYelp);
  yield fork(watchCreateYelp);
  yield fork(watchDeleteYelp);

  yield fork(watchGetAutomizy);
  yield fork(watchCreateAutomizy);
  yield fork(watchAddAutomizyList);
  yield fork(watchDeleteAutomizyList);
  yield fork(watchDeleteAutomizy);

  yield fork(watchGetSendfox);
  yield fork(watchCreateSend);
  yield fork(watchAddSendfoxList);
  yield fork(watchDeleteSendfoxList);
  yield fork(watchDeleteSendfox);

  yield fork(watchGetIntercom);
  yield fork(watchCreateIntercom);
  yield fork(watchDeleteIntercom);

  yield fork(watchDelCWFKy);
  yield fork(watchCreateCWFKy);
  yield fork(watchUpdCWFFld);
  yield fork(watchGetWBFormField);
  yield fork(watchDeleteCustomWebform);
  yield fork(watchCreateCustomWebform);
  yield fork(watchGetCustomWebform);

  yield fork(watchUpdIntIsActive);
  yield fork(watchUpdCustField);
  yield fork(watchActiveCustFld);
  yield fork(watchDelCustomWebhookFieldUint);
  yield fork(watchCreateCustomWebhookFieldUint);
  yield fork(watchGetCustomWebhookField);
  yield fork(watchGetCustomWebhook);
  yield fork(watchCreateCustomWebhook);
  yield fork(watchDeleteCustomWebhook)

  yield fork(watchGetFBLAC);
  yield fork(watchSyncLeadForms);
  yield fork(watchCreateFBLAC);
  yield fork(watchCreateFBLPage);
  yield fork(watchDeleteFBLList);
  yield fork(watchDeleteFBL);

  yield fork(watchGetThinkific);
  yield fork(watchCreateThinkific);
  yield fork(watchDeleteThinkific);

  yield fork(watchGetLinkedin);
  // yield fork(watchCreateLinkedin);
  yield fork(watchDeleteLinkedin);
  yield fork(watchLinkLinkedinPag);
  yield fork(watchDeleteLinkList);


  yield fork(watchGetInstaFoll);
  yield fork(watchCreateInstaFoll);
  yield fork(watchDeleteInstaFollower);
  yield fork(watchLinkInstaPage);
  yield fork(watchDeleteInstaList);

  yield fork(watchGetIntegromat);
  yield fork(watchCreateIntegromat);
  yield fork(watchDeleteIntegromat);

  yield fork(watchGetCapterra);
  yield fork(watchCreateCapterra);
  yield fork(watchDeleteCapterra);

  yield fork(watchGetYtVideo);
  yield fork(watchGetPlayList);
  yield fork(watchCreateVideo);
  yield fork(watchDeleteYtVideo);
  yield fork(watchDeleteYtRecord);
  yield fork(watchCheckVideo);

  yield fork(watchGetYtChannel);
  yield fork(watchCreateYtChannel);
  yield fork(watchDeleteYtChannel);

  yield fork(watchGetTwitter);
  yield fork(watchCreateTwitter);
  yield fork(watchDeleteTwitter);

  yield fork(watchGetHubSpot);
  yield fork(watchCreateHubSpot);
  yield fork(watchDeleteHubSpot);

  yield fork(watchGetBonusly);
  yield fork(watchCreateBonusly);
  yield fork(watchDeleteBonusly);

  yield fork(watchGetCampaignMonitor);
  yield fork(watchCreateCampaignMonitor);
  yield fork(watchDeleteCampaignMonitor);

  yield fork(watchGetSendOwl);
  yield fork(watchCreateSendOwl);
  yield fork(watchDeleteSendOwl);

  yield fork(watchGetAcuityScheduling);
  yield fork(watchCreateAcuityScheduling);
  yield fork(watchDeleteAcuityScheduling);

  yield fork(watchGetWordpressReview);
  yield fork(watchCreateWordpressReview);
  yield fork(watchDeleteWordpressReview);

  yield fork(watchGetStripe);
  yield fork(watchCreateStripe);
  yield fork(watchDeleteStripe);

  yield fork(watchGetScheduleonce);
  yield fork(watchCreateScheduleonce);
  yield fork(watchDeleteScheduleonce);

  yield fork(watchGetBraintree);
  yield fork(watchCreateBraintree);
  yield fork(watchDeleteBraintree);

  yield fork(watchGetEventbrite);
  yield fork(watchDeleteEventbrite);

  yield fork(watchGetAutopilot);
  yield fork(watchCreateAutopilot);
  yield fork(watchDeleteAutopilot);

  yield fork(watchGetCliengo);
  yield fork(watchCreateCliengo);
  yield fork(watchDeleteCliengo);

  yield fork(watchGetWebflow);
  yield fork(watchCreateWebflow);
  yield fork(watchDeleteWebflow);

  yield fork(watchGetThrivecart);
  yield fork(watchCreateThrivecart);
  yield fork(watchCreateThrivecartWB);
  yield fork(watchDeleteThrivecartWB);
  yield fork(watchDeleteThrivecart);

  yield fork(watchGetMailerLite);
  yield fork(watchCreateMailerLite);
  yield fork(watchDeleteMailerLite);

  yield fork(watchGetKit);
  yield fork(watchDeleteKit);
  yield fork(watchCreateKitForm);
  yield fork(watchDeleteFormList);

  yield fork(watchGetShopify);
  yield fork(watchAddPixelTag);
  yield fork(watchDeletePixel);

  yield fork(watchGetShopaccino);
  yield fork(watchCreateShopaccino);
  yield fork(watchDeleteShopaccino);

  yield fork(watchGetShopline);
  yield fork(watchAddPixelTagShopline);
  yield fork(watchDeletePixelShopline);

  yield fork(watchGetPipedrive);
  yield fork(watchCreatePipedrive);
  yield fork(watchDeletePipedrive);

  yield fork(watchGetPl);
  yield fork(watchDeletePl);

  yield fork(watchGetCalendly);
  yield fork(watchCreateCalendly);
  yield fork(watchDeleteCalendly);

  yield fork(watchGetNeto);
  yield fork(watchCreateNeto);
  yield fork(watchDeleteNeto);

  yield fork(watchGetZap);
  yield fork(watchCreateZap);
  yield fork(watchDeleteZap);

  yield fork(watchGetIntegration);
  yield fork(watchGetIntMstList);

  yield fork(watchGetSendInBlue);
  yield fork(watchCreateSendInBlue);
  yield fork(watchDeleteSendInBlue);

  yield fork(watchGetConvertkit);
  yield fork(watchCreateConvertkit);
  yield fork(watchDeleteConvertkit);

  yield fork(watchGetGoogle);
  yield fork(watchCreateGoogle);
  yield fork(watchDeleteGoogle);

  yield fork(watchGetLemlist);
  yield fork(watchCreateLemlist);
  yield fork(watchDeleteLemlist);

  yield fork(watchGetAweber);
  yield fork(watchCreateAweber);
  yield fork(watchCreateAweberList);
  yield fork(watchDeleteList);
  yield fork(watchDeleteAweber);
  yield fork(watchSyncAweberList);

  yield fork(watchGetMailchimp);
  yield fork(watchCreateMailchimp);
  yield fork(watchCreateMailchimpWebhook);
  yield fork(watchDeleteMC);
  yield fork(watchDeleteMCList);

  yield fork(watchGetFBAC);
  yield fork(watchCreateFBAC);
  yield fork(watchCreateFBPage);
  yield fork(watchDeleteFBList);
  yield fork(watchDeleteFB);

  yield fork(watchGetBigCommerce);
  yield fork(watchCreateBigCommerce);
  yield fork(watchDeleteBigCommerce);
  yield fork(watchUpdBigcmWebhook);

  yield fork(watchGetGMB);
  yield fork(watchCreateGMB);
  yield fork(watchCreateGMBPage);
  yield fork(watchDeletePage);
  yield fork(watchDeleteGMB);
  yield fork(watchSyncGooglePage);

  yield fork(watchGetImages);

  yield fork(watchAddCutomReview);
  yield fork(watchUpdateReview);

  yield fork(watchGetNotifForInt);
  yield fork(watchUploadCSV);
  yield fork(watchDeleteImage);

  yield fork(watchGetModwebs);
  yield fork(watchCreateModwebs);
  yield fork(watchDeleteModwebs);

  yield fork(watchGetCashFree);
  yield fork(watchCreateCashFree);
  yield fork(watchDeleteCashFree);
}
