import React from 'react';
import { BlockStack, Box, Button, Card, Divider, Icon, InlineGrid, InlineStack, Page, Text } from '@shopify/polaris';
import { ChatIcon, EmailIcon, LayoutPopupIcon, MegaphoneIcon, TextWithImageIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
// import { useLanguage } from '../../LanguageContext';

function ShopifyEmbedGetStart(props) {
  const navigate = useNavigate();

  // const { triggerShopify, setTriggerShopify } = useLanguage();

  const notificationLists = [
    {
      icon: LayoutPopupIcon,
      label: 'Social proof popups',
      subtitle: 'Show recent sales, sold counters, and low-stock alerts to build trust, create urgency, and drive purchases.'
    },
    {
      icon: TextWithImageIcon,
      label: 'Inline social proof',
      subtitle: 'Embed recent sales, visitor counts, and sold counters directly on product pages to boost confidence and drive conversions.'
    },
    {
      icon: MegaphoneIcon,
      label: 'Announcements',
      subtitle: 'Show countdown timers, special offers, and CTAs to create urgency and maximize engagement.'
    },
  ];

  const handleEmailSupport = () => {
    window.open('mailto:support@wisernotify.com', '_self');
  }

  const handleChatSupport = () => {
    if (window.Froged) {
      window.Froged('open');
    }
  }

  const helpLists = [
    {
      icon: EmailIcon,
      label: 'Get Quick Email Support',
      subtitle: 'Drop us an email, and we’ll respond as soon as possible to assist you.',
      cta: 'Open email',
      action: () => handleEmailSupport()
    },
    {
      icon: ChatIcon,
      label: 'Instant Help via Live Chat',
      subtitle: 'Chat with us in real time to get quick answers and resolve your queries.',
      cta: 'Open chat',
      action: () => handleChatSupport()
    }
  ];

  const handleExplore = () => {
    props.handleGetStarted();
    // setTriggerShopify(true);
    navigate("/notifShopify");
  }

  const shopUserName = localStorage.getItem('shp_un');

  return (
    <div>
      <Page fullWidth>
        <BlockStack gap={400}>
          <Box>
            <BlockStack gap={100}>
              <Text variant='headingLg' as='p' fontWeight='semibold' id='shopifyEmbedTextColor'>Hey, {shopUserName ? shopUserName : "Store Admin"} !</Text>
              <Text variant='headingXs' as='p' fontWeight='regular' tone='subdued'>👋 Welcome to: WiserNotify Sales Popup</Text>
            </BlockStack>
          </Box>
          <Box paddingBlockStart={100}>
            <Text fontWeight='semibold' id='shopifyEmbedTextColor'>Boost engagement and drive conversions with social proof & FOMO widgets</Text>
            <Box paddingBlockStart={200}>
              <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={400}>
                {notificationLists && notificationLists.map((data, index) => (
                  <Card key={index}>
                    <BlockStack gap={200}>
                      <InlineStack gap={200}>
                        <Box>
                          <Icon source={data.icon} />
                        </Box>
                        <Text variant='headingSm' fontWeight='semibold' >{data.label}</Text>
                      </InlineStack>
                      <Text variant='headingXs' fontWeight='regular' tone='subdued'>{data.subtitle}</Text>
                      <Box paddingBlock={400}>
                        <Button variant='primary' onClick={() => handleExplore()}>Explore</Button>
                      </Box>
                    </BlockStack>
                  </Card>
                ))}
              </InlineGrid>
            </Box>
          </Box>
          <Box>
            <Text fontWeight='semibold' id='shopifyEmbedTextColor'>Need help with implementation or specific features?</Text>
            <Box paddingBlockStart={200}>
              <Card padding={0}>
                <Box padding={400}>
                  <Text id='helpTitlecolor' fontWeight='semibold'>We’re here for you</Text>
                </Box>
                <Divider />
                <Box padding={400}>
                  <InlineGrid columns={{ xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }} gap={400}>
                    {helpLists && helpLists.map((data, index) => (
                      <div key={index} className='help_card_bg'>
                        <BlockStack gap={200}>
                          <InlineStack gap={200}>
                            <Box>
                              <Icon source={data.icon} />
                            </Box>
                            <Text variant='headingSm' fontWeight='semibold'>{data.label}</Text>
                          </InlineStack>
                          <Text variant='headingXs' fontWeight='regular' tone='subdued'>{data.subtitle}</Text>
                          <Box paddingBlock={100}>
                            <Button variant='plain' onClick={data.action}>{data.cta}</Button>
                          </Box>
                        </BlockStack>
                      </div>
                    ))}
                  </InlineGrid>
                </Box>
              </Card>
            </Box>
          </Box>
        </BlockStack>
      </Page>
    </div>
  );
}

export default ShopifyEmbedGetStart;