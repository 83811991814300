import React from 'react';
import { IndexTable, InlineStack, Text, Card, Page, Button, Box, Badge, BlockStack, Modal, TextField } from "@shopify/polaris";
import { BillIcon } from "@shopify/polaris-icons";
import moment from 'moment';
import BillingSummery from '../PricingPlan/BillingSummery';
import { skeleton } from '../../utils/skeleton';

function BillingInvoiceCard(props) {
  const { state, changeNameValue, translate } = props;

  const capitalFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
  };

  return (
    <Page title={translate('billing').title}
      primaryAction={
        props.profile && props.profile?.cbe && props.profile?.cbe?.client_id &&
        <Box>
          <InlineStack gap={200}>
            <Button size='large' icon={BillIcon} variant="tertiary" onClick={props.mangePaymentSrc}>{translate('billing').ManagePayment}</Button>
            <div>
              <Button size='large' icon={BillIcon} variant="tertiary" onClick={props.handleUpdateBilling}>{translate('billing').UpdateBillingInfo}</Button>
            </div>
          </InlineStack>
        </Box>
      }
    >
      {/* condition for display billing summery */}
      {localStorage.getItem('pvd') !== 'shopify' && props?.profile?.billing?.pi !== 'free_plan' &&
        <BillingSummery props={props} />
      }

      {state.billingInvoice &&
        <Box paddingBlockStart={400}>
          <Card padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockStart={100}>
                <Text variant="headingMd" fontWeight="semibold" >
                  {translate('billing').PastBills}
                </Text>
              </Box>
            </InlineStack>
            <Box paddingBlockStart={300}>
              <Card padding={0}>
                <IndexTable
                  itemCount={state.billingInvoice?.length || 0}
                  headings={[
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').DateIssued}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').BillNumber} </Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').PlanName}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').PaymentStatus}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').Amount}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').Remarks}</Text>
                    },

                  ]}
                  selectable={false}
                >
                  {
                    props.isLoading ?
                      skeleton(10, 6)
                      :
                      state.billingInvoice && state.billingInvoice?.length > 0 && state.billingInvoice.map((i, index) => {
                        return (
                          <>
                            <IndexTable.Row key={index}>

                              {/* Date issued */}
                              <IndexTable.Cell>
                                <Text variant="bodySm" >
                                  {moment(i.cdt).format('MMM DD YYYY')}
                                </Text>
                              </IndexTable.Cell>

                              {/* Bill number */}
                              <IndexTable.Cell>
                                <div className={i.iobj && i.iobj?.id ? "invoice_dwn" : ""} >
                                  {(i.iobj && i.iobj.id) ? (
                                    <Box onClick={() => props.downloadInvoice(i.iobj?.id)}>
                                      <Text variant="bodySm" >
                                        #{i.iobj.id}
                                      </Text>
                                    </Box>
                                  )
                                    :
                                    ('-')
                                  }
                                </div>
                              </IndexTable.Cell>

                              {/* Plan name */}
                              <IndexTable.Cell>
                                {
                                  localStorage.getItem('pvd') === 'wix' ?
                                    <BlockStack>
                                      <Text variant="bodySm" >
                                        {
                                          i.pobj.cf_plan_category ?
                                            capitalFirstLetter(i.pobj.cf_plan_category)
                                            :
                                            i.pobj.pnm &&
                                            capitalFirstLetter(i.pobj.pnm)
                                        }
                                      </Text>
                                      {
                                        (i.pobj && i.pobj.price !== undefined) ?
                                          <Text variant='bodySm'> ${i.pobj.price}</Text>
                                          :
                                          <Text variant='bodySm'> ${i.pobj.prc}</Text>
                                      }
                                    </BlockStack>
                                    :
                                    <BlockStack>
                                      <Text variant="bodySm" >
                                        {capitalFirstLetter(i.pobj.cf_plan_category)}
                                      </Text>
                                      {
                                        i.iobj ? i.iobj.amount_to_collect !== 0 &&
                                          <Text variant='bodySm'>
                                            ${i.iobj.amount_to_collect / 100}/ {i.pdru === 'year' && i.pdr === '5' ? translate('billing').lifetime : i.pdru}
                                          </Text>
                                          :
                                          <Text variant='bodySm'> ${i.pobj.price / 100}/ {i.pdru === 'year' && i.pdr === '5' ? translate('billing').lifetime : i.pdru}</Text>
                                      }
                                    </BlockStack>
                                }

                              </IndexTable.Cell>

                              {/* Payment status */}
                              <IndexTable.Cell>
                                {
                                  i.adnboj ? (
                                    <Badge tone="success">
                                      <Text variant='bodySm'>{translate('billing').Success}</Text>
                                    </Badge>
                                  ) : (
                                    <>
                                      {i.pst === 1 && (
                                        <Badge tone="warning">
                                          <Text variant='bodySm'>{translate('billing').Pending}</Text>
                                        </Badge>
                                      )}
                                      {i.pst === 2 && (
                                        <Badge tone="critical">
                                          <Text variant='bodySm'>{translate('billing').Fail}</Text>
                                        </Badge>
                                      )}

                                      {i.sobj?.status === "in_trial" ? (
                                        <Badge tone="success">
                                          <Text variant='bodySm'>{translate('billing').DaysTrialStarted}</Text>
                                        </Badge>
                                      ) : i.pst === 3 ? (
                                        <Badge tone="success">
                                          <Text variant='bodySm'>{translate('billing').Success}</Text>
                                        </Badge>
                                      ) : null}
                                    </>
                                  )
                                }
                              </IndexTable.Cell>

                              {/* Amount */}
                              <IndexTable.Cell>
                                {
                                  localStorage.getItem('pvd') === 'wix' ?
                                    i.pobj.price !== undefined ? `$0` : `$${i.pobj.prc}`
                                    :
                                    i.adnboj ?
                                      <Text variant="bodySm" tone="success">
                                        ${(i.adnboj.amount / 100)}
                                      </Text>
                                      :
                                      i.iobj && i.iobj?.credits_applied !== 0 ?
                                        <Text variant="bodySm" tone="success">
                                          ${(i.iobj.credits_applied / 100) + (i.iobj.amount_paid / 100)}
                                        </Text>
                                        :
                                        i?.iobj && i?.iobj?.amount_paid ?
                                          <Text variant="bodySm" tone="success">
                                            ${(i.iobj?.amount_paid / 100)}
                                          </Text>
                                          :
                                          i?.iobj && i.iobj?.amount_to_collect !== 0 ?
                                            <Text variant="bodySm" tone="critical">
                                              ${(i?.iobj?.amount_to_collect / 100)}
                                            </Text>
                                            :
                                            <Text variant="bodySm" tone="critical">
                                              ${i.pobj?.price / 100}
                                            </Text>

                                }
                              </IndexTable.Cell>

                              {/* Remark */}
                              <IndexTable.Cell>
                                <Text variant="bodySm">
                                  {
                                    (i.tobj && i.tobj.error_text) ? <span>{i.tobj.error_text}</span> : i.iobj?.credits_applied !== 0 ? translate('billing').CreditApplied : '-'
                                  }
                                </Text>
                              </IndexTable.Cell>

                            </IndexTable.Row >
                          </>
                        )
                      })}

                </IndexTable>
              </Card>
            </Box>
          </Card >
        </Box>
      }

      {/* shopifyBill */}
      {
        state.shopifyBill &&
        <Box paddingBlockStart={400}>
          <Card padding={400}>
            <InlineStack align='space-between'>
              <Box paddingBlockStart={100}>
                <Text variant="headingMd" fontWeight="semibold" >
                  {translate('billing').PastBills}
                </Text>
              </Box>
            </InlineStack>
            <Box paddingBlockStart={300}>
              <Card padding={0}>
                <IndexTable
                  itemCount={state.shopifyBill?.length || 0}
                  headings={[
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').NO}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').ChargeID}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').PlanID}</Text>
                    },
                    {
                      title: <Text variant='headingXs' fontWeight='medium'>{translate('billing').BilledDate}</Text>
                    },
                  ]}
                  selectable={false}
                >
                  {
                    props.isLoading ?
                      skeleton(10, 4)
                      :
                      state.shopifyBill && state.shopifyBill?.length > 0 && state.shopifyBill.map((i, index) => {
                        return (
                          <>
                            <IndexTable.Row key={index}>

                              <IndexTable.Cell>
                                <Text variant="bodySm" >
                                  {index + 1}
                                </Text>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Text variant="bodySm" >
                                  {i.cid ? i.cid : '-'}
                                </Text>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Text variant="bodySm" >
                                  {i.pi}
                                </Text>
                              </IndexTable.Cell>

                              <IndexTable.Cell>
                                <Text variant="bodySm" >
                                  {moment(i.cdt).format('MMM DD YYYY')}
                                </Text>
                              </IndexTable.Cell>

                            </IndexTable.Row >
                          </>
                        )
                      })
                  }
                </IndexTable>
              </Card>
            </Box>
          </Card >
        </Box>
      }

      {/* Update billing information */}
      <Modal
        open={state.openBilling}
        title={translate('billing').UpdateBillingInformation}
        onClose={(e) => { props.openCloseModal('openBilling', state.openBilling, e); }}>
        <Modal.Section>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').Email}
              value={state.e}
              onChange={(e) => changeNameValue({ e: e })}
              autoComplete="off"
              disabled
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').FirstName}
              value={state.fn}
              onChange={(e) => changeNameValue({ fn: e })}
              autoComplete="off"
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').LastName}
              value={state.ln}
              onChange={(e) => changeNameValue({ ln: e })}
              autoComplete="off"
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').Zip}
              value={state.zip}
              onChange={(e) => changeNameValue({ zip: e })}
              autoComplete="off"
              type='number'
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').City}
              value={state.city}
              onChange={(e) => changeNameValue({ city: e })}
              autoComplete="off"
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').Country}
              value={state.country}
              onChange={(e) => changeNameValue({ country: e })}
              autoComplete="off"
            />
          </Box>
          <Box paddingBlockStart={200} paddingBlockEnd={200}>
            <TextField
              label={translate('billing').Company}
              value={state.company}
              onChange={(e) => changeNameValue({ company: e })}
              autoComplete="off"
            />
          </Box>
          <InlineStack align='center'>
            <Box paddingBlockEnd={100} paddingBlockStart={300}>
              <Button id='Add' onClick={() => {
                props.openCloseModal('openBilling', state.openBilling, 'close');
                props.handleUpdate();
              }}>{translate('billing').Update}</Button>
            </Box>
          </InlineStack>
        </Modal.Section>
      </Modal>
    </Page>
  );
}

export default BillingInvoiceCard;
