import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Text, Box, Icon, InlineStack, Button, BlockStack, Divider } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import './StepBubble.css'
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../LanguageContext';

function StepBubble(props) {
  const navigate = useNavigate();
  const { translate } = useLanguage();

  //state data.
  const [state, setState] = useState({
    popUp: true,
    stepCnt: 4,
    width: 25,
    userNotif: false,
    userGoal: false,
    userPixel: false,
    userPlan: true,
  });

  //set state data.
  const changeNameValue = useCallback((obj) => {
    setState((prevState) => ({ ...prevState, ...obj }))
  }, []);

  //get data from redux.
  const profile = useSelector(state => state.auth?.profile)

  //toggle open/close popup.
  const togglePopup = () => {
    changeNameValue({ popUp: !state.popUp });
  };

  useEffect(() => {
    if (profile && localStorage.getItem('pvd') !== 'shopify' && localStorage.getItem('pvd') !== 'wix') {
      let newWidth = 0;

      // Check if the plan is assigned
      if (profile?.csub === '' || profile?.csub === null || profile?.csub === undefined) {
        changeNameValue({ userPlan: false });
      }
      else {
        changeNameValue({ userPlan: true });
        newWidth += 25; // Increase width by 25 if plan is assigned
      }

      // Check if the pixel is detected
      if (profile.vstp && profile?.vstp?.plx && profile.vstp?.plx?.st === true) {
        changeNameValue({ userPixel: true });
        newWidth += 25; // Increase width by 25 if pixel is detected
      } else {
        changeNameValue({ userPixel: false });
      }

      // Check if the notification is created
      if (profile.vstp && profile?.vstp?.ntf && profile.vstp?.ntf?.st === true) {
        changeNameValue({ userNotif: true });
        newWidth += 25; // Increase width by 25 if notification is created
      } else {
        changeNameValue({ userNotif: false });
      }

      // Check if the goal is created
      if (profile.vstp && profile?.vstp?.gl && profile?.vstp?.gl?.st === true) {
        changeNameValue({ userGoal: true });
        newWidth += 25; // Increase width by 25 if goal is created
      } else {
        changeNameValue({ userGoal: false });
      }

      // Only update width if it has changed
      if (state.width !== newWidth) {
        changeNameValue({ width: newWidth }); // Update the width at the end
      }

      ///condition for new step condition popup 36-30-32022
      const stepCount = Math.min(Math.floor(newWidth / 25), 4);
      if (state.stepCnt !== stepCount) {
        setTimeout(() => {
          changeNameValue({ stepCnt: stepCount });
        }, 1);
      }
    }
  }, [profile]);

  //handleNext in stepPopup
  const handleNext = () => {
    setTimeout(() => {
      if (window.Froged) {
        window.Froged('set', {
          get_started: true
        });
      }
      if (window._cio) {
        window._cio.identify({
          id: localStorage.getItem('ti'),
          get_started: true,
        });
      }
    }, 1000);
    if (state.userPlan === false) {
      navigate('/plan');
      changeNameValue({ popUp: !state.popUp });
    }
    else if (state.userPixel === false && localStorage.getItem('pvd') !== 'wix') {
      navigate('/pixel');
      localStorage.setItem('blink', 'true');
      changeNameValue({ popUp: !state.popUp });
    }
    else if (state.userNotif === false) {
      navigate('/notification?ds=type');
      changeNameValue({ popUp: !state.popUp });
    }
    else if (state.userGoal === false) {
      navigate('/goal');
      changeNameValue({ popUp: !state.popUp });
    }
  }

  return (
    <>
      {
        localStorage.getItem('isbusr') !== 'true' &&
        ((localStorage.getItem('csub') !== '' && localStorage.getItem('csub') !== null && localStorage.getItem('csub') !== undefined)) &&
        profile && profile.isfrexp !== true &&
        profile && localStorage.getItem('pvd') !== 'shopify' && localStorage.getItem('pvd') !== 'wix' &&
        localStorage.getItem('utyp') !== 'appsumo' &&
        <>
          <div className='complete_pop' style={{ display: state.stepCnt !== 4 ? 'block' : 'none' }}>
            <div className='steps_btn'>
              <Button variant="primary" tone='success' size='large' onClick={togglePopup}>
                <span className='zero_three'>{state.stepCnt}/4</span>{translate('step').CompleteSteps}
              </Button>
            </div>
            <>
              <div className='complete_step_card'>
                {state.popUp && (
                  <Card>
                    <Box>
                      <div className='heading_card'>
                        <InlineStack align='space-between'>
                          <Box>
                            <Text variant="bodyMd" as="p" fontWeight='medium'>
                              {translate('step').CompleteSteps}
                            </Text>
                          </Box>
                          <Box onClick={togglePopup} className='three_points'>
                            <Icon
                              source={XIcon}
                              tone="base"
                            />
                          </Box>
                        </InlineStack>
                      </div>
                    </Box>
                    <Box paddingInlineStart={200} paddingBlockStart={200}>
                      <InlineStack wrap={false}>
                        <Box>
                          <Text variant='bodyMd'>
                            {state.stepCnt}/4
                          </Text>
                        </Box>
                        <Box paddingInlineStart={500}>
                          <div className="line-container">
                            <div className={state.width >= 25 ? 'line active' : 'line'}></div>
                            <div className={state.width >= 50 ? 'line active' : 'line'}></div>
                            <div className={state.width >= 75 ? 'line active' : 'line'}></div>
                            <div className={state.width >= 100 ? 'line active' : 'line'}></div>
                          </div>
                        </Box>
                      </InlineStack>
                    </Box>
                    <Box paddingBlockStart={200}>
                      <Card padding={0}>
                        <div className='card_back_color'>
                          <BlockStack>
                            <Box padding={300}>
                              <Box paddingBlock={200}>
                                <InlineStack blockAlign="center">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill={state.userPlan === false ? '#CCCCCC' : '#29845A'}></rect>
                                    <path fill-rule="evenodd" clipRule="evenodd" d="M17.7803 7.96967C18.0732 8.26256 18.0732 8.73744 17.7803 9.03033L11.2803 15.5303C10.9874 15.8232 10.5126 15.8232 10.2197 15.5303L6.96967 12.2803C6.67678 11.9874 6.67678 11.5126 6.96967 11.2197C7.26256 10.9268 7.73744 10.9268 8.03033 11.2197L10.75 13.9393L16.7197 7.96967C17.0126 7.67678 17.4874 7.67678 17.7803 7.96967Z" fill="#F8FFFB"></path>
                                  </svg>
                                  <Box paddingInlineStart={400} onClick={() => navigate('/plan')}>
                                    <div className='three_points'>
                                      <Text variant='bodySm' as="p">
                                        {translate('step').PlanAssigned}
                                      </Text>
                                    </div>
                                  </Box>
                                </InlineStack>
                              </Box>
                              <Divider />
                              <Box paddingBlock={200}>
                                <InlineStack blockAlign="center">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill={state.userPixel === true ? '#29845A' : '#CCCCCC'}></rect>
                                    <path fill-rule="evenodd" clipRule="evenodd" d="M17.7803 7.96967C18.0732 8.26256 18.0732 8.73744 17.7803 9.03033L11.2803 15.5303C10.9874 15.8232 10.5126 15.8232 10.2197 15.5303L6.96967 12.2803C6.67678 11.9874 6.67678 11.5126 6.96967 11.2197C7.26256 10.9268 7.73744 10.9268 8.03033 11.2197L10.75 13.9393L16.7197 7.96967C17.0126 7.67678 17.4874 7.67678 17.7803 7.96967Z" fill="#F8FFFB"></path>
                                  </svg>
                                  <Box paddingInlineStart={400} onClick={() => navigate('/pixel')}>
                                    <div className='three_points'>
                                      <Text variant='bodySm' as="p">
                                        {translate('step').InstallPixelCode}
                                      </Text>
                                    </div>
                                  </Box>
                                </InlineStack>
                              </Box>
                              <Divider />
                              <Box paddingBlock={200}>
                                <InlineStack blockAlign="center">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill={state.userNotif === true ? '#29845A' : '#CCCCCC'}></rect>
                                    <path fill-rule="evenodd" clipRule="evenodd" d="M17.7803 7.96967C18.0732 8.26256 18.0732 8.73744 17.7803 9.03033L11.2803 15.5303C10.9874 15.8232 10.5126 15.8232 10.2197 15.5303L6.96967 12.2803C6.67678 11.9874 6.67678 11.5126 6.96967 11.2197C7.26256 10.9268 7.73744 10.9268 8.03033 11.2197L10.75 13.9393L16.7197 7.96967C17.0126 7.67678 17.4874 7.67678 17.7803 7.96967Z" fill="#F8FFFB"></path>
                                  </svg>
                                  <Box paddingInlineStart={400} onClick={() => navigate('/notification?ds=type')}>
                                    <div className='three_points'>
                                      <Text variant='bodySm' as="p">
                                        {translate('step').CreateNotification}
                                      </Text>
                                    </div>
                                  </Box>
                                </InlineStack>
                              </Box>
                              <Divider />
                              <Box paddingBlock={200}>
                                <InlineStack blockAlign="center">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill={state.userGoal === true ? '#29845A' : '#CCCCCC'}></rect>
                                    <path fill-rule="evenodd" clipRule="evenodd" d="M17.7803 7.96967C18.0732 8.26256 18.0732 8.73744 17.7803 9.03033L11.2803 15.5303C10.9874 15.8232 10.5126 15.8232 10.2197 15.5303L6.96967 12.2803C6.67678 11.9874 6.67678 11.5126 6.96967 11.2197C7.26256 10.9268 7.73744 10.9268 8.03033 11.2197L10.75 13.9393L16.7197 7.96967C17.0126 7.67678 17.4874 7.67678 17.7803 7.96967Z" fill="#F8FFFB"></path>
                                  </svg>
                                  <Box paddingInlineStart={400} onClick={() => navigate('/goal')}>
                                    <div className='three_points'>
                                      <Text variant='bodySm' as="p">
                                        {translate('step').CreateGoal}
                                      </Text>
                                    </div>
                                  </Box>
                                </InlineStack>
                              </Box>
                            </Box>
                          </BlockStack>
                        </div>
                      </Card>
                    </Box>
                    <Box paddingBlockStart={300} onClick={() => handleNext()}>
                      <Button variant="primary" fullWidth>
                        {
                          state.userPlan === false ?
                            translate('step').StepOnePlan
                            :
                            state.userPixel === false ?
                              translate('step').StepTwoPixel
                              :
                              state.userNotif === false ?
                                translate('step').StepThreeNotif
                                :
                                state.userGoal === false &&
                                translate('step').StepFourGoal
                        }
                      </Button>
                    </Box>
                  </Card>
                )}
              </div >
            </>
          </div>
        </>
      }
    </>
  );
}

export default StepBubble;